import { useCallback } from "react";
import styled from "styled-components/macro";

export enum PackCategory {
  featured = "featured",
  notOwned = "notOwned",
  owned = "owned",
}

interface Props {
  selectedCategory: PackCategory;
  setSelectedCategory: (e: PackCategory) => void;
}

const Chooser = styled.ul`
  && {
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      list-style-type: none;
      display: inline;
      margin: 0 0.3rem;

      button.selected {
        font-weight: 900;
      }
    }
  }
`;

export const CategoryChooser = ({
  selectedCategory,
  setSelectedCategory,
}: Props) => {
  const getButtonClass = useCallback(
    (category: PackCategory) => {
      if (selectedCategory === category) {
        return "selected";
      }
      return "";
    },
    [selectedCategory],
  );

  return (
    <Chooser>
      <li>
        <button
          className={getButtonClass(PackCategory.featured)}
          onClick={() => setSelectedCategory(PackCategory.featured)}
        >
          Featured
        </button>
      </li>
      <li>
        <button
          className={getButtonClass(PackCategory.notOwned)}
          onClick={() => setSelectedCategory(PackCategory.notOwned)}
        >
          Browse All
        </button>
      </li>
      <li>
        <button
          className={getButtonClass(PackCategory.owned)}
          onClick={() => setSelectedCategory(PackCategory.owned)}
        >
          Your Packs
        </button>
      </li>
    </Chooser>
  );
};
