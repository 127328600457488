import { gql } from "@apollo/client";

const GET_SHOP_PACKS = gql`
  query getShopPacks {
    allPacks {
      ownedPacks {
        ...PackFields
      }
      notOwnedPacks {
        ...PackFields
      }
    }
  }

  fragment PackFields on ShopPack {
    packId
    ownedByUser
    packName
    priceCents
    fullPriceCents
    isSeasonal
    marketingDescription
    invoiceId
    appleProductId
    estimatedPromptCount
  }
`;

export default GET_SHOP_PACKS;
