import { AuthenticationFailure } from "../../__generated__/globalTypes";

export default function getAuthenticationFailureDescription(
  failureType?: AuthenticationFailure,
): string {
  switch (failureType) {
    case AuthenticationFailure.UnknownError:
    default:
      return "An error occurred; please try again.";

    case AuthenticationFailure.AccountAlreadyExists:
      return "An account with that email already exists.";

    case AuthenticationFailure.AccountNotFound:
      return "No account with that email was found.";

    case AuthenticationFailure.IncorrectPassword:
      return "The password you entered was incorrect.";

    case AuthenticationFailure.InvalidEmailAddress:
      return "The email address you entered was invalid.";

    case AuthenticationFailure.MissingData:
      return "All fields are required.";

    case AuthenticationFailure.MustUseGoogle:
      return "You must use Google to sign in.";

    case AuthenticationFailure.GoogleError:
      return "There was an error signing in with your Google account. Please try again.";
  }
}
