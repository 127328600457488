import Decimal from "decimal.js";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { PusherContext } from "../../pusherContext";
import { ProfilePicContainer } from "../ProfilePicContainer/ProfilePicContainer";
import StatusIcon, { StatusIconProps } from "./StatusIcon";

interface WrapperProps {
  scalingFactor: number;
}

const scale = ({ scalingFactor }: WrapperProps, scalar: number): string => {
  const value = new Decimal(scalar).times(scalingFactor).toNumber();
  return `${value}em`;
};

const Wrapper = styled.div.attrs(() => ({
  className: "inlinePlayerName",
}))<WrapperProps>`
  && {
    display: inline-block;
    font-size: ${(props) => scale(props, 0.8)};
    font-weight: 700;
    margin: ${(props) => scale(props, 1.2)} 0 ${(props) => scale(props, 1.5)};
    white-space: nowrap;

    .profilePicContainer {
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      border: 1px solid #fff;
      box-shadow: 0 0 5px #000;
      margin-bottom: -0.4em;
      margin-right: 0.4em;
    }
  }
`;

interface InlinePlayerNameProps {
  playerId: string;
  scalingFactor?: number;
  statusIcon?: StatusIconProps["icon"];
}

export const InlinePlayerName = ({
  playerId,
  scalingFactor = 1,
  statusIcon = "none",
}: InlinePlayerNameProps) => {
  const { humanPlayers, userId } = useContext(PusherContext);
  const [playerName, setPlayerName] = useState("");
  const [playerPic, setPlayerPic] = useState("");

  useEffect(() => {
    if (playerId && humanPlayers[playerId]) {
      setPlayerPic(humanPlayers[playerId].profilePicture);

      if (playerId === userId) {
        setPlayerName("You");
      } else {
        setPlayerName(humanPlayers[playerId].name);
      }
    }
  }, [playerId, humanPlayers, userId]);

  if (!humanPlayers[playerId]) {
    console.warn(`Player ${playerId} not found in humanPlayers`);
    return null;
  }

  return (
    <Wrapper scalingFactor={scalingFactor}>
      <ProfilePicContainer profilePicture={playerPic} />
      <StatusIcon icon={statusIcon} />
      {playerName}
    </Wrapper>
  );
};
