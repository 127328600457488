import React, { useState, useCallback } from "react";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { CardProps, Card } from "../design-components/Card/Card";

/**
 * Render a form for users to enter their email and receive a magic login link in an email. Card props can be passed in
 * to override some of the defaults (other than those that would break its functionality).
 */
export const RequestForm = (props: CardProps) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const makeRequest = useCallback(
    async (e: any) => {
      try {
        if (e && e.preventDefault) {
          e.preventDefault();
        }

        await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/user/magicLogin`,
          {
            params: {
              email,
            },
          },
        );
        setSuccess(true);
      } catch (err) {
        setFailure(true);
        Sentry.captureException(err);
      }
    },
    [email, setSuccess, setFailure],
  );

  const enableRetry = useCallback(() => {
    setSuccess(false);
    setFailure(false);
  }, [setSuccess, setFailure]);

  if (success) {
    return (
      <Card
        title="We got you."
        subtitle="Check your email - we'll be in touch."
      />
    );
  }

  if (failure) {
    return (
      <Card
        title="Something went wrong..."
        subtitle="Sorry about that. Try again?"
        submitButtonProps={{
          disabled: false,
          textLabels: {
            disabled: "Try Again",
            enabled: "Try Again",
            submitting: "Try Again",
          },
          onClick: enableRetry,
        }}
      />
    );
  }

  return (
    <Card
      title="Request Magic Login"
      subtitle="Enter the email address you used to sign up, and we'll send you a link that will log you in, no questions asked."
      {...props}
      submitButtonProps={{
        disabled: email.length < 1,
        textLabels: {
          disabled: "Enter Your Email",
          enabled: "Request Magic Link",
          submitting: "Requesting...",
        },
        onClick: makeRequest,
        resetOnChange: [success, failure, email],
      }}
    >
      <form onSubmit={makeRequest}>
        <input
          type="text"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
        <label htmlFor="email">Email Address</label>
      </form>
    </Card>
  );
};
