import styled from "styled-components/macro";
import { ThemedComponentProps } from "../../types/ThemedComponentProps";
import { lightDarkVariant } from "../../lib/lightDarkVariant";

export const CardContainer = styled.div.attrs(() => ({
  className: "cardContainer",
}))<ThemedComponentProps>`
  ${(props) =>
    lightDarkVariant("background-color", {
      light: props.tcTheme.light.detail,
      dark: props.tcTheme.dark.detail,
    })}

  && {
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 5vh;
    min-height: min-content;

    .cardContents {
      padding: 3rem 2rem;
      display: block;
    }

    button {
      display: block;
      width: 100%;
      margin-bottom: 0;

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        &:hover {
          opacity: 0.6;
        }
      }

      &.auxButton {
        display: inherit;
        width: inherit;

        &:hover {
          opacity: inherit;
        }

        &:disabled {
          &:hover {
            opacity: inherit;
          }
        }
      }
    }

    button(:not(.auxSubmitButton)) {
      &.submitButton {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
`;
