import styled from "styled-components/macro";

export const FormContainer = styled.form`
  width: 80%;
  margin: 0 auto;
  margin-top: 4rem;

  a {
    margin-top: 3rem;
    font-size: 1.6rem;
    display: block;
    text-align: center;
  }

  input {
    margin-bottom: 1.8rem;
    font-size: 1.8rem;
    height: 4rem;
  }
`;

export const ErrorWrapper = styled.div`
  margin: 2rem auto 0;
  width: 100%;
`;
