import React from "react";
import styled from "styled-components/macro";
// import { PusherContext } from "../../pusherContext";
// import { TCLogo } from "../../TCLogo/TCLogo";

const MetaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* TCLogo */
  .logo {
    flex: 1;

    h1 {
      margin-top: 2px;
      font-size: 16px;
      font-weight: normal;
      text-align: left;
      opacity: 0.5;
      cursor: default;
    }
  }

  /* Response X of Y text */
  p {
    flex: 1;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 12px;
    text-align: right;
    line-height: 1.5em;
    opacity: 0.5;
    cursor: default;
  }
`;

interface ResponseMetaProps {
  responseIndex?: number;
  totalResponses?: number;
}

/**
 * Add a Got 'Em logo and show which response we're showing if the required data is present
 */
export const ResponseMeta = ({
  responseIndex,
  totalResponses,
}: ResponseMetaProps) => {
  // const { roomConfigPreset } = useContext(PusherContext);

  if (
    typeof responseIndex === "undefined" ||
    typeof totalResponses === "undefined"
  ) {
    return null;
  }

  return (
    <MetaWrapper>
      {/* <TCLogo primaryEmoji={roomConfigPreset?.primaryEmoji} plainColor={true} /> */}
      <p>
        <b>{responseIndex + 1}</b> / {totalResponses}
      </p>
    </MetaWrapper>
  );
};
