import { useContext } from "react";
import styled from "styled-components/macro";
import { lightDarkVariant } from "../../lib/lightDarkVariant";
import { ThemeContext } from "../../themeContext";
import { ThemedComponentProps } from "../../types/ThemedComponentProps";

const Wrapper = styled.div`
  && {
    position: relative;
    display: inline;
  }
`;

const Icon = styled.div<ThemedComponentProps>`
  && {
    display: inline;
    position: absolute;
    left: -32px;
    top: 16px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 15px;
    text-align: center;

    &.success {
      font-size: 14px;
      padding-top: 3px;
      background-color: ${(props) =>
        lightDarkVariant("background-color", {
          light: props.tcTheme.light.positive,
          dark: props.tcTheme.dark.positive,
        })};
    }

    &.danger {
      padding-top: 1px;
      background-color: ${(props) =>
        lightDarkVariant("background-color", {
          light: props.tcTheme.light.negative,
          dark: props.tcTheme.dark.negative,
        })};
    }
  }
`;

export interface StatusIconProps {
  icon: "success" | "danger" | "none";
}

export default function StatusIcon({ icon }: StatusIconProps) {
  const tcTheme = useContext(ThemeContext);

  switch (icon) {
    default:
      return null;

    case "none":
      return null;

    case "success":
      return (
        <Wrapper>
          <Icon className="success" tcTheme={tcTheme}>
            ✓
          </Icon>
        </Wrapper>
      );

    case "danger":
      return (
        <Wrapper>
          <Icon className="danger" tcTheme={tcTheme}>
            &times;
          </Icon>
        </Wrapper>
      );
  }
}
