import { css } from "styled-components/macro";

/**
 * Generate a CSS snippet for a property with a value for light and dark mode
 */
export const lightDarkVariant = (
  property: string,
  values: { light: string; dark: string },
) => {
  // return css`
  //   ${property}: ${values.light};

  //   @media (prefers-color-scheme: dark) {
  //     ${property}: ${values.dark};
  //   }
  // `;
  return css`
    ${property}: ${values.dark};
  `;
};
