import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";

import { getMissingPacks, searchUsers, grantPack } from "../api/admin";
import { Card } from "../design-components/Card/Card";
import { UserSearchResult, MissingPackResult } from "../types/eventPayloads";

export default function GrantPacks() {
  const [successMessage, setSuccessMessage] = useState("");

  // state for user search
  const [searchTimeout, setSearchTimeout] = useState<number | null>(null);
  const [userSearch, setUserSearch] = useState("");
  const [userResults, setUserResults] = useState<UserSearchResult[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserSearchResult | null>(
    null,
  );

  // state for pack selection
  const [packResults, setPackResults] = useState<MissingPackResult[]>([]);
  const [selectedPack, setSelectedPack] = useState<MissingPackResult | null>(
    null,
  );

  useEffect(() => {
    const fetchMissingPacks = async () => {
      if (selectedUser !== null) {
        const missingPacks = await getMissingPacks(selectedUser.userId);
        setPackResults(missingPacks);
      }
    };

    fetchMissingPacks();
  }, [selectedUser]);

  const onUserSearch = useCallback(
    (e: any) => {
      const doSearch = async (keyword: string) => {
        const result = await searchUsers(keyword);
        setUserResults(result);
      };

      setUserSearch(e.target.value);

      if (searchTimeout) {
        window.clearTimeout(searchTimeout);
      }

      if (userSearch.length > 0) {
        const newTimeout = window.setTimeout(
          () => doSearch(e.target.value),
          800,
        );
        setSearchTimeout(newTimeout);
      }
    },
    [searchTimeout, setSearchTimeout, userSearch, setUserSearch],
  );

  const resetUser = useCallback(() => {
    setSelectedUser(null);
    setSelectedPack(null);
    setPackResults([]);
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      if (!selectedPack || !selectedUser) {
        throw new Error(`Tried to grant pack without selecting pack and user`);
      }
      const result = await grantPack(
        selectedPack?.packId,
        selectedUser?.userId,
      );

      setSuccessMessage(`Granted ${result.packName} to ${result.playerEmail}`);
      resetUser();
      setUserSearch("");
      setUserResults([]);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }, [resetUser, selectedPack, selectedUser]);

  const selectUser = useCallback((user: UserSearchResult) => {
    try {
      setSelectedUser(user);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }, []);

  const resetPack = useCallback(() => {
    setSelectedPack(null);
  }, []);

  return (
    <>
      {successMessage.length > 0 && (
        <Card>
          <h3>✔ {successMessage}</h3>
        </Card>
      )}

      <Card
        submitButtonProps={{
          textLabels: {
            disabled: "Grant Pack",
            submitting: "Granting pack...",
            enabled: "Grant Pack",
          },
          disabled: !selectedUser || !selectedPack,
          onClick: onSubmit,
          resetOnChange: [selectedUser, selectedPack],
        }}
      >
        <h3>Grant Packs</h3>

        {selectedUser && (
          <>
            <div className="selectedUser">
              <p>
                {selectedUser.name} ({selectedUser.email})
              </p>
              <button onClick={resetUser}>Choose Someone Else</button>
            </div>

            {selectedPack && (
              <div className="selectedPack">
                <p>{selectedPack.packName}</p>
                <button onClick={resetPack}>Choose Different Pack</button>
              </div>
            )}

            {selectedPack === null && (
              <>
                {!packResults.length && (
                  <p>
                    <b>User has all packs!</b>
                  </p>
                )}

                {packResults.map((pack) => (
                  <div
                    className="packResult"
                    key={pack.packId}
                    onClick={() => setSelectedPack(pack)}
                  >
                    {pack.packName}
                  </div>
                ))}
              </>
            )}
          </>
        )}

        {!selectedUser && (
          <>
            <p>Give any prompt pack to any user for $free.99</p>
            <input
              type="text"
              name="userSearch"
              placeholder="Search by name or email..."
              value={userSearch}
              onChange={onUserSearch}
            />

            {!userResults.length && userSearch.length > 0 && (
              <p>
                <b>No results found</b>
                <br />
                Note: Guest users don't appear
              </p>
            )}

            {userResults.map((result) => (
              <div
                className="userResult"
                key={result.userId}
                onClick={() => selectUser(result)}
              >
                {result.name} ({result.email})
              </div>
            ))}
          </>
        )}
      </Card>
    </>
  );
}
