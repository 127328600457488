import { gql } from "@apollo/client";

export const GET_USER_PACKS = gql`
  query GetUserPacks {
    packs {
      packId
      packName
      isSeasonal
      marketingDescription
      estimatedPromptCount
    }
  }
`;

export default GET_USER_PACKS;
