import styled from "styled-components/macro";

const NonGuesserWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;

    .last-guess {
      margin-top: 10rem;
    }
  }
`;

export default NonGuesserWrapper;
