import { useCallback, useContext, useState } from "react";
import styled from "styled-components/macro";
import { AuxButton } from "../design-components/AuxButton/AuxButton";
import { ThemeContext } from "../themeContext";
import LastGuessModal from "./LastGuessModal";

const Wrapper = styled.div`
  && {
    padding-bottom: 1.5rem;

    button {
      display: inline-block;
    }
  }
`;

export default function ViewLastGuess() {
  const tcTheme = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const handleHideModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <Wrapper>
      {showModal && <LastGuessModal handleClose={handleHideModal} />}
      <AuxButton onClick={handleShowModal} tcTheme={tcTheme}>
        View Last Guess
      </AuxButton>
    </Wrapper>
  );
}
