import { useContext } from "react";
import { PusherContext } from "../pusherContext";
import { PointTotal } from "../types/eventPayloads";
import { ScoreEasterEgg } from "./ScoreEasterEgg";

export type ScoreSummaryProps = {
  showNewPoints: boolean;
};

export const ScoreSummary = ({ showNewPoints }: ScoreSummaryProps) => {
  const { gamePoints, humanPlayers } = useContext(PusherContext);

  if (!gamePoints || !humanPlayers) {
    return <p className="text-center">No scores are in yet.</p>;
  }

  const renderNewPoints = showNewPoints && !!gamePoints.roundPoints;

  return (
    <table className="point-totals">
      <thead>
        <tr>
          <th>Player</th>
          {renderNewPoints && <th className="data-numeric">New Points</th>}
          <th className="data-numeric">Total Points</th>
        </tr>
      </thead>
      <tbody>
        {gamePoints &&
          gamePoints.totalPoints.map(({ playerId, points: totalPoints }) => {
            if (!humanPlayers[playerId]) {
              console.warn(`User ${playerId} not found in users list`);
              return null;
            }

            const { name } = humanPlayers[playerId];

            let roundPoints: PointTotal | undefined;
            if (renderNewPoints) {
              roundPoints = gamePoints?.roundPoints?.find(
                (roundPointEntry) => roundPointEntry.playerId === playerId,
              );
              if (!roundPoints) {
                console.warn(`Didn't find roundPoints for user ${playerId}`);
                return null;
              }
            } else {
              roundPoints = undefined;
            }

            return (
              <tr key={name}>
                <td>{name}</td>
                {renderNewPoints && roundPoints && (
                  <td className="data-numeric">
                    {roundPoints.points > 0 && "+"}
                    {roundPoints.points.toLocaleString("en-US")}
                  </td>
                )}
                <td className="data-numeric">
                  {totalPoints.toLocaleString("en-US")}
                  {renderNewPoints && roundPoints && (
                    <ScoreEasterEgg
                      roundPoints={roundPoints?.points}
                      totalPoints={totalPoints}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        {!gamePoints && (
          <tr>
            <td colSpan={renderNewPoints ? 3 : 2}>Loading points...</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
