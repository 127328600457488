import { gql } from "@apollo/client";

const LOGIN_MUTATION = gql`
  mutation UserLogin($data: AuthInput!) {
    login(data: $data) {
      failureType
      success
    }
  }
`;

export default LOGIN_MUTATION;
