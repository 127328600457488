import React, { useContext, useEffect } from "react";
import styled from "styled-components/macro";
import "./lobby.scss";
import { LobbyControls } from "./LobbyControls/LobbyControls";
import { PusherContext } from "../pusherContext";
import { CurrentPin } from "../CurrentPin/CurrentPin";
import { Card } from "../design-components/Card/Card";
import { trackPage } from "../SegmentManager";
import { AddBotButton } from "../AddBotButton/AddBotButton";

const AddBotContainer = styled.div`
  && {
    text-align: center;

    button {
      width: initial;
      display: inline;
    }
  }
`;

const Lobby = () => {
  const { onlineUsers, pusherPin } = useContext(PusherContext);

  useEffect(() => {
    trackPage("Game", "Lobby");
  }, []);

  const userList = Object.entries(onlineUsers).map(([id, user]) => (
    <li key={id}>{user.name}</li>
  ));

  return (
    <div className="lobby">
      <LobbyControls />

      {pusherPin && (
        <Card>
          <CurrentPin pin={pusherPin} />
        </Card>
      )}

      <Card title="Who's Here">
        <ul>{userList}</ul>
        <>
          <br />
          <AddBotContainer>
            <AddBotButton clickEventName="Add Bot Clicked (Who's Here)" />
          </AddBotContainer>
        </>
      </Card>
    </div>
  );
};

export default Lobby;
