import styled from "styled-components/macro";

export const CardForm = styled.form.attrs(() => ({
  className: "card-form",
}))`
  && {
    flex-direction: row;
    flex: 1;
    align-items: center;

    h4 {
      font-family: "Righteous";
      margin-bottom: 1rem;
    }

    label {
      text-align: left;
      margin: 0;
      padding: 0 1rem;
      text-transform: initial;
      line-height: 1em;
      font-size: 16px;
    }

    input[type="checkbox"] {
      line-height: 1em;
      font-size: 1em;
    }

    .radio-item {
      display: flex;
      align-items: flex-start;
      margin: 1.5rem 0;

      .radio-container {
        position: relative;
        top: -0.3rem;
      }

      input[type="radio"] {
        margin-top: 0.2rem;
        font-size: 1em;
      }

      label {
        display: inline-block;
        padding: 0 0.5rem;
        line-height: 2rem;

        b {
          display: block;
        }

        span {
          font-weight: normal;
        }
      }
    }
  }
`;
