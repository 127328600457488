import { gql } from "@apollo/client";

const GET_ROUND_BONUSES = gql`
  query getRoundBonuses($gameRoomId: String!) {
    gameRoom(gameRoomId: $gameRoomId) {
      currentRound {
        roundBonuses {
          event
          playerId
          pointValue
        }
      }
    }
  }
`;

export default GET_ROUND_BONUSES;
