import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { useLocation, Redirect } from "react-router";
import { APP_ROUTES } from "../constants";

import { IS_LOGGED_IN } from "../AuthFlow/IsLoggedIn";
import { IsLoggedIn } from "../AuthFlow/__generated__/IsLoggedIn";

interface Props {
  children: JSX.Element;
}

/**
 * Check if the user is logged in. If so, render the children passed to this component.
 * If not, redirect the user to the auth page with a URL param that will send them back here.
 */
export default function LoginGate({ children }: Props) {
  const location = useLocation();
  const { data, loading, error } = useQuery<IsLoggedIn>(IS_LOGGED_IN, {
    fetchPolicy: "network-only",
  });

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
    Sentry.captureException(error);
  }

  if (data.me?.userId) {
    return children;
  }

  return (
    <Redirect
      to={{
        pathname: APP_ROUTES.AUTH,
        search: `?to=${encodeURIComponent(
          location.pathname + location.search,
        )}`,
      }}
    />
  );
}
