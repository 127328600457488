import styled from "styled-components/macro";
import { Card } from "../design-components/Card/Card";
import GenericModal from "../design-components/GenericModal";

const HowToPlayContainer = styled.div`
  && {
    .cardContents {
      padding-top: 0.4rem;
    }

    li {
      margin-bottom: 2rem;
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }
`;

type HowToPlayProps = {
  handleClose: () => void;
};

export default function HowToPlay({ handleClose }: HowToPlayProps) {
  return (
    <HowToPlayContainer>
      <GenericModal handleClose={handleClose}>
        <Card
          title="How to Play"
          submitButtonProps={{
            disabled: false,
            onClick: handleClose,
            textLabels: {
              disabled: "Back to Game",
              enabled: "Back to Game",
              submitting: "Closing...",
            },
          }}
        >
          <ol>
            <li>
              <b>Response Phase</b>
              <br />
              At the beginning of the round, the prompt is revealed, and all
              players submit a response. Try not to be too obvious; the
              objective is to guess who said what!
            </li>
            <li>
              <b>Guessing Phase</b>
              <br />
              After everyone's response has been submitted, it's time to start
              guessing. Players take turns guessing. If they're correct, the
              player they guessed is out for the rest of the round, and they get
              to guess again (until they're wrong). Correct guesses get 1,000
              points, with lots of bonuses available.
            </li>
            <li>
              <b>End of Round</b>
              <br />
              That's it! Scores are displayed, and a new round can begin. A new
              prompt is chosen at random and all players are back in the game.
              And someone else gets to make the first guess.
            </li>
          </ol>
        </Card>
      </GenericModal>
    </HowToPlayContainer>
  );
}
