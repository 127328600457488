import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { CardBanner } from "../design-components/CardBanner/CardBanner";
import PackBuyer from "../PackBuyer";
import { PusherContext } from "../pusherContext";
import END_OF_ROUND_UPSELL from "./queries/endOfRoundUpsell";
import {
  endOfRoundUpsell,
  endOfRoundUpsellVariables,
} from "./queries/__generated__/endOfRoundUpsell";

/**
 * If the situation warrants it, advertise a prompt pack to the user. Requirements:
 * - this is at least the third round of the game
 * - nobody in the group owns anything other than the starter pack
 */
export default function EndOfRoundUpsell() {
  const { gameRoomId, userId } = useContext(PusherContext);
  const { data, loading } = useQuery<
    endOfRoundUpsell,
    endOfRoundUpsellVariables
  >(END_OF_ROUND_UPSELL, {
    fetchPolicy: "cache-and-network",
    variables: {
      gameRoomId,
      playerId: userId,
    },
  });

  if (loading || !data) {
    return null;
  }

  // only show if this is at least the third round
  if (data.gameRoom.gameRounds.length < 3) {
    console.debug("Not enough rounds to show upsell");
    return null;
  }

  // only show if this game only contains the starter pack
  if (data.gameRoom.roomConfig.packsEnabled.length > 1) {
    console.debug("Too many packs to show upsell");
    return null;
  }

  const banner = (
    <CardBanner emoji="🎁">
      <p>Enjoy over 30 more prompts in all future games!</p>
      <span className="small-caps">
        There are only 5 in the free version... just sayin'
      </span>
    </CardBanner>
  );

  return (
    <PackBuyer
      pack={data.recommendedPack}
      topContent={banner}
      sourceForAnalytics="Round Summary"
    />
  );
}
