import React, { useContext, useCallback, useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { PusherContext } from "../../pusherContext";
import { Card } from "../../design-components/Card/Card";
import { Button } from "../../design-components/SubmitButton/SubmitButton";
import { trackEvent } from "../../SegmentManager";
import { AddBotButton } from "../../AddBotButton/AddBotButton";

const AddBotContainer = styled.div`
  && {
    text-align: center;

    button {
      width: initial;
      display: inline;
    }
  }
`;

export const LobbyControls = () => {
  const pusherContext = useContext(PusherContext);
  const [playersRequiredToStart, setPlayersRequired] = useState(1);

  useEffect(() => {
    const { roomConfig, onlineUsers } = pusherContext;
    if (!roomConfig) {
      return;
    }

    setPlayersRequired(roomConfig.minPlayers - Object.keys(onlineUsers).length);
  }, [pusherContext]);

  const handleStartGame = useCallback(async () => {
    const { pusherPin } = pusherContext;
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/room/${pusherPin}/startGame`,
      );
    } catch (error) {
      console.error(error);
    }
  }, [pusherContext]);

  const openHowToPlayModal = () => {
    trackEvent("Lobby: How to Play Clicked");
    pusherContext.setShowHowToPlayModal(true);
  };

  const playerNoun = playersRequiredToStart === 1 ? "player" : "players";

  return (
    <Card
      title="Ready to Play?"
      submitButtonProps={{
        disabled: playersRequiredToStart > 0,
        onClick: handleStartGame,
        textLabels: {
          disabled: `${playersRequiredToStart} more ${playerNoun} needed.`,
          enabled: "Let the Games Begin",
          submitting: "Starting the game...",
        },
      }}
    >
      <Button onClick={openHowToPlayModal}>How to Play</Button>

      {playersRequiredToStart > 0 && (
        <>
          <br />
          <AddBotContainer>
            <AddBotButton clickEventName="Add Bot Clicked (Lobby Controls)" />
          </AddBotContainer>
        </>
      )}
    </Card>
  );
};
