import React, { ReactNode, useContext } from "react";
import styled from "styled-components/macro";
import { lightDarkVariant } from "../../lib/lightDarkVariant";
import { ThemeContext } from "../../themeContext";
import { ThemedComponentProps } from "../../types/ThemedComponentProps";

const BannerWrapper = styled.div<ThemedComponentProps>`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.8rem;

    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.secondary,
        dark: props.tcTheme.dark.secondary,
      })}

    p {
      font-size: 1.6rem;
      margin: 0;
      cursor: default;
    }

    span {
      opacity: 0.8;
      font-weight: bold;
      font-size: 1.4rem;

      &.small-caps {
        font-size: 1.3rem;
      }
    }

    .small-caps {
      text-transform: uppercase;
      font-weight: bold;
    }

    div.feature {
      flex: 1;
    }

    div.gutter {
      align-items: normal;
      flex-shrink: 1;
      flex-grow: 0;
      padding: 0 0.9rem 0 0.5rem;
    }
  }
`;

type CardBannerProps = {
  emoji?: string;
  children: ReactNode;
  onDismiss?: (e: any) => void;
};

export const CardBanner = (props: CardBannerProps) => {
  const tcTheme = useContext(ThemeContext);

  return (
    <BannerWrapper tcTheme={tcTheme}>
      {props.emoji && <div className="gutter">{props.emoji}</div>}
      <div className="feature">{props.children}</div>
      {props.onDismiss && (
        <div className="gutter">
          <button className="dismiss-banner" onClick={props.onDismiss}>
            &mult;
          </button>
        </div>
      )}
    </BannerWrapper>
  );
};
