import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import { Card } from "../design-components/Card/Card";

const MenuContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`;

const MenuRow = styled.div`
  && {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

type ResponsePagerProps = {
  handleSetIndex: CallableFunction;
  handleStartGuessing: CallableFunction;
  responseIndex: number;
  totalResponses: number;
};

export const ResponsePager = ({
  handleSetIndex,
  handleStartGuessing,
  responseIndex,
  totalResponses,
}: ResponsePagerProps) => {
  const [guessReady, setGuessReady] = useState(false);
  const [guessCooldown, setGuessCooldown] = useState<any>(null);

  useEffect(() => {
    // when the last answer is shown, count down 3 seconds before unlocking "start guessing" to avoid accidental presses
    if (responseIndex === totalResponses - 1) {
      if (!guessCooldown) {
        setGuessCooldown(
          setTimeout(() => {
            setGuessReady(true);
          }, 3000),
        );
      }
    }
  }, [responseIndex, totalResponses, guessCooldown]);

  const handlePrevious = useCallback(() => {
    if (responseIndex === 0) {
      return;
    }
    handleSetIndex(responseIndex - 1);

    // reset "start guessing" lock
    if (guessCooldown) {
      clearTimeout(guessCooldown);
      setGuessCooldown(null);
    }
    setGuessReady(false);
  }, [responseIndex, guessCooldown, handleSetIndex]);

  const handleNext = useCallback(() => {
    if (responseIndex === totalResponses - 1) {
      handleStartGuessing();
    } else {
      handleSetIndex(responseIndex + 1);
    }
  }, [responseIndex, totalResponses, handleSetIndex, handleStartGuessing]);

  let nextButton: React.ReactNode;
  if (responseIndex === totalResponses - 1) {
    nextButton = (
      <Card
        submitButtonProps={{
          disabled: !guessReady,
          onClick: handleNext,
          textLabels: {
            enabled: "Start Guessing",
            disabled: "Just a sec...",
            submitting: "Get ready...",
          },
          resetOnChange: guessReady,
        }}
      />
    );
  } else {
    nextButton = (
      <Card
        submitButtonProps={{
          disabled: false,
          onClick: handleNext,
          textLabels: {
            enabled: "Next Response",
            disabled: "Next Response",
            submitting: "Next Response",
          },
          resetOnChange: responseIndex,
        }}
      />
    );
  }

  return (
    <MenuContainer>
      <MenuRow>
        <Card
          submitButtonProps={{
            disabled: responseIndex === 0,
            onClick: handlePrevious,
            textLabels: {
              disabled: "Previous",
              enabled: "Previous",
              submitting: "Previous",
            },
            resetOnChange: responseIndex,
          }}
        />
        {nextButton}
      </MenuRow>
    </MenuContainer>
  );
};
