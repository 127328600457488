import axios from "axios";

const baseApiUri = `${process.env.REACT_APP_BACKEND_HOST}/round`;

/**
 * Get the user's answer for the current round
 */
export const getMyAnswer = async () => {
  const response = await axios.get(`${baseApiUri}/answer`);
  return response.data;
};

export const retractAnswer = async () => {
  const response = await axios.delete(`${baseApiUri}/answer`);
  return response;
};
