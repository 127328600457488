import {
  useStripe,
  useElements,
  CardElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { PaymentRequest, StripeCardElementOptions } from "@stripe/stripe-js";
import Decimal from "decimal.js";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Sentry from "@sentry/browser";

import { PusherContext } from "../pusherContext";
import { ShopPack, UserPaymentMethod } from "../types/eventPayloads";
import { SubmitButton } from "../design-components/SubmitButton/SubmitButton";

import "./PaymentModal.scss";

const Modal = styled.div`
  h1 {
    font-family: "Righteous", sans-serif;
    font-size: 2.2rem;
    font-weight: normal;
    text-align: center;
  }
`;

interface PaymentModalProps {
  cart: Array<ShopPack>;
  handleClose: (e: any) => void;
}

export const PaymentModal = ({ cart, handleClose }: PaymentModalProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { isDarkMode } = useContext(PusherContext);

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [amountCents, setAmountCents] = useState<number | null>(null);

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );
  const [
    userPaymentMethods,
    setUserPaymentMethods,
  ] = useState<Array<UserPaymentMethod> | null>(null);

  // load user's payment methods (if any)
  useEffect(() => {
    const getUserPaymentMethods = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/user/paymentMethods`,
        );
        const paymentMethods = result.data as Array<UserPaymentMethod>;
        setUserPaymentMethods(paymentMethods);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    getUserPaymentMethods();
  }, []);

  // create Stripe Payment Request for selected prompt pack
  useEffect(() => {
    if (stripe && amountCents !== null) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Got 'Em Shop",
          amount: amountCents,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(pr);
            pr.on("paymentmethod", async (ev) => {
              const {
                paymentIntent,
                error: confirmError,
              } = await stripe.confirmCardPayment(
                clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false },
              );

              if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete("fail");
                Sentry.captureException(confirmError);
              } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete("success");
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow.
                if (paymentIntent?.status === "requires_action") {
                  // Let Stripe.js handle the rest of the payment flow.
                  const { error } = await stripe.confirmCardPayment(
                    clientSecret,
                  );
                  if (error) {
                    // The payment failed -- ask your customer for a new payment method.
                    setError(
                      `Payment failed! Please try a different payment method.`,
                    );
                    Sentry.captureException(error);
                  } else {
                    setSucceeded(true);
                  }
                } else {
                  setSucceeded(true);
                }
              }
            });
          }
        })
        .catch((error) => Sentry.captureException(error));
    }
  }, [stripe, amountCents, clientSecret]);

  // find or create a Payment Intent for the current user/cart
  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const cartIds = cart.map((item) => item.packId);

        const result = await axios.post(
          `${process.env.REACT_APP_BACKEND_HOST}/packs/createPaymentIntent`,
          {
            cart: cartIds,
          },
        );

        setClientSecret(result.data.clientSecret);
        setAmountCents(result.data.amountCents);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    createPaymentIntent();
  }, [cart]);

  const getElementsConfig = useCallback(() => {
    const color = isDarkMode ? "#fff" : "#000";
    const placeholderColor = isDarkMode ? "#ccc" : "333";

    const options: StripeCardElementOptions = {
      style: {
        base: {
          fontSize: "18px",
          color,
          "::placeholder": {
            color: placeholderColor,
          },
        },
      },
      iconStyle: isDarkMode ? "solid" : "default",
    };

    return options;
  }, [isDarkMode]);

  // Listen for changes in the CardElement
  // and display any errors as the customer types their card details
  const handleChange = async (event: any) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const submitWithNewCard = async (ev?: any) => {
    if (!stripe || !elements) {
      console.error("Stripe failed to initialize");
      return;
    }

    ev?.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)!,
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
      Sentry.captureException(payload.error);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  const submitWithExistingCard = async (paymentMethodId: string) => {
    if (!stripe || !elements) {
      console.error("Stripe failed to initialize");
      return;
    }

    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId,
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
      Sentry.captureException(payload.error);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <Modal>
      <div className="modalOverlay" onClick={handleClose} />
      <div className="checkoutModal">
        <div className="modalSpacer" />
        <div className="modalContent">
          <button className="closeModal" onClick={handleClose}>
            &times;
          </button>
          <h1>Complete Purchase</h1>

          <div className="checkoutDetails">
            <p className="invoice-subtext">
              Once purchased, content will be available in your account as long
              as Got 'Em exists.
            </p>

            <table>
              <tbody>
                {cart.map((cartItem) => (
                  <tr key={cartItem.packId}>
                    <td>
                      <b>{cartItem.packName}</b>
                      <em>(Prompt Pack)</em>
                    </td>
                    <td className="price">
                      $
                      {new Decimal(cartItem.priceCents)
                        .dividedBy(100)
                        .toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {!succeeded && (
            <div className="paymentZone">
              {userPaymentMethods?.map((paymentMethod) => (
                <div
                  className="user-payment-method"
                  key={paymentMethod.paymentMethodId}
                >
                  <div className="method-info">
                    <div className="title-line">
                      Card ending in <code>{paymentMethod.last4}</code>
                    </div>
                    <div className="meta-line">
                      <span>{paymentMethod.network}</span>
                      {paymentMethod.applePay && <span>Apple Pay</span>}
                    </div>
                  </div>
                  <div className="method-button">
                    <form>
                      <SubmitButton
                        textLabels={{
                          disabled: "Use Card",
                          submitting: "Processing...",
                          enabled: "Use Card",
                        }}
                        onClick={() =>
                          submitWithExistingCard(paymentMethod.paymentMethodId)
                        }
                        disabled={processing || succeeded}
                        resetOnChange={{ processing, succeeded }}
                      />
                    </form>
                  </div>
                </div>
              ))}

              {paymentRequest && clientSecret.length > 0 && (
                <div className="apple-pay-wrapper">
                  <PaymentRequestButtonElement
                    options={{
                      paymentRequest,
                      style: {
                        paymentRequestButton: {
                          theme: isDarkMode ? "light" : "dark",
                          type: "buy",
                          height: "36px",
                        },
                      },
                    }}
                  />
                  <p className="invoice-subtext">or</p>
                </div>
              )}

              <form id="payment-form" onSubmit={submitWithNewCard}>
                <CardElement
                  id="card-element"
                  onChange={handleChange}
                  options={getElementsConfig()}
                />

                <SubmitButton
                  textLabels={{
                    disabled: "Complete Purchase",
                    submitting: "Processing...",
                    enabled: "Complete Purchase",
                  }}
                  onClick={submitWithNewCard}
                  disabled={processing || disabled || succeeded}
                  resetOnChange={{ processing, disabled, succeeded }}
                />

                {/* Show any error that happens when processing the payment */}

                {error && (
                  <p className="invoice-subtext" role="alert">
                    {error}
                  </p>
                )}
              </form>
            </div>
          )}

          {succeeded && (
            <div className="thank-you">
              <h2>🎉 Thank You!</h2>
              <p>Your purchase will be available in your account shortly.</p>

              <SubmitButton
                textLabels={{
                  disabled: "Close",
                  submitting: "Closing...",
                  enabled: "Close",
                }}
                disabled={false}
                onClick={handleClose}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
