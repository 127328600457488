import styled from "styled-components/macro";

const ModalBackdrop = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #333;
  opacity: 0.8;

  @include darkMode {
    background-color: #222;
  }
`;

const ModalContainer = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #0a2239;
`;

const ModalContent = styled.div`
  && {
    z-index: 10;
    flex: 1;

    border-radius: 10px 10px 0 0;
    padding: 2vh 5% 30vh;
    position: relative;

    @include darkMode {
      background-color: #000;
      color: #eee;
      box-shadow: 0 -4px 6px #111;
    }

    .closeModal {
      border: 0;
      font-size: 3rem;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      margin: 1.5rem;
    }
  }
`;

type ModalProps = {
  children: React.ReactNode;
  handleClose: () => void;
};

export default function GenericModal({ children, handleClose }: ModalProps) {
  return (
    <>
      <ModalBackdrop />
      <ModalContainer>
        <ModalContent>
          <button className="closeModal" onClick={handleClose}>
            &times;
          </button>
          {children}
        </ModalContent>
      </ModalContainer>
    </>
  );
}
