import React from "react";
import { ThemeName } from "./constants";
import { ThemeUpdatePayload } from "./types/eventPayloads";

export const defaultTheme: ThemeUpdatePayload = {
  themeName: ThemeName.Default,
  dark: {
    detail: "#123C64",
    shadow: "#15497A",
    primary: "#0A2239",
    negative: "#BA1200",
    positive: "#4CB944",
    secondary: "#1D84B5",
    detailShadow: "#0C2741",
    noLogoBackground: false,
    primaryTextColor: "#FFFFFF",
    secondaryTextColor: "#000000",
  },
  light: {
    detail: "#faeff0",
    shadow: "#FFA9B0",
    primary: "#FFE6E8",
    negative: "#F48498",
    positive: "#ACD8AA",
    secondary: "#F2CCC3",
    detailShadow: "#E0E0E0",
    noLogoBackground: false,
    primaryTextColor: "#000000",
    secondaryTextColor: "#FFFFFF",
  },
};

export const ThemeContext = React.createContext<ThemeUpdatePayload>(
  defaultTheme,
);
