import { gql } from "@apollo/client";

const END_OF_ROUND_UPSELL = gql`
  query endOfRoundUpsell($gameRoomId: String!, $playerId: String!) {
    gameRoom(gameRoomId: $gameRoomId) {
      gameRounds {
        gameRoundId
      }
      roomConfig {
        packsEnabled {
          packId
        }
      }
    }
    recommendedPack(playerId: $playerId, essentialExpansionOnly: true) {
      packName
      priceCents
      marketingDescription
      ownedByUser
      packId
      isSeasonal
      invoiceId
      appleProductId
    }
  }
`;

export default END_OF_ROUND_UPSELL;
