import { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { PusherContext } from "../pusherContext";
import { TCLogo } from "../TCLogo/TCLogo";
import { Helmet } from "react-helmet";
import GrantPacks from "./GrantPacks";
import BrowseGameSessions from "./GameSessions";
import { trackPage } from "../SegmentManager";

const AdminWrapper = styled.div`
  .cardContainer {
    margin-bottom: 3vh;
  }

  .cardContents {
    padding: 2rem;

    h3 {
      font-family: "Righteous", sans-serif;
      font-size: 2rem;
      font-weight: normal;
      text-align: center;
      margin: 0;
    }

    p {
      text-align: center;
      font-size: 1.8rem;
      width: 80%;
      margin: 1rem auto;
    }

    input[type="text"] {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .selectedUser,
  .selectedPack {
    width: 80%;
    margin: 0.5rem auto;

    p {
      text-align: center;
      font-weight: 700;
    }

    button {
      margin-top: 1rem;
    }
  }

  .userResult,
  .packResult {
    width: 80%;
    margin: 0.2rem auto;
    padding: 1rem 0;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const AdminMode = () => {
  const history = useHistory();
  const { me } = useContext(PusherContext);

  useEffect(() => {
    trackPage("Admin Page", "Home");
  }, []);

  // redirect back to home if they shouldn't be here, keeping in mind the backend routes check for real
  useEffect(() => {
    if (me && !me.isAdmin) {
      history.push("/");
    }
  }, [me, history]);

  const goHome = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <AdminWrapper>
      <Helmet>
        <title>Got 'Em: The Admin Hole</title>
      </Helmet>
      <TCLogo subtitle="The Admin Hole" onClick={goHome} />

      {/* Module: Grant packs to users */}
      <GrantPacks />

      {/* Module: View details of recent game sessions */}
      <BrowseGameSessions />
    </AdminWrapper>
  );
};
