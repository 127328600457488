import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components/macro";
import { PusherContext } from "../pusherContext";
import { InlinePlayerName } from "../design-components/InlinePlayerName/InlinePlayerName";

import "../GuessPhase/SelectionModal.scss";
import { SubmitButton } from "../design-components/SubmitButton/SubmitButton";
import { kickUser } from "../api/room";
import { AddBotButton } from "../AddBotButton/AddBotButton";
import { trackPage } from "../SegmentManager";

const Modal = styled.div`
  && {
    h1 {
      font-family: "Righteous", sans-serif;
      font-size: 2.2rem;
      font-weight: normal;
      text-align: center;
    }

    .explanation {
      width: 70%;
      margin: 2rem auto;
      font-size: 0.8em;
      line-height: 1.4em;
      text-align: center;
      opacity: 0.7;
    }

    .add-bot-container {
      text-align: center;
    }
  }
`;

const PlayerControl = styled.div`
  && {
    display: flex;
    justify-content: space-between;

    .inlinePlayerName {
      margin: 1em 0;
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        margin-bottom: 0;
      }
    }
  }
`;

interface Props {
  handleClose: (e: any) => void;
}

export default function ManagePlayersModal({ handleClose }: Props) {
  const { onlineUsers, me, pusherPin } = useContext(PusherContext);

  useEffect(() => {
    trackPage("Game", "Manage Players");
  }, []);

  const handleKick = useCallback(
    (userId: string) => async () => {
      if (!pusherPin) {
        return;
      }

      if (window.confirm("Are you sure you want to kick this player?")) {
        await kickUser(pusherPin, userId);
      }
    },
    [pusherPin],
  );

  return (
    <Modal>
      <div className="modalOverlay" onClick={handleClose} />
      <div className="selectionModal">
        <div className="modalSpacer" />
        <div className="modalContent">
          <button className="closeModal" onClick={handleClose}>
            &times;
          </button>
          <h1>Manage Players</h1>

          <div className="add-bot-container">
            <AddBotButton clickEventName="Add Bot Clicked (Manage Players)" />
          </div>

          <p className="explanation">
            If someone's phone died or they fell asleep, kick 'em out. They'll
            have a chance to veto.
          </p>

          {Object.entries(onlineUsers)
            .filter(([userId]) => userId !== me?.userId)
            .map(([userId, player]) => (
              <PlayerControl key={userId}>
                <InlinePlayerName playerId={userId} scalingFactor={1.5} />
                <div className="buttonContainer">
                  <SubmitButton
                    textLabels={{
                      disabled: "Kick",
                      enabled: "Kick",
                      submitting: "Kick",
                    }}
                    disabled={false}
                    onClick={handleKick(userId)}
                  />
                </div>
              </PlayerControl>
            ))}
        </div>
      </div>
    </Modal>
  );
}
