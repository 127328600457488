import React, { useContext, useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { useMutation } from "@apollo/client";
import { UpcomingGuesser } from "./UpcomingGuesser";
import { PromptForm } from "./PromptForm";
import { PusherContext } from "../pusherContext";
import { SubmittedAnswer } from "./SubmittedAnswer";
import { AuxButton } from "../design-components/AuxButton/AuxButton";
import { ThemeContext } from "../themeContext";
import SUBMIT_ANSWER_MUTATION from "./SubmitAnswerMutation";
import {
  submitAnswer,
  submitAnswerVariables,
} from "./__generated__/SubmitAnswer";

import "./PromptScreen.scss";
import { BigPrompt } from "../design-components/BigPrompt/BigPrompt";
import { trackEvent, trackPage } from "../SegmentManager";

const VetoContainer = styled.div`
  && {
    margin-bottom: 0.3em;
  }
`;

export const PromptScreen = () => {
  const pusherContext = useContext(PusherContext);
  const tcTheme = useContext(ThemeContext);

  const [submitAnswerMutation] = useMutation<
    submitAnswer,
    submitAnswerVariables
  >(SUBMIT_ANSWER_MUTATION);

  const [vetoed, setVetoed] = useState(false);
  const [vetoLabel, setVetoLabel] = useState("");
  const [vetoCount, setVetoCount] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const [hasResponded, setHasResponded] = useState(false);

  useEffect(() => {
    trackPage("Game", "Prompt Screen");
  }, []);

  const handleVetoClick = useCallback(async () => {
    try {
      if (!vetoed) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND_HOST}/round/vetoPrompt`,
        );
        setVetoed(true);
        trackEvent("Prompt Veto Submitted");
      } else {
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_HOST}/round/vetoPrompt`,
        );
        setVetoed(false);
        trackEvent("Prompt Veto Retracted");
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [vetoed]);

  useEffect(() => {
    if (pusherContext.respondedUsers && pusherContext.userId) {
      if (
        Object.keys(pusherContext.respondedUsers).includes(pusherContext.userId)
      ) {
        setHasResponded(true);
      } else {
        setHasResponded(false);
      }
    }
  }, [pusherContext.respondedUsers, pusherContext.userId]);

  useEffect(() => {
    setHasResponded(false);
    setVetoed(false);
  }, [pusherContext.currentPromptId]);

  useEffect(() => {
    // Vote to Skip
    if (vetoed) {
      setVetoLabel(`Cancel Vote`);
    } else {
      setVetoLabel("Vote to Skip");
    }
  }, [vetoed]);

  useEffect(() => {
    if (pusherContext.vetoCount > 0) {
      setVetoCount(
        ` (${pusherContext.vetoCount}/${pusherContext.vetosNeeded})`,
      );
    } else {
      setVetoCount("");
    }
  }, [pusherContext.vetoCount, pusherContext.vetosNeeded]);

  const submitAnswer = async (answer: string, wasPhonedIn: boolean) => {
    console.debug("Submitting answer: ", answer);
    setSubmitting(true);

    try {
      await submitAnswerMutation({
        variables: {
          answer,
          wasPhonedIn,
        },
      });
    } catch (err) {
      setSubmitting(false);
      Sentry.captureException(err);
    }
  };

  if (hasResponded) {
    return <SubmittedAnswer />;
  }
  return (
    <div className="answerForm">
      <VetoContainer>
        <AuxButton tcTheme={tcTheme} onClick={handleVetoClick}>
          {vetoLabel}
          {vetoCount}
        </AuxButton>
      </VetoContainer>
      <BigPrompt promptText={pusherContext.currentPrompt} />
      <UpcomingGuesser />
      <PromptForm submitAnswer={submitAnswer} submitting={submitting} />
    </div>
  );
};
