import React, { useState, useEffect, useContext } from "react";
import { useTimedQueue } from "../hooks/useTimedQueue";
import "./TextNotificationBar.scss";
import { PusherContext } from "../pusherContext";
import { TC_CONSTANTS } from "../constants";
import { TextNotificationPayload } from "../types/eventPayloads";

export const TextNotificationBar = () => {
  const { pusherChannel } = useContext(PusherContext);
  const [currentNotification, pushNotification] = useTimedQueue<string>(8000);

  const [displayedNotification, setDisplayedNotification] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [notificationOffset, setNotificationOffset] = useState(0);

  // bind and unbind to notifications from Pusher
  useEffect(() => {
    if (!pusherChannel) {
      return;
    }

    pusherChannel.bind(
      TC_CONSTANTS.EVENTS.TEXT_NOTIFICATION,
      ({ message }: TextNotificationPayload) => {
        pushNotification(message);
      },
    );
    return () => {
      pusherChannel.unbind(TC_CONSTANTS.EVENTS.TEXT_NOTIFICATION);
    };
  }, [pusherChannel, pushNotification]);

  // copy current notification from timed queue to use in display
  useEffect(() => {
    let timeout;

    if (currentNotification !== null) {
      if (displayedNotification !== currentNotification) {
        setDisplayedNotification(`${currentNotification}`);
        setIsShown(true);
      }
    } else {
      // hide notification text
      setIsShown(false);

      // clear stale data from displayedNotification after animation is over
      timeout = setTimeout(() => {
        setDisplayedNotification("");
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [currentNotification, displayedNotification]);

  // build style properties that control notification visibility/animation
  useEffect(() => {
    if (isShown) {
      setNotificationOffset(0);
    } else {
      setNotificationOffset(-10);
    }
  }, [isShown]);

  return (
    <div className="text-notification-bar">
      <p
        style={{
          transform: `translateY(${notificationOffset}rem)`,
        }}
      >
        {displayedNotification || " "}
      </p>
    </div>
  );
};
