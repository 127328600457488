import styled from "styled-components/macro";

export const BlockInput = styled.input`
  && {
    border-radius: 5px;
    display: block;
    width: 100%;
    background-color: #fff;
    color: #333;
    font-size: 1.6rem;
    box-shadow: 0 0 5px #000;
  }
`;
