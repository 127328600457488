/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The type of failure that occurred during authentication
 */
export enum AuthenticationFailure {
  AccountAlreadyExists = "AccountAlreadyExists",
  AccountNotFound = "AccountNotFound",
  GoogleError = "GoogleError",
  IncorrectPassword = "IncorrectPassword",
  InvalidEmailAddress = "InvalidEmailAddress",
  MissingData = "MissingData",
  MustUseGoogle = "MustUseGoogle",
  UnknownError = "UnknownError",
}

/**
 * The type of point scored, e.g. CorrectGuess
 */
export enum PointEvent {
  CorrectGuess = "CorrectGuess",
  DrySpellBroken = "DrySpellBroken",
  GuessedSelf = "GuessedSelf",
  LastOneOut = "LastOneOut",
  MostDeceivingAnswer = "MostDeceivingAnswer",
  MostFrequentSuspect = "MostFrequentSuspect",
  QuickAnswer = "QuickAnswer",
  RepeatedGuess = "RepeatedGuess",
  SlowAnswer = "SlowAnswer",
  StreakBonus = "StreakBonus",
  WorstGuesser = "WorstGuesser",
}

/**
 * Email address and password for logging into or creating a Got 'Em account
 */
export interface AuthInput {
  email: string;
  password: string;
}

/**
 * Display name, email address and password for creating a Got 'Em account
 */
export interface SignupInput {
  email: string;
  password: string;
  displayName: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
