import { useContext } from "react";
import styled from "styled-components/macro";
import { lightDarkVariant } from "../lib/lightDarkVariant";
import { ThemeContext } from "../themeContext";
import { ThemedComponentProps } from "../types/ThemedComponentProps";

interface WrapperProps {
  parentBackgroundIsBody: boolean;
}

const Wrapper = styled.div<ThemedComponentProps & WrapperProps>`
  && {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    .cutout {
      border-radius: 50%;
      width: 12rem;
      height: 12rem;
      margin: 0 auto;
      position: relative;
      top: -7rem;

      ${(props) => {
        if (props.parentBackgroundIsBody) {
          return lightDarkVariant("background-color", {
            light: "#fff",
            dark: "#000",
          });
        }
        return lightDarkVariant("background-color", {
          light: props.tcTheme.light.primary,
          dark: props.tcTheme.dark.primary,
        });
      }}
    }

    span {
      position: relative;
      top: -17.5rem;
      font-size: 8rem;

      &.correct {
        ${(props) =>
          lightDarkVariant("color", {
            light: props.tcTheme.light.positive,
            dark: props.tcTheme.dark.positive,
          })}
      }

      &.incorrect {
        ${(props) =>
          lightDarkVariant("color", {
            light: props.tcTheme.light.negative,
            dark: props.tcTheme.dark.negative,
          })}
      }
    }
  }
`;

interface Props {
  correct: boolean;
  parentBackgroundIsBody?: boolean;
}

/**
 * Display a checkmark or X depending on whether or not a guess was correct.
 */
export default function TopIcon({
  correct,
  parentBackgroundIsBody = false,
}: Props) {
  const tcTheme = useContext(ThemeContext);

  return (
    <Wrapper tcTheme={tcTheme} parentBackgroundIsBody={parentBackgroundIsBody}>
      <div className="cutout" />
      <span className={correct ? "correct" : "incorrect"}>
        {correct && "✓"}
        {!correct && "𝐗"}
      </span>
    </Wrapper>
  );
}
