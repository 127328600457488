import React, { useContext } from "react";
import styled from "styled-components/macro";
import { ProfilePicContainer } from "../design-components/ProfilePicContainer/ProfilePicContainer";
import { PusherContext } from "../pusherContext";

const GuesserContainer = styled.div`
  && {
    font-size: 0.8em;
    margin: 1.2em 0 1.5em;

    span {
      opacity: 0.8;
    }

    .profilePicContainer {
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      border: 1px solid #fff;
      box-shadow: 0 0 5px #000;
      margin-bottom: -0.4em;
      margin-right: 0.4em;
    }
  }
`;

export const UpcomingGuesser = () => {
  const pusherContext = useContext(PusherContext);
  if (!pusherContext.guesserId) {
    return null;
  }

  const guesser = pusherContext.humanPlayers[pusherContext.guesserId];
  if (!guesser) {
    return null;
  }

  if (pusherContext.guesserId === pusherContext.userId) {
    return (
      <GuesserContainer>
        <ProfilePicContainer profilePicture={guesser.profilePicture} />
        <b>You</b>
        <span> are the first guesser</span>
      </GuesserContainer>
    );
  }

  return (
    <GuesserContainer>
      <ProfilePicContainer profilePicture={guesser.profilePicture} />
      <b>{guesser.name}</b>
      <span> is the first guesser</span>
    </GuesserContainer>
  );
};
