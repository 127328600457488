import { useCallback, useContext } from "react";
import { ThemeContext } from "../themeContext";
import "./TCLogo.scss";

export type TCLogoProps = {
  onClick?: CallableFunction;
  plainColor?: boolean;
  primaryEmoji?: string;
  splashScreen?: boolean;
  subtitle?: string;
};

export const TCLogo = (props: TCLogoProps) => {
  const themeContext = useContext(ThemeContext);
  const { subtitle, primaryEmoji } = props;

  const handleClick = useCallback(() => {
    if (props.onClick) props.onClick();
  }, [props]);

  const divClasses = ["logo"];
  if (primaryEmoji?.length) {
    divClasses.push("withEmoji");
  }
  if (!!props.onClick) {
    divClasses.push("clickable");
  }

  if (!!props.plainColor || themeContext.dark.noLogoBackground) {
    divClasses.push("plainColor");
  }

  if (!!props.splashScreen) {
    divClasses.push("splash-screen");
  }

  return (
    <div className={divClasses.join(" ")}>
      <img
        alt="Got 'Em"
        src="/assets/logo.png"
        onClick={handleClick}
        className="logo-img"
      />
      {subtitle && <h2>{subtitle}</h2>}
    </div>
  );
};
