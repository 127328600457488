import { useState, useContext, useCallback, useEffect } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { SelectionModal } from "./SelectionModal";
import { AppState } from "../types/AppState";
import { PusherContext } from "../pusherContext";
import { SelectionModalProps } from "./SelectionModal";
import { BigPrompt } from "../design-components/BigPrompt/BigPrompt";
import { GameResponse } from "../design-components/GameResponse/GameResponse";
import { Card } from "../design-components/Card/Card";
import { ThemedComponentProps } from "../types/ThemedComponentProps";
import { lightDarkVariant } from "../lib/lightDarkVariant";
import { ThemeContext } from "../themeContext";
import ViewLastGuess from "./ViewLastGuess";
import { AuxButton } from "../design-components/AuxButton/AuxButton";

import "./GuesserInterface.scss";

const GuesserInterfaceWrapper = styled.div.attrs(() => ({
  className: "guesserInterface",
}))<ThemedComponentProps>`
  && {
    .guessInput {
      div.cardContents {
        padding-top: 0;

        .tap-label {
          display: block;
          text-align: center;
          margin: 0.5rem 0 1.2rem;

          p {
            border-radius: 5px;
            display: inline;
            margin: 0;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2rem;
            opacity: 0.8;
            padding: 0.4rem 0.6rem;
            text-align: center;
            text-transform: uppercase;
            width: 30%;

            ${(props) =>
              lightDarkVariant("background-color", {
                light: props.tcTheme.light.detailShadow,
                dark: props.tcTheme.dark.detailShadow,
              })}
          }
        }
      }
    }
  }
`;

// convert data structures to generic form for selection modal
const getAnswerList = (
  answers: AppState["currentAnswers"],
): SelectionModalProps["options"] => {
  return answers.reduce(
    (acc, { answerId, answer }): SelectionModalProps["options"] => {
      return {
        ...acc,
        [answerId]: {
          title: answer,
        },
      };
    },
    {},
  );
};

const getPlayerList = (
  users: AppState["unguessedUsers"],
  myUserId: string,
): SelectionModalProps["options"] => {
  // TODO: allow this to be configured remotely from the server config endpoint
  const FALLBACK_IMAGE = "/assets/feels_guy.png";

  return Object.entries(users).reduce(
    (
      acc,
      [userId, { name, profilePicture }],
    ): SelectionModalProps["options"] => {
      // you're not allowed to guess yourself
      if (userId === myUserId) {
        return acc;
      }
      return {
        ...acc,
        [userId]: {
          title: name,
          image: profilePicture || FALLBACK_IMAGE,
        },
      };
    },
    {},
  );
};

export const GuesserInterface = () => {
  const pusherContext = useContext(PusherContext);
  const tcTheme = useContext(ThemeContext);

  // guess data
  const [chosenAnswer, setChosenAnswer] = useState<string | null>(null);
  const [chosenPlayer, setChosenPlayer] = useState<string | null>(null);

  // UI state
  const [choosingAnswer, setChoosingAnswer] = useState(false);
  const [choosingPlayer, setChoosingPlayer] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const submitGuess = useCallback(() => {
    axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/room/${pusherContext.pusherPin}/submitGuess`,
      {
        chosenAnswer,
        chosenPlayer,
      },
    );
  }, [chosenAnswer, chosenPlayer, pusherContext]);

  const modalControls = {
    openChooseAnswer: useCallback(() => {
      setChoosingAnswer(true);
    }, []),
    openChoosePlayer: useCallback(() => {
      setChoosingPlayer(true);
    }, []),
    closeChooseAnswer: useCallback(() => {
      setChoosingAnswer(false);
    }, []),
    closeChoosePlayer: useCallback(() => {
      setChoosingPlayer(false);
    }, []),
  };

  // reset UI state after a guess has been evaluated
  useEffect(() => {
    setChosenAnswer(null);
    setChoosingAnswer(false);
  }, [pusherContext.guessesEvaluated]);

  // reset UI state after a guess has been evaluated
  useEffect(() => {
    setChosenPlayer(null);
    setChoosingPlayer(false);
  }, [pusherContext.guessesEvaluated]);

  // manage submit button disabled state
  useEffect(() => {
    if (chosenAnswer === null || chosenPlayer === null) {
      setSubmitDisabled(true);
      return;
    }
    setSubmitDisabled(false);
  }, [chosenAnswer, chosenPlayer]);

  console.log(pusherContext.lastGuess);

  return (
    <>
      {choosingAnswer && (
        <SelectionModal
          handleClose={modalControls.closeChooseAnswer}
          handleSelection={setChosenAnswer}
          headerText="Choose A Response"
          options={getAnswerList(pusherContext.remainingAnswers)}
        />
      )}
      {choosingPlayer && pusherContext.userId && (
        <SelectionModal
          handleClose={modalControls.closeChoosePlayer}
          handleSelection={setChosenPlayer}
          headerText="Who Wrote It?"
          options={getPlayerList(
            pusherContext.unguessedUsers,
            pusherContext.userId,
          )}
        />
      )}

      <GuesserInterfaceWrapper tcTheme={tcTheme}>
        {pusherContext.lastGuess && <ViewLastGuess />}
        {!pusherContext.lastGuess && <h3>You're the Guesser!</h3>}

        <BigPrompt promptText={pusherContext.currentPrompt} />

        <div className="flex-spacer" />

        <div className="guessInput">
          <h3>Choose a response</h3>
          {!chosenAnswer && (
            <AuxButton
              tcTheme={tcTheme}
              onClick={modalControls.openChooseAnswer}
            >
              Tap to Choose
            </AuxButton>
          )}
          {chosenAnswer && (
            <>
              <GameResponse
                onClick={modalControls.openChooseAnswer}
                responseTitle={
                  getAnswerList(pusherContext.currentAnswers)[chosenAnswer]
                    .title
                }
                responseImage={
                  getAnswerList(pusherContext.currentAnswers)[chosenAnswer]
                    .image
                }
              >
                <div className="tap-label">
                  <p>Tap to Change</p>
                </div>
              </GameResponse>
            </>
          )}
        </div>

        <div className="flex-spacer" />

        <div className="guessInput">
          <h3>And guess who wrote it</h3>
          {!chosenPlayer && (
            <AuxButton
              tcTheme={tcTheme}
              onClick={modalControls.openChoosePlayer}
            >
              Tap to Choose
            </AuxButton>
          )}
          {chosenPlayer && pusherContext.userId && (
            <>
              <GameResponse
                onClick={modalControls.openChoosePlayer}
                responseTitle={
                  getPlayerList(
                    pusherContext.unguessedUsers,
                    pusherContext.userId,
                  )[chosenPlayer]?.title ?? "Unknown Player"
                }
                responseImage={
                  getPlayerList(
                    pusherContext.unguessedUsers,
                    pusherContext.userId,
                  )[chosenPlayer]?.image ?? undefined
                }
              >
                <div className="tap-label">
                  <p>Tap to Change</p>
                </div>
              </GameResponse>
            </>
          )}
        </div>

        <div className="flex-spacer" />

        <Card
          submitButtonProps={{
            onClick: submitGuess,
            disabled: submitDisabled,
            textLabels: {
              disabled: "Choose a Response + Who Wrote It",
              enabled: "Lock It In",
              submitting: "Checking Guess...",
            },
            resetOnChange: pusherContext.guessesEvaluated,
          }}
        />
      </GuesserInterfaceWrapper>

      <div className="guessSubmit"></div>
    </>
  );
};
