import { useCallback, useContext } from "react";
import { ThemeContext } from "../themeContext";
import { TCLogoProps } from "./TCLogo";
import "./TCLogo.scss";

/**
 * A smaller version of the main Got 'Em logo that renders left-aligned in its container.
 */
export const TCLogoSmall = (
  props: Omit<TCLogoProps, "primaryEmoji" | "plainColor">,
) => {
  const themeContext = useContext(ThemeContext);

  const handleClick = useCallback(() => {
    if (props.onClick) props.onClick();
  }, [props]);

  const divClasses = ["logo", "logo-small"];

  if (!!props.onClick) {
    divClasses.push("clickable");
  }

  if (themeContext.dark.noLogoBackground) {
    divClasses.push("plainColor");
  }

  if (!!props.splashScreen) {
    divClasses.push("splash-screen");
  }

  return (
    <div className={divClasses.join(" ")}>
      <img
        alt="Got 'Em"
        src="/assets/logo.png"
        onClick={handleClick}
        className="logo-img"
      />
    </div>
  );
};
