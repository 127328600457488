import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import styled from "styled-components/macro";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import { Card } from "../../design-components/Card/Card";
import { ProfilePicContainer } from "../../design-components/ProfilePicContainer/ProfilePicContainer";
import { PusherContext } from "../../pusherContext";
import { updateUser, createPresignedUploadNew } from "../../api/users";

import "./PlayerSettings.scss";
import { SubmitButtonProps } from "../../design-components/SubmitButton/SubmitButton";
import { AuxButton } from "../../design-components/AuxButton/AuxButton";
import { ThemeContext } from "../../themeContext";
import { trackEvent, trackPage } from "../../SegmentManager";

const InputContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    input {
      flex-shrink: 1;
    }

    fieldset {
      margin-bottom: 3vh;
    }
  }
`;

const CurrentPictureContainer = styled.div`
  && {
    .profilePicContainer {
      margin: auto;
      width: 150px;
      height: 150px;
      z-index: 10;
    }
  }
`;

const NewPictureContainer = styled.div`
  margin: 0 auto;
  text-align: center;

  input {
    max-width: 100%;
  }
`;

const Spacer = styled.div`
  margin-top: 3rem;
`;

export const PlayerSettings = () => {
  const tcTheme = useContext(ThemeContext);
  const { me, updateWhoAmI } = useContext(PusherContext);
  const [newName, setNewName] = useState("");
  const [newImage, setNewImage] = useState<any>(null);
  const [scale, setScale] = useState(1);
  const editor = useRef<AvatarEditor>();

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop: handleDrop,
  // });

  useEffect(() => {
    trackPage("Account", "Player Settings");
  }, []);

  useEffect(() => {
    if (!!me) {
      setNewName(me.name);
    }
  }, [me]);

  // change name
  const handleNameChange = useCallback(
    (e: any) => {
      setNewName(e.target.value);
    },
    [setNewName],
  );

  const submitNameChange = useCallback(async () => {
    trackEvent("Name Changed");

    console.debug("Setting name to", { newName });
    await updateUser({
      name: newName,
    });
    updateWhoAmI();
  }, [newName, updateWhoAmI]);

  // profile picture
  const handleUpload = useCallback(
    async (e?: React.MouseEvent<any>) => {
      e?.preventDefault();

      trackEvent("Profile Photo Changed", {
        scale,
      });

      const { imageId, presignedUrl } = await createPresignedUploadNew(
        newImage.name,
      );

      const croppedImage = editor.current.getImage();
      croppedImage.toBlob(async (blob) => {
        await axios.put(presignedUrl, blob, {
          headers: {
            "Content-Type": newImage.type,
          },
          withCredentials: false,
        });

        // register image with user profile on backend
        await updateUser({
          imageId,
        });

        // reload profile so we can see the changes immediately
        await updateWhoAmI();

        // reset uploader/cropper state
        setNewImage(null);
        setScale(1);
      });
    },
    [scale, newImage, updateWhoAmI],
  );

  const handleDrop = (files) => {
    trackEvent("Profile Photo Dropped");
    const file = files[0];
    setNewImage(file);
  };

  const handleScale = (e: React.FormEvent<HTMLInputElement>) => {
    const scale = parseFloat(e.currentTarget.value);
    setScale(scale);
  };

  const handleCancelImage = () => {
    trackEvent("Profile Photo Canceled");
    setNewImage(null);
    setScale(1);
  };

  const getProfilePicSubmitProps = useCallback(():
    | SubmitButtonProps
    | undefined => {
    if (!newImage) {
      return undefined;
    }

    return {
      onClick: handleUpload,
      disabled: false,
      textLabels: {
        enabled: "Crop & Upload",
        submitting: "Uploading...",
        disabled: "Upload a New Image",
      },
    };
  }, [newImage, handleUpload]);

  const baseCanvasSize =
    document.documentElement.clientWidth > 400
      ? {
          width: 250,
          height: 250,
          borderRadius: 125,
          border: 50,
        }
      : {
          width: 200,
          height: 200,
          borderRadius: 100,
          border: 40,
        };

  return (
    <>
      <Card
        title="Your Name"
        submitButtonProps={{
          textLabels: {
            disabled: "Update Name",
            enabled: "Update Name",
            submitting: "Updating...",
          },
          disabled: me?.name === newName,
          onClick: submitNameChange,
          resetOnChange: me?.name,
        }}
      >
        <form>
          <InputContainer>
            <fieldset>
              <input
                type="text"
                name="name"
                id="name"
                maxLength={20}
                value={newName}
                onChange={handleNameChange}
              />
            </fieldset>
          </InputContainer>
        </form>
      </Card>

      <Card
        title="Profile Picture"
        submitButtonProps={getProfilePicSubmitProps()}
      >
        <NewPictureContainer>
          {!newImage && me && (
            <CurrentPictureContainer>
              <ProfilePicContainer profilePicture={me.profilePicture} />
            </CurrentPictureContainer>
          )}

          {newImage && (
            <>
              <AvatarEditor
                width={baseCanvasSize.width}
                height={baseCanvasSize.height}
                image={newImage}
                border={baseCanvasSize.border}
                borderRadius={baseCanvasSize.borderRadius}
                scale={scale}
                ref={editor}
              />
              <Spacer>
                <input
                  name="scale"
                  type="range"
                  min={1}
                  max={2}
                  step={0.01}
                  defaultValue={1}
                  onChange={handleScale}
                />
              </Spacer>
              <Spacer>
                <AuxButton tcTheme={tcTheme} onClick={handleCancelImage}>
                  Cancel New Image
                </AuxButton>
              </Spacer>
            </>
          )}
        </NewPictureContainer>

        {!newImage && (
          <Dropzone onDrop={handleDrop} accept="image/*">
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Spacer>
                  <AuxButton tcTheme={tcTheme}>
                    Tap here or drag and drop
                  </AuxButton>
                </Spacer>
              </div>
            )}
          </Dropzone>
        )}
      </Card>
    </>
  );

  // <LoginUpsell />
};
