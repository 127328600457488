import { useContext } from "react";
import styled from "styled-components/macro";
import { lightDarkVariant } from "../../lib/lightDarkVariant";
import { ThemeContext } from "../../themeContext";
import { ThemedComponentProps } from "../../types/ThemedComponentProps";

const Wrapper = styled.div.attrs(() => ({
  className: "infoBlurb",
}))<ThemedComponentProps>`
  && {
    width: 80%;
    margin: 0 auto;
    padding: 0 10%;
    border-radius: 5px;
    font-weight: 700;
    font-size: 1.8rem;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: center;

    p {
      flex: 1;
      margin: 0;
      padding: 1rem 0;

      &.emoji {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 0;
        font-size: 2.2rem;
        padding-right: 1.5rem;
      }
    }

    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.detail,
        dark: props.tcTheme.dark.detail,
      })};
  }
`;

interface Props {
  children: React.ReactChild;
  hideThatFinger?: boolean;
}

export default function InfoBlurb({ children, hideThatFinger = false }: Props) {
  const tcTheme = useContext(ThemeContext);

  return (
    <Wrapper tcTheme={tcTheme}>
      {!hideThatFinger && <p className="emoji">👉</p>}
      <p>{children}</p>
    </Wrapper>
  );
}
