import React, {
  useCallback,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import { PusherContext } from "../pusherContext";
import { Card, CardProps } from "../design-components/Card/Card";
import { SubmitButtonProps } from "../design-components/SubmitButton/SubmitButton";

type AuthFormProps = {
  authError: string | null;
  cardProps?: Omit<CardProps, "children">;
  children?: ReactNode;
  handleSubmit: (
    email: string,
    password: string,
    name?: string,
  ) => Promise<void>;
  mode: "login" | "signup";
};

export const AuthForm = (props: AuthFormProps) => {
  const { userName: ctxUsername } = useContext(PusherContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const [authError, setAuthError] = useState<string | null>(null);

  useEffect(() => {
    if (ctxUsername && ctxUsername?.length) {
      setName(ctxUsername);
    }
  }, [ctxUsername]);

  useEffect(() => {
    if (props.authError !== null) {
      setAuthError(props.authError);
    }
  }, [props.authError]);

  useEffect(() => {
    setAuthError(null);
  }, [props.mode]);

  const handleSubmit = useCallback(
    (e: any) => {
      setAuthError(null);

      if (props.mode === "login") {
        props.handleSubmit.apply(null, [email, password, undefined]);
      } else {
        props.handleSubmit.apply(null, [email, password, name]);
      }
    },
    [props.mode, props.handleSubmit, email, password, name],
  );

  const getPasswordAutocompleteType = useCallback(() => {
    if (props.mode === "login") {
      return "current-password";
    }
    return "new-password";
  }, [props.mode]);

  const getSubmitDisabled = useCallback(() => {
    if (props.mode === "login") {
      return !email.length || !password.length;
    }
    return !email.length || !password.length || !name.length;
  }, [email, password, name, props]);

  const getSubmitLabels = useCallback((): SubmitButtonProps["textLabels"] => {
    if (props.mode === "login") {
      return {
        disabled: "Log In",
        enabled: "Log In",
        submitting: "Logging in...",
      };
    }

    return {
      disabled: "Create Account",
      enabled: "Create Account",
      submitting: "Signing up...",
    };
  }, [props]);

  return (
    <Card
      submitButtonProps={{
        disabled: getSubmitDisabled(),
        onClick: handleSubmit,
        textLabels: getSubmitLabels(),
        resetOnChange: authError,
      }}
      {...props.cardProps}
    >
      {props.children}

      <form>
        <div className="inputContainer">
          <div style={{ flexGrow: 1 }} />

          <div className="emailContainer">
            <input
              type="text"
              name="email"
              id="email"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
            <label htmlFor="email">Email Address</label>
          </div>

          <div className="emailContainer">
            <input
              type="password"
              name="password"
              id="password"
              value={password || ""}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete={getPasswordAutocompleteType()}
            />
            <label htmlFor="password">Password</label>
          </div>

          {props.mode === "signup" && (
            <>
              <div className="emailContainer">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="username"
                />
                <label htmlFor="name">
                  Display Name{" "}
                  <p className="muted">(You can change this later)</p>
                </label>
              </div>
            </>
          )}

          {authError && (
            <>
              <p>
                <span role="img" aria-label="Hmmm">
                  🤔
                </span>{" "}
                {authError}
              </p>
              <br />
              <br />
            </>
          )}

          <div style={{ flexGrow: 2 }} />
        </div>
      </form>
    </Card>
  );
};
