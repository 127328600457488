import React, { useState, useCallback, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { Card } from "../design-components/Card/Card";
import { trackEvent } from "../SegmentManager";
import GET_PHONE_IN_ANSWER from "../queries/getPhoneInAnswer";
import { getPhoneInAnswer } from "../queries/__generated__/getPhoneInAnswer";

type PromptFormProps = {
  submitAnswer: (answer: string, wasPhonedIn: boolean) => void;
  submitting: boolean;
};

export const PromptForm = ({ submitAnswer }: PromptFormProps) => {
  const [initialized, setInitialized] = useState(false);
  const [answer, setAnswer] = useState("");
  const [canPhone, setCanPhone] = useState(true);
  const [lastPhoneAnswer, setLastPhoneAnswer] = useState("");

  const [phoneItIn] = useLazyQuery<getPhoneInAnswer>(GET_PHONE_IN_ANSWER, {
    fetchPolicy: "network-only",
  });

  // initialize answer from localStorage if present
  useEffect(() => {
    if (!initialized) {
      const draftAnswer = window?.localStorage.getItem("ANSWER_DRAFT");
      if (draftAnswer) {
        setAnswer(draftAnswer);
      }
      setInitialized(true);
    }
  }, [initialized]);

  // sync answer state with localStorage
  useEffect(() => {
    if (initialized) {
      if (answer.length > 0) {
        window?.localStorage.setItem("ANSWER_DRAFT", answer);
      } else {
        window?.localStorage.removeItem("ANSWER_DRAFT");
      }
    }
  }, [answer, initialized]);

  const handleChangeAnswer = useCallback(
    (e: any) => {
      setAnswer(e.target.value);
    },
    [setAnswer],
  );

  const handleSubmitAnswer = useCallback(() => {
    submitAnswer(answer, answer === lastPhoneAnswer);
    setAnswer("");
  }, [submitAnswer, answer, lastPhoneAnswer]);

  const handlePhoneItIn = useCallback(async () => {
    if (answer !== lastPhoneAnswer) {
      if (
        !window.confirm(
          "Are you sure? You'll lose everything you've written so far.",
        )
      ) {
        return;
      }
    }

    try {
      trackEvent("Phone In Used");

      const result = await phoneItIn();
      if (result.data?.phoneItIn) {
        setAnswer(result.data.phoneItIn);
        setLastPhoneAnswer(result.data.phoneItIn);
        setCanPhone(true);
      } else {
        throw new Error("No phone it in answer received");
      }
    } catch (error) {
      console.warn("Failed to phone it in");
      setCanPhone(false);
    }
  }, [answer, lastPhoneAnswer, phoneItIn]);

  return (
    <>
      <Helmet>
        <title>Submit Response</title>
      </Helmet>
      <form name="answerForm" className="answerForm">
        <div className="composeContainer">
          <textarea rows={10} value={answer} onChange={handleChangeAnswer} />

          <Card
            submitButtonProps={{
              disabled: answer.trim().length === 0,
              onClick: handleSubmitAnswer,
              textLabels: {
                disabled: "Type a Response",
                enabled: "Send It",
                submitting: "Sending...",
              },
            }}
          />
        </div>

        <Card
          title="Can't come up with anything?"
          submitButtonProps={{
            disabled: !canPhone,
            onClick: handlePhoneItIn,
            textLabels: {
              disabled: "You're On Your Own",
              enabled: "Phone It In",
              submitting: "Phoning...",
            },
            resetOnChange: answer,
          }}
        />
      </form>
    </>
  );
};
