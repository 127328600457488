import { gql } from "@apollo/client";

const recentGameSessionsQuery = gql`
  query recentGameSessions($skip: Float, $take: Float) {
    gameRooms(skip: $skip, take: $take) {
      gameRoomId
      createdAt
      gameRounds {
        gameRoundId
      }
      playerHistory {
        userId
        isAdmin
        clientType
      }
      roomConfig {
        nsfwEnabled
        packsEnabled {
          packId
          packName
        }
      }
    }
  }
`;

export default recentGameSessionsQuery;
