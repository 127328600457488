export type GameAnswer = {
  answerId: number;
  answer: string;
};

export type GameUser = {
  name: string;
  role: string;
  profilePicture: string;
  correctGuessStreak: number;
};

export type FullUser = {
  userId: string;
  lastJoinedRoom: string;
  isGuest: boolean;
  name: string;
  email: string;
  role: string;
  clientType: string;
  roundStatus: string;
  networkStatus: string;
  gameRoomPin: string;
  profilePicture: string;
  isAdmin: boolean;
};

export type UserStats = {
  pointsScored: number;
  roundsSwept: number;
  bestGuessStreak: number;
  roundsPlayed: number;
  wordsWritten: number;
  timesKicked: number;
};

export type GameUserCollection = {
  [userId: string]: GameUser;
};

export type BroadcastAnswersPayload = {
  answers: Array<GameAnswer>;
  remainingAnswers: Array<GameAnswer>;
};

export type PlayerListSyncPayload = {
  humanPlayers: GameUserCollection;
  onlineUsers: GameUserCollection;
  remainingUsers: GameUserCollection;
  respondedUsers: GameUserCollection;
  unguessedUsers: GameUserCollection;
  waitingRoom: GameUserCollection;
  guesserOrder: string[];
};

export type RoundSyncPayload = {
  currentPrompt: string;
  currentPromptId: number;
  gamePhase: string;
  gameRoomId: string;
  guesserId: string;
  totalPoints: Array<PointTotal>;
};

export type NewPromptPayload = {
  prompt: string;
  promptId: number;
};

export type NewGuesserPayload = {
  guesserId: string;
};

export enum PointEvent {
  CorrectGuess = "CorrectGuess",
  DrySpellBroken = "DrySpellBroken",
  GuessedSelf = "GuessedSelf",
  LastOneOut = "LastOneOut",
  MostDeceivingAnswer = "MostDeceivingAnswer",
  MostFrequentSuspect = "MostFrequentSuspect",
  QuickAnswer = "QuickAnswer",
  RepeatedGuess = "RepeatedGuess",
  SlowAnswer = "SlowAnswer",
  StreakBonus = "StreakBonus",
  WorstGuesser = "WorstGuesser",
}

export interface PointLineItem {
  event: PointEvent;
  playerId: string;
  pointValue: number;
}

export type GuessResultPayload = {
  answerGuessedId: string;
  playerGuessedId: string; // the player you thought wrote it
  guessCorrect: boolean;
  newGuesser: string; // might be the same
  playerWhoGuessed: string;
  correctGuessStreak: number; // the number of consecutive correct guesses this guesser has made (including this one)
  pointLineItems: PointLineItem[];
};

export type TextNotificationPayload = {
  message: string;
};

export type KickNotificationPayload = {
  kickerId: string;
  playerBeingKickedId: string;
  executeAt: string; // ISO Date
};

export type PointTotal = {
  playerId: string;
  points: number;
};

export type FinishRoundPayload = {
  gameRoundId: string;
  roundPoints: PointTotal[];
  totalPoints: PointTotal[];
  roundSwept: boolean;
};

export type OutOfPromptsPayload = {
  morePromptsAvailable: boolean;
  roundsPlayed: number;
};

export type ColorThemeData = {
  primary: string;
  secondary: string;
  detail: string;
  shadow: string;
  detailShadow: string;
  positive: string;
  negative: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  noLogoBackground: boolean;
};

// ColorThemeData generates a few fields that shouldn't be customized
export interface DefineColorThemeData
  extends Omit<ColorThemeData, "detail" | "shadow" | "detailShadow"> {
  detail?: string;
}

export type ThemeUpdatePayload = {
  themeName: string;
  light: ColorThemeData;
  dark: ColorThemeData;
};

export type VetoCountUpdatePayload = {
  promptId: number;
  vetoCount: number;
  vetosNeeded: number;
};

export interface NewRoomOptions {
  nsfwEnabled?: boolean;
  rotationMode?: string;
  requestedPackIds?: Array<string>;
  colorThemeName?: string;
  configPresetName?: string;
}

export interface RoomCreateConfig {
  startDate: string;
  endDate: string;
  slug: string;
  primaryEmoji: string;
  secondaryEmoji: string;
  description: string;
  packNames: Array<string>;
  requestedPackIds: Array<string>;
  colorThemeName: string;
  colorTheme: ThemeUpdatePayload;
  userOwnsAllPacks?: boolean;
  packs: Array<ShopPack>;
}

export interface ShopPack {
  ownedByUser: boolean;
  packId: string;
  packName: string;
  priceCents: number;
  fullPriceCents: number;
  isSeasonal: boolean;
  marketingDescription: string;
  invoiceId: string | null;
  appleProductId: string;
  promptList?: string[];
  estimatedPromptCount: number | null;
}

export interface FeaturedConfigPack {
  pack: ShopPack;
  featuredConfig: RoomCreateConfig;
}

export interface ShopPackList {
  featuredConfigPacks: Array<FeaturedConfigPack>;
  promotedPacks: Array<ShopPack>;
  ownedPacks: Array<ShopPack>;
  notOwnedPacks: Array<ShopPack>;
  ownedPromptCount: number;
  notOwnedPromptCount: number;
}

export interface UserPaymentMethod {
  paymentMethodId: string;
  last4: string | null;
  network: string | null;
  applePay: boolean;
}

export interface GetMyAnswerPayload {
  answer: string;
}

export interface UserSearchResult {
  userId: string;
  name: string;
  email: string;
}

export interface MissingPackResult {
  packId: string;
  packName: string;
}

export interface GrantPackResult {
  invoiceId: string;
  playerEmail: string;
  packName: string;
}
