import styled from "styled-components/macro";

type ProfilePicContainerProps = {
  profilePicture: string | undefined;
};

// TODO: allow this to be configured remotely from the server config endpoint
const FALLBACK_IMAGE = "/assets/feels_guy.png";

export const ProfilePicContainer = styled.div.attrs(() => ({
  className: "profilePicContainer",
}))<ProfilePicContainerProps>`
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 15px #000;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${({ profilePicture }) =>
    `url('${profilePicture ?? FALLBACK_IMAGE}')`};
`;
