import axios from "axios";

export default async function getPusherChannel(pin: string) {
  console.debug("Joining game with PIN", pin);
  if (!pin) {
    throw new Error("Empty PIN");
  }

  const {
    data: { channel_name },
  } = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/room/${pin}`);

  return channel_name;
}
