import React, { useContext, useState, useEffect, useCallback } from "react";
import Decimal from "decimal.js";
import Measure, { ContentRect } from "react-measure";
import axios from "axios";
import styled, { css } from "styled-components/macro";
import * as Sentry from "@sentry/browser";

import { PusherContext } from "../pusherContext";
import { ResponsePager } from "./ResponsePager";
import { BigPrompt } from "../design-components/BigPrompt/BigPrompt";
import { TopToast } from "../design-components/TopToast";
import { TC_CONSTANTS } from "../constants";
import { useSeenTracker } from "../hooks/useSeenTracker";
import { GameResponse } from "../design-components/GameResponse/GameResponse";

interface ResponseStripProps {
  responseCount: number;
  currentResponseIndex: number;
  contentRect: ContentRect;
}

/**
 * Given the width of the container, calculate the amount of margin to render between cards so they appear as a carousel
 * @param contentRect The dimensions of the card's bounding parent
 */
const calculateResponseGutter = (contentRect: ContentRect) => {
  if (!contentRect.bounds?.width) {
    console.warn("Can't calculate without bounds", { contentRect });
    return;
  }

  const viewportWidth = window.innerWidth;
  const marginPerSide = new Decimal(viewportWidth)
    .minus(contentRect.bounds.width)
    .toNumber();

  return marginPerSide;
};

const ResponseStrip = styled.div<ResponseStripProps>`
  display: flex;
  flex-direction: row;
  width: 100%;

  .cardContainer {
    margin-bottom: 0;

    .cardContents {
      padding-top: 1rem;
    }
  }

  ${(props) => css`
    /* margin-left: ${-1 * 100 * props.currentResponseIndex}vw; */
    margin-left: ${-1 * window.innerWidth * props.currentResponseIndex}px;
    transition: margin 0.5s ease-in-out;

    .gameResponse {
      width: 100%;
      flex-shrink: 0;
      margin-right: ${calculateResponseGutter(props.contentRect)}px;
    }
  `}
`;

export const ResponseViewer = () => {
  const {
    currentAnswers: gameAnswers,
    currentPrompt,
    pusherChannel,
    pusherPin,
  } = useContext(PusherContext);
  const [responseIndex, setResponseIndex] = useState(0);
  const [wasRead, markItemRead] = useSeenTracker();

  const sendAnswerRead = useCallback(
    (responseIndex: number) => {
      console.log("Marking read:", { responseIndex });

      if (responseIndex === null || !gameAnswers[responseIndex]) {
        return null;
      }

      if (pusherChannel) {
        const { answerId } = gameAnswers[responseIndex];
        pusherChannel.trigger(TC_CONSTANTS.EVENTS.ANSWER_READ, {
          answerId,
        });
      } else {
        console.error("Failed to send ANSWER_READ without channel");
        Sentry.captureMessage("Failed to send ANSWER_READ without channel");
      }
    },
    [pusherChannel, gameAnswers],
  );

  const handleSetIndex = useCallback(
    (newIndex: number) => {
      setResponseIndex(newIndex);
    },
    [setResponseIndex],
  );

  const handleStartGuessing = useCallback(() => {
    axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/room/${pusherPin}/startGuessing`,
    );
  }, [pusherPin]);

  // send "answer read" message the first time an answer is shown
  useEffect(() => {
    if (responseIndex !== null) {
      if (!wasRead(responseIndex.toString())) {
        markItemRead(responseIndex.toString());
        sendAnswerRead(responseIndex);
      }
    }
  }, [responseIndex, sendAnswerRead, wasRead, markItemRead]);

  if (responseIndex === null) {
    return null;
  }

  return (
    <div className="responseViewer">
      <BigPrompt promptText={currentPrompt} />

      <div className="currentAnswer">
        <div className="cardContainer">
          {/* actual responses displayed here: */}
          <Measure client bounds>
            {({ contentRect, measureRef }) => (
              <div ref={measureRef}>
                <ResponseStrip
                  responseCount={gameAnswers.length}
                  currentResponseIndex={responseIndex}
                  contentRect={contentRect}
                >
                  {gameAnswers.map((answer, index) => (
                    <GameResponse
                      key={answer.answerId}
                      responseTitle={answer.answer}
                      responseIndex={index}
                      totalResponses={gameAnswers.length}
                    />
                  ))}
                </ResponseStrip>
              </div>
            )}
          </Measure>
        </div>
      </div>

      <TopToast>Read the responses aloud.</TopToast>
      <ResponsePager
        handleSetIndex={handleSetIndex}
        handleStartGuessing={handleStartGuessing}
        responseIndex={responseIndex}
        totalResponses={gameAnswers.length}
      />
    </div>
  );
};
