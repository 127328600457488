/// <reference types="node" />

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { APP_ROUTES } from "../constants";
import { trackEvent } from "../SegmentManager";
import InfoBlurb from "../design-components/InfoBlurb";
import {
  PrimaryButton,
  SecondaryButton,
} from "../design-components/BlockButton";
import { TCLogo } from "../TCLogo/TCLogo";
import getAuthenticationFailureDescription from "./helpers/authenticationFailures";

import { IS_LOGGED_IN } from "./IsLoggedIn";
import { AuthenticationFailure } from "../__generated__/globalTypes";
import { IsLoggedIn } from "./__generated__/IsLoggedIn";

const AuthEntryWrapper = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: 4rem;

  button {
    margin-bottom: 1.5rem;
  }
`;

const GoogleSigninContainer = styled.div`
  margin: 2rem 0 4rem;

  /* google renders their login button in this */
  iframe {
    margin-left: auto !important;
    margin-right: auto !important;
  }
`;

export default function AuthEntry() {
  const history = useHistory();
  const location = useLocation();

  const [error, setError] = useState("");

  // redirect to / if already logged in
  const { data: loginData } = useQuery<IsLoggedIn>(IS_LOGGED_IN, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (loginData?.me?.userId) {
      trackEvent("Already logged in redirect", {
        redirectURI: new URLSearchParams(location.search).get("to"),
      });
      history.push("/");
    }
  }, [history, loginData, location.search]);

  // render Google Login button
  useEffect(() => {
    function renderGoogleLogin() {
      const login_uri = `${
        process.env.REACT_APP_BACKEND_HOST
      }/user/google/login?to=${
        new URLSearchParams(location.search).get("to") ?? ""
      }`;

      // @ts-ignore
      google.accounts.id.initialize({
        client_id:
          "1092734997416-a4v3k60m068kktrv0dtps717r59mnts2.apps.googleusercontent.com",
        login_uri,
        ux_mode: "redirect",
        context: "auth-page",
      });

      // @ts-ignore
      google.accounts.id.renderButton(document.getElementById("google_login"), {
        theme: "outline",
        size: "medium",
        type: "standard",
        shape: "rectangular",
      });
    }

    const timeouts = [];

    // @ts-ignore
    if (typeof window.google === "undefined") {
      // things are running slow. wait 0.5sec, then try again
      timeouts.push(
        setTimeout(() => {
          // @ts-ignore
          if (typeof window.google === "undefined") {
            // ...really slow. wait 5 seconds, then try again
            timeouts.push(setTimeout(renderGoogleLogin, 5000));
            return;
          }
          renderGoogleLogin();
        }, 500),
      );
    } else {
      renderGoogleLogin();
    }

    // don't leak timeouts
    return () => {
      timeouts.map(clearTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // show google auth error if present
  useEffect(() => {
    const authError = new URLSearchParams(location.search).get("authError");

    if (authError === AuthenticationFailure.GoogleError) {
      setError(
        getAuthenticationFailureDescription(AuthenticationFailure.GoogleError),
      );
    }
  }, [location.search]);

  const handleHome = () => {
    history.push("/");
  };

  const handleSignup = () => {
    const redirectURI = encodeURIComponent(
      new URLSearchParams(location.search).get("to"),
    );
    history.push(`${APP_ROUTES.SIGNUP}?to=${redirectURI}`);
  };

  const handleLogin = () => {
    const redirectURI = encodeURIComponent(
      new URLSearchParams(location.search).get("to"),
    );
    history.push(`${APP_ROUTES.LOGIN}?to=${redirectURI}`);
  };

  return (
    <AuthEntryWrapper>
      <Helmet>
        <title>Got 'Em: Log In or Sign Up</title>
      </Helmet>
      <TCLogo onClick={handleHome} />
      <InfoBlurb hideThatFinger={true}>
        {error.length ? (
          error
        ) : (
          <>
            A free account lets you host games, set a profile picture, and
            unlock new content!
          </>
        )}
      </InfoBlurb>
      <ButtonContainer>
        <GoogleSigninContainer id="google_login" />

        <SecondaryButton onClick={handleLogin}>Log In</SecondaryButton>
        <PrimaryButton onClick={handleSignup}>Sign Up</PrimaryButton>
      </ButtonContainer>
    </AuthEntryWrapper>
  );
}
