import { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/browser";
import { EventModal } from "../design-components/EventModal/EventModal";
import { TC_CONSTANTS } from "../constants";
import { PusherContext } from "../pusherContext";
import {
  FinishRoundPayload,
  GuessResultPayload,
  PointLineItem,
} from "../types/eventPayloads";

const MODAL_TIMEOUT = 8000;

interface ModalData {
  primaryText: string;
  secondaryText: string;
  pointLineItems?: PointLineItem[];
  imageBanner?: "gotem" | "nicetry";
}

interface ModalManagerProps {
  setGuessesEvaluated: (newValue: number) => void;
  setGuesserId: (newValue: string) => void;
}

/**
 * Handle Pusher events related to anything that needs to pop up a full screen modal, such as guess results. Then,
 * render the modal for a predefined duration or until the user dismisses it.
 */
export const ModalManager = (props: ModalManagerProps) => {
  const [modalTimeout, setModalTimeout] = useState<NodeJS.Timeout>();
  const [modalPayloads, setModalPayloads] = useState<Array<ModalData>>([]);

  const modalStackRef = useRef(modalPayloads);
  modalStackRef.current = modalPayloads;

  const {
    guesserId,
    guessesEvaluated,
    humanPlayers,
    pusherChannel,
    roundsFinished,
    userId,
  } = useContext(PusherContext);

  const pushModalData = useCallback(
    (newData: ModalData) => {
      const [...draft] = modalStackRef.current;
      draft.push(newData);
      setModalPayloads(draft);
    },
    [setModalPayloads],
  );

  const handleGuessResult = useCallback(
    (data: GuessResultPayload) => {
      props.setGuesserId(data.newGuesser);
      props.setGuessesEvaluated(guessesEvaluated + 1);

      if (data.playerWhoGuessed === userId) {
        if (data.guessCorrect) {
          const guessedName = humanPlayers[data.playerGuessedId]?.name ?? `'em`;
          if (guessedName === `'em`) {
            Sentry.captureMessage("Unable to look up name on correct guess", {
              extra: {
                playerGuessedId: data.playerGuessedId,
                humanPlayers,
              },
            });
          }

          pushModalData({
            primaryText: "You Got 'Em!",
            secondaryText: `Nice work - you got ${guessedName} out!`,
            pointLineItems: data.pointLineItems,
            imageBanner: "gotem",
          });
        } else {
          if (data.playerGuessedId === data.playerWhoGuessed) {
            pushModalData({
              primaryText: "Nice Try",
              secondaryText:
                "You somehow guessed yourself... that ain't gonna fly.",
              pointLineItems: data.pointLineItems,
              imageBanner: "nicetry",
            });
          } else {
            pushModalData({
              primaryText: "Nice Try",
              secondaryText: "You guessed wrong... better luck next time!",
              pointLineItems: data.pointLineItems,
              imageBanner: "nicetry",
            });
          }
        }
      }
    },
    [guessesEvaluated, props, userId, pushModalData, humanPlayers],
  );

  const handleFinishRound = useCallback(
    (data: FinishRoundPayload) => {
      if (roundsFinished.includes(data.gameRoundId)) {
        return;
      }

      if (data.roundSwept) {
        let guesserName = "The guesser";
        if (guesserId !== null && humanPlayers[guesserId]) {
          guesserName = humanPlayers[guesserId].name;
        }

        if (guesserId === userId) {
          guesserName = "You";
        }

        pushModalData({
          primaryText: "What a Sweep!",
          secondaryText: `${guesserName} crushed it - a perfect round!`,
        });
      }
    },
    [guesserId, roundsFinished, humanPlayers, userId, pushModalData],
  );

  // handle pusher messages that trigger modals
  useEffect(() => {
    if (pusherChannel) {
      // handle guess result; guesser interface depends on context updates
      pusherChannel.bind(TC_CONSTANTS.EVENTS.GUESS_RESULT, handleGuessResult);

      // if the round was swept, display a modal
      pusherChannel.bind(TC_CONSTANTS.EVENTS.FINISH_ROUND, handleFinishRound);
    }

    return () => {
      if (pusherChannel) {
        pusherChannel.unbind(
          TC_CONSTANTS.EVENTS.GUESS_RESULT,
          handleGuessResult,
        );

        pusherChannel.unbind(
          TC_CONSTANTS.EVENTS.FINISH_ROUND,
          handleFinishRound,
        );
      }
    };
  }, [handleGuessResult, handleFinishRound, pusherChannel]);

  // auto-dismiss modals on timeout
  useEffect(() => {
    if (!!modalTimeout) {
      return;
    }

    if (modalPayloads.length > 0) {
      const newTimeout = setTimeout(() => {
        setModalPayloads(() => {
          // remove 0th element from modals array
          const [...oldPayloads] = modalStackRef.current;
          oldPayloads.shift();
          return oldPayloads;
        });
        setModalTimeout(undefined);
      }, MODAL_TIMEOUT);
      setModalTimeout(newTimeout);
    }
  }, [modalPayloads, setModalPayloads, modalTimeout, setModalTimeout]);

  // manually dismiss modal and trigger the next in the queue, if any
  const dismissModal = useCallback(() => {
    if (modalPayloads.length) {
      // remove 0th element from modals array
      const [...oldPayloads] = modalStackRef.current;
      oldPayloads.shift();
      setModalPayloads(oldPayloads);
    }

    if (modalTimeout) {
      clearTimeout(modalTimeout);
      setModalTimeout(undefined);
    }
  }, [modalTimeout, modalPayloads, setModalTimeout, setModalPayloads]);

  if (!modalPayloads.length) {
    return null;
  }

  const {
    primaryText,
    secondaryText,
    pointLineItems,
    imageBanner,
  } = modalPayloads[0];
  return (
    <EventModal
      starText={primaryText}
      bubbleText={secondaryText}
      pointLineItems={pointLineItems}
      imageBanner={imageBanner}
      onClick={dismissModal}
    />
  );
};
