/// <reference types="node" />

import React from "react";
import Pusher, { Channel } from "pusher-js";
import axios from "axios";
import axiosRetry from "axios-retry";
import * as Sentry from "@sentry/browser";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./apollo-client";

// css (IMPORT ORDER MATTERS)
import "normalize.css";
import "concrete.css";
import "./App.scss";

// helpers
import getPusherChannel from "./lib/getPusherChannel";
import SegmentManager, { loadSegment } from "./SegmentManager";

// components
import { AdminMode } from "./AdminMode";
import { AppContainer } from "./AppContainer/AppContainer";
import { ConnectionStatus } from "./ConnectionStatus/ConnectionStatus";
import { CreateRoom } from "./CreateRoom/CreateRoom";
import { GameLoadingScreen } from "./GameLoadingScreen/GameLoadingScreen";
import { GuessPhase } from "./GuessPhase/GuessPhase";
import { HomeScreen } from "./HomeScreen/HomeScreen";
import { JoinRoom } from "./JoinRoom/JoinRoom";
import { MagicLogin } from "./MagicLogin/MagicLogin";
import { ModalManager } from "./ModalManager/ModalManager";
import { PromptScreen } from "./PromptScreen/PromptScreen";
import { PusherContext } from "./pusherContext";
import { ReadPhase } from "./ReadPhase/ReadPhase";
import { RoundSummary } from "./RoundSummary/RoundSummary";
import { ServerUnreachableScreen } from "./ServerUnreachableScreen/ServerUnreachableScreen";
import { ShopHome } from "./Shop/ShopHome";
import { ThemeContext, defaultTheme } from "./themeContext";
import { WaitingRoom } from "./WaitingRoom/WaitingRoom";
import AuthEntry from "./AuthFlow";
import Lobby from "./Lobby/lobby";
import Login from "./AuthFlow/Login";
import LoginGate from "./LoginGate/LoginGate";
import OutOfPrompts from "./OutOfPrompts/OutOfPrompts";
import Signup from "./AuthFlow/Signup";
import TimedOut from "./TimedOut/TimedOut";
import UserStats from "./UserStats/UserStats";
import Whoops from "./Whoops";

// types
import {
  BroadcastAnswersPayload,
  FinishRoundPayload,
  FullUser,
  GuessResultPayload,
  KickNotificationPayload,
  NewGuesserPayload,
  NewPromptPayload,
  OutOfPromptsPayload,
  PlayerListSyncPayload,
  RoomCreateConfig,
  RoundSyncPayload,
  ShopPack,
  ThemeUpdatePayload,
  VetoCountUpdatePayload,
} from "./types/eventPayloads";
import { AppState } from "./types/AppState";
import { HostMode } from "./types/HostMode";
import {
  TC_CONSTANTS,
  GAME_PHASE,
  PublicRoomConfig,
  APP_ROUTES,
  NETWORK_STATUS,
} from "./constants";
import { PusherConnectionStatus } from "./types/ConnectionStatus";

type AppProps = {};

// configure global Axios retry logic. 10 retries should cover Heroku boot time
axiosRetry(axios, {
  retries: 10,
  retryDelay: axiosRetry.exponentialDelay,
});

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    loadSegment();

    this.clearKickOrder = this.clearKickOrder.bind(this);
    this.fetchRoomConfig = this.fetchRoomConfig.bind(this);
    this.hideOptions = this.hideOptions.bind(this);
    this.setCart = this.setCart.bind(this);
    this.setGuesserId = this.setGuesserId.bind(this);
    this.setGuessesEvaluated = this.setGuessesEvaluated.bind(this);
    this.setMobileHostMode = this.setMobileHostMode.bind(this);
    this.setShowCheckoutModal = this.setShowCheckoutModal.bind(this);
    this.setShowHowToPlayModal = this.setShowHowToPlayModal.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.updateWhoAmI = this.updateWhoAmI.bind(this);

    this.state = {
      activeKickOrder: null,
      cart: [],
      checkingLoginStatus: true,
      clearKickOrder: this.clearKickOrder,
      colorTheme: defaultTheme,
      currentAnswers: [],
      currentPrompt: null,
      currentPromptId: null,
      gamePhase: GAME_PHASE.HOME_SCREEN,
      gamePoints: null,
      gameRoomId: null,
      guesserId: null,
      guesserOrder: null,
      guessesEvaluated: 0,
      hostMode: HostMode.APPLE_TV,
      humanPlayers: {},
      isDarkMode: true,
      isGuestAccount: true,
      lastGuess: null,
      me: null,
      onCheckoutClosed: null,
      onlineUsers: {},
      optionsVisible: false,
      outOfPromptsInfo: null,
      pendingNotifications: [],
      pusher: null,
      pusherChannel: null,
      pusherChannelName: null,
      pusherConnectionStatus: PusherConnectionStatus.Initialized,
      pusherPin: null,
      remainingAnswers: [],
      remainingUsers: {},
      respondedUsers: {},
      roomConfig: null,
      roomConfigPreset: null,
      roundsFinished: [],
      serverDown: false,
      serverHealthy: false,
      setCart: this.setCart,
      setShowCheckoutModal: this.setShowCheckoutModal,
      setShowHowToPlayModal: this.setShowHowToPlayModal,
      showCheckoutModal: false,
      showHowToPlayModal: false,
      unguessedUsers: {},
      updateWhoAmI: this.updateWhoAmI,
      userId: null,
      userName: null,
      users: {},
      vetoCount: 0,
      vetosNeeded: 0,
      waitingRoom: {},
    };
  }

  async componentDidMount() {
    this.pingBackend();

    // NOTE: We're gonna try out only having the app run in dark mode
    // where we can't use CSS, it's helpful to have this dynamic value available
    // this.setState({
    //   isDarkMode: window.matchMedia("(prefers-color-scheme: dark)").matches,
    // });

    // try {
    //   window
    //     .matchMedia("(prefers-color-scheme: dark)")
    //     .addEventListener("change", (event) => {
    //       if (event.matches) {
    //         this.setState({ isDarkMode: true });
    //       } else {
    //         this.setState({ isDarkMode: false });
    //       }
    //     });
    // } catch (err) {
    //   console.warn(err);
    // }
  }

  componentWillUnmount() {
    if (this.state.pusherChannel) {
      this.state?.pusher?.disconnect();
    }
  }

  componentDidUpdate(_prevProps: AppProps, prevState: AppState) {
    const prevConfig = prevState && prevState.pusherPin;
    const currentConfig = this.state && this.state.pusherPin;

    if (prevConfig !== currentConfig || (!prevConfig && !!currentConfig)) {
      if (this.state.serverHealthy) {
        this.fetchRoomConfig();
      }
    }

    if (
      prevState.serverHealthy === false &&
      this.state.serverHealthy === true
    ) {
      this.initChannel();
      this.updateWhoAmI().then(() => this.autoJoin());
    }

    // download config preset if present
    if (
      prevState.roomConfig?.configPresetName !==
      this.state.roomConfig?.configPresetName
    ) {
      const newPresetName = this.state.roomConfig?.configPresetName;

      if (newPresetName === null) {
        this.setState({
          roomConfigPreset: null,
        });
      } else if (!!newPresetName) {
        this.fetchConfigPreset(newPresetName);
      }
    }
  }

  clearKickOrder() {
    this.setState({
      activeKickOrder: null,
    });
  }

  setCart(newCart: ShopPack[], onCheckoutClosed?: () => void) {
    this.setState({
      cart: newCart,
      showCheckoutModal: newCart.length > 0,
    });

    if (onCheckoutClosed) {
      this.setState({
        onCheckoutClosed,
      });
    }
  }

  setShowCheckoutModal(newValue: boolean) {
    this.setState({
      showCheckoutModal: newValue,
    });
  }

  setShowHowToPlayModal(newValue: boolean) {
    this.setState({
      showHowToPlayModal: newValue,
    });
  }

  async fetchConfigPreset(presetName: string) {
    try {
      const configPreset = await axios.get<RoomCreateConfig>(
        `${process.env.REACT_APP_BACKEND_HOST}/featuredConfigs/${presetName}`,
      );

      this.setState({
        roomConfigPreset: configPreset.data,
      });
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  }

  async fetchRoomConfig() {
    if (!this.state.pusherPin) {
      console.warn("can't fetch room config without PIN");
      return;
    }

    try {
      const newConfig = await axios.get<PublicRoomConfig>(
        `${process.env.REACT_APP_BACKEND_HOST}/room/${this.state.pusherPin}/config`,
      );

      console.log({ newConfig });

      this.setState({
        roomConfig: newConfig.data,
      });
    } catch (err) {
      if (err?.response?.status === 404 || err?.response?.status === 410) {
        console.info(`Failed to fetch config for PIN ${this.state.pusherPin}`);
      } else {
        if (Sentry) {
          Sentry.captureException(err);
        }
      }
    }
  }

  // health-check the backend to make sure it's up and running (Heroku free tier kills the server after inactivity)
  pingBackend() {
    // use .then instead of await so retry works
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/healthz`)
      .then(() => {
        this.setState({
          serverHealthy: true,
        });
      })
      .catch(() => {
        this.setState({
          serverDown: true,
        });
      });
  }

  // check if user is logged in, and if so, populate with their data
  async updateWhoAmI() {
    if (!this.state.serverHealthy) {
      console.debug(
        "Skipping updateWhoAmI because we haven't heard from the server",
      );
      return;
    }

    try {
      const whoami = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/user/whoami`,
      );
      const data = whoami.data as FullUser;

      if (data) {
        console.debug("Received profile for logged in user");
        await this.setState({
          checkingLoginStatus: false,
          me: data,
          isGuestAccount: data.isGuest,
          userId: data.userId,
          userName: data.name,
          pusherPin: data.gameRoomPin,
        });

        if (data.networkStatus === NETWORK_STATUS.TIMED_OUT) {
          this.clearKickOrder();
          this.autoJoin();
        }
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        console.debug("Looks like we're a guest");
        this.setState({
          checkingLoginStatus: false,
          me: null,
          isGuestAccount: true,
          userId: null,
          userName: null,
        });
      } else {
        console.error(err);
        Sentry.captureException(err);
      }
    }
  }

  /**
   * instantiate connection to Pusher
   * NOTE: this requires a response from the backend that looks like
   * {
   *   "auth":"pusher_key:hmac_digest",
   *   "channel_data":"{\"user_id\":\"asdf\"}
   * "}
   */
  getPusher() {
    if (!this.state.pusher) {
      const {
        REACT_APP_PUSHER_KEY,
        REACT_APP_PUSHER_CLUSTER,
        REACT_APP_BACKEND_HOST,
      } = process.env;

      const pusher = new Pusher(REACT_APP_PUSHER_KEY!, {
        cluster: REACT_APP_PUSHER_CLUSTER,
        authEndpoint: `${REACT_APP_BACKEND_HOST}/pusher/auth/web`,
        auth: {
          headers: {},
          params: {
            pin: this.state.pusherPin,
            name: this.state.userName,
            user_id: this.state.userId,
          },
        },
      });
      this.setState({ pusher });
      return pusher;
    }
    return this.state.pusher;
  }

  initChannel() {
    if (
      this.state.serverHealthy &&
      this.state.pusherChannelName &&
      this.state.pusherPin &&
      this.state.userName
    ) {
      this.getPusher().bind_global((name: string, data: any) =>
        console.log("[Global Event] received", name, data),
      );

      this.getPusher().connection.bind(
        "state_change",
        ({
          previous,
          current,
        }: {
          previous: PusherConnectionStatus;
          current: PusherConnectionStatus;
        }) => {
          console.log(`[Pusher] state update from ${previous} to ${current}`);

          if (previous === current) {
            return;
          }

          this.setState({
            pusherConnectionStatus: current,
          });

          if (current === "connected" && this.state?.pusherChannelName) {
            const gameChannel = this.getPusher().subscribe(
              this.state.pusherChannelName,
            );
            gameChannel.bind("pusher:subscription_succeeded", (data: any) => {
              console.log("[Pusher] subscription succeeded; binding to events");

              this.updateWhoAmI();
              this.onSubscriptionSucceeded(data);
              this.subscribeToGame(gameChannel);
              this.setState({
                pusherChannel: gameChannel,
              });

              // request resync; Pusher doesn't notify the server if we're gone for <3sec, like if we reload the page
              axios
                .post(
                  `${process.env.REACT_APP_BACKEND_HOST}/room/${this.state.pusherPin}/requestSync`,
                )
                .catch(() => {});
            });

            // TODO: bind disconnect and attempt to reconnect?
          }
        },
      );

      this.getPusher().connect();
    }
  }

  subscribeToGame = (gameChannel: Channel) => {
    // events that are only listened to in this component (<App />) will be unbound and rebound on some rerenders
    const appEvents = [
      TC_CONSTANTS.EVENTS.BROADCAST_ANSWERS,
      TC_CONSTANTS.EVENTS.FINISH_ROUND,
      TC_CONSTANTS.EVENTS.GUESS_RESULT,
      TC_CONSTANTS.EVENTS.KICK_NOTIFICATION,
      TC_CONSTANTS.EVENTS.MEMBER_ADDED,
      TC_CONSTANTS.EVENTS.MEMBER_REMOVED,
      TC_CONSTANTS.EVENTS.NEW_GUESSER,
      TC_CONSTANTS.EVENTS.NEW_PROMPT,
      TC_CONSTANTS.EVENTS.PLAYERLIST_SYNC,
      TC_CONSTANTS.EVENTS.ROUND_SYNC,
      TC_CONSTANTS.EVENTS.START_GUESSING,
      TC_CONSTANTS.EVENTS.START_READING,
      TC_CONSTANTS.EVENTS.VETO_COUNT_UPDATE,
      TC_CONSTANTS.EVENTS.OUT_OF_PROMPTS,
    ];
    appEvents.forEach((eventName) => {
      gameChannel.unbind(eventName);
    });

    // handle users joining
    gameChannel.bind(
      "pusher:member_added",
      (member: { id: string; info: any }) => {
        const newUsers = {
          ...this.state.users,
          [member.id]: member.info,
        };
        this.setState({
          users: newUsers,
        });
      },
    );

    // handle users leaving
    gameChannel.bind(
      "pusher:member_removed",
      (member: { id: string; info: any }) => {
        const memberRemovedReducer = (
          acc: { [id: string]: any },
          currentValue: [string, any],
        ) => {
          const [id, user] = currentValue;
          if (id === member.id) {
            return acc;
          }
          return {
            ...acc,
            [id]: user,
          };
        };

        const newUsers = Object.entries(this.state.users).reduce(
          memberRemovedReducer,
          {},
        );
        this.setState({ users: newUsers });
      },
    );

    // handle new prompt
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.NEW_PROMPT,
      (data: NewPromptPayload) => {
        const { prompt, promptId } = data;

        if (promptId !== this.state.currentPromptId) {
          this.setState({
            currentPrompt: prompt,
            currentPromptId: promptId,
            gamePhase: GAME_PHASE.ANSWERING,
            respondedUsers: {},
            lastGuess: null,
          });
        }
      },
    );

    // handle start of "reader mode"
    gameChannel.bind(TC_CONSTANTS.EVENTS.START_READING, () => {
      this.setState({
        gamePhase: GAME_PHASE.READING,
      });
    });

    // handle new guesser selection
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.NEW_GUESSER,
      (data: NewGuesserPayload) => {
        const { guesserId } = data;
        this.setState({
          guesserId,
        });
      },
    );

    // handle guess result and save to state so it can be displayed downstream
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.GUESS_RESULT,
      (data: GuessResultPayload) => {
        this.setState({
          lastGuess: data,
        });
      },
    );

    // handle answer broadcast and save to state
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.BROADCAST_ANSWERS,
      (data: BroadcastAnswersPayload) => {
        this.setState({
          currentAnswers: data.answers,
          remainingAnswers: data.remainingAnswers,
        });
      },
    );

    // handle updated player info
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.PLAYERLIST_SYNC,
      (data: PlayerListSyncPayload) => {
        this.setState({
          onlineUsers: data.onlineUsers,
          respondedUsers: data.respondedUsers,
          remainingUsers: data.remainingUsers,
          unguessedUsers: data.unguessedUsers,
          waitingRoom: data.waitingRoom,
          humanPlayers: data.humanPlayers,
          guesserOrder: data.guesserOrder,
        });

        this.updateWhoAmI();
      },
    );

    // handle end of round summary and update point totals
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.FINISH_ROUND,
      (data: FinishRoundPayload) => {
        const draft = [...this.state.roundsFinished];
        if (!draft.includes(data.gameRoundId)) {
          draft.push(data.gameRoundId);
          this.setState({
            roundsFinished: draft,
          });
        }

        this.setState({
          gamePoints: data,
        });
      },
    );

    // handle state sync
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.ROUND_SYNC,
      (data: RoundSyncPayload) => {
        if (!this.state.gamePoints) {
          this.setState({
            // @ts-ignore
            gamePoints: {
              roundPoints: undefined,
              totalPoints: data.totalPoints,
            },
          });
        }

        this.setState({
          currentPrompt: data.currentPrompt,
          currentPromptId: data.currentPromptId,
          gameRoomId: data.gameRoomId,
          guesserId: data.guesserId,
        });

        // translate server phase to client phase
        const phaseMap = new Map<string, GAME_PHASE>();
        phaseMap.set(TC_CONSTANTS.EVENTS.NEW_PROMPT, GAME_PHASE.ANSWERING);
        phaseMap.set(TC_CONSTANTS.EVENTS.START_GUESSING, GAME_PHASE.GUESSING);
        phaseMap.set(
          TC_CONSTANTS.EVENTS.FINISH_ROUND,
          GAME_PHASE.ROUND_SUMMARY,
        );
        phaseMap.set(
          TC_CONSTANTS.EVENTS.OUT_OF_PROMPTS,
          GAME_PHASE.OUT_OF_PROMPTS,
        );

        if (data.gamePhase && phaseMap.has(data.gamePhase)) {
          this.setState({
            gamePhase: phaseMap.get(data.gamePhase)!,
          });
        }
      },
    );

    // handle start of "guessing mode"
    gameChannel.bind(TC_CONSTANTS.EVENTS.START_GUESSING, () => {
      this.setState({
        gamePhase: GAME_PHASE.GUESSING,
      });
    });

    // handle theme update
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.THEME_UPDATE,
      (data: ThemeUpdatePayload) => {
        this.setState({
          colorTheme: data,
        });
      },
    );

    // update how many people have voted to skip the current prompt
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.VETO_COUNT_UPDATE,
      (data: VetoCountUpdatePayload) => {
        if (data.promptId === this.state.currentPromptId) {
          this.setState({
            vetoCount: data.vetoCount,
            vetosNeeded: data.vetosNeeded,
          });
        }
      },
    );

    // handle a user being kicked by another user - and if we're the one being kicked, show the veto button
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.KICK_NOTIFICATION,
      (data: KickNotificationPayload) => {
        // if we are actively being kicked, we don't care about someone else's kick order
        if (
          this.state.activeKickOrder !== null &&
          this.state.activeKickOrder.playerBeingKickedId ===
            this.state.me?.userId
        ) {
          // ...unless a more up-to-date kick order is coming through for us
          if (data.playerBeingKickedId !== this.state.me?.userId) {
            return;
          }
        }
        this.setState({
          activeKickOrder: data,
        });
      },
    );

    // handle the case where all available prompts have been used
    gameChannel.bind(
      TC_CONSTANTS.EVENTS.OUT_OF_PROMPTS,
      (data: OutOfPromptsPayload) => {
        this.setState({
          gamePhase: GAME_PHASE.OUT_OF_PROMPTS,
          outOfPromptsInfo: data,
        });
      },
    );
  };

  onSubscriptionSucceeded = (subscriptionData: any) => {
    console.log("subscription succeeded", subscriptionData);
    this.setState({
      gamePhase: GAME_PHASE.LOBBY,
      users: subscriptionData.members,
    });
  };

  handleJoin = (pusherChannelName: string) => {
    console.debug("handleJoin", { pusherChannelName });
    this.setState({
      pusherChannelName,
    });
    this.initChannel();
  };

  autoJoin = async () => {
    // then, rejoin room from local state if present
    if (this.state.pusherPin) {
      try {
        console.log("autojoining", this.state.pusherPin);
        const pusherChannel = await getPusherChannel(this.state.pusherPin);
        this.handleJoin(pusherChannel);
      } catch (e) {
        console.warn("Failed to autojoin", e);
      }
    }
  };

  setPusherChannelName = (pusherChannelName: string) => {
    this.setState({ pusherChannelName });
  };

  setPusherPin = (pusherPin: string) => {
    this.setState({ pusherPin });
  };

  setUserName = (userName: string) => {
    this.setState({
      userName,
    });
  };

  setMobileHostMode = () => {
    this.setState({
      hostMode: HostMode.MOBILE,
    });
  };

  setGamePhase = (newPhase: GAME_PHASE) => {
    this.setState({
      gamePhase: newPhase,
    });
  };

  setColorTheme = (colorTheme: ThemeUpdatePayload) => {
    if (this.state.colorTheme.themeName !== colorTheme.themeName) {
      this.setState({
        colorTheme,
      });
    }
  };

  setGuessesEvaluated = (guessesEvaluated: number) => {
    this.setState({
      guessesEvaluated,
    });
  };

  setGuesserId = (guesserId: string) => {
    this.setState({
      guesserId,
    });
  };

  hideOptions() {
    this.setState({
      optionsVisible: false,
    });
  }

  toggleOptions() {
    this.setState({
      optionsVisible: !this.state.optionsVisible,
    });
  }

  leaveRoom = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/room/leave`);

      await this.state?.pusherChannel?.disconnect();
      await this.state?.pusher?.disconnect();

      this.setState({
        gamePhase: GAME_PHASE.HOME_SCREEN,
        pusherPin: null,
        optionsVisible: false,
        pusherChannelName: null,
        pusherChannel: null,
      });

      // await this.updateWhoAmI();
      window.location.reload();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      window.location.reload();
    }
  };

  getGameStateComponent() {
    // communicate to user if we've given up on reaching the server
    if (this.state.serverDown) {
      return <ServerUnreachableScreen />;
    }

    // show loading screen if we're checking whether we're logged in
    if (this.state.checkingLoginStatus || !this.state.serverHealthy) {
      return <GameLoadingScreen />;
    }

    if (
      this.state.waitingRoom &&
      this.state.userId &&
      Object.keys(this.state.waitingRoom).includes(this.state.userId)
    ) {
      return <WaitingRoom />;
    }

    if (
      this.state.pusherPin &&
      this.state.me?.networkStatus === NETWORK_STATUS.TIMED_OUT
    ) {
      return <TimedOut handleLeave={this.leaveRoom} />;
    }

    switch (this.state.gamePhase) {
      case GAME_PHASE.HOME_SCREEN:
        return <HomeScreen />;

      case GAME_PHASE.LOBBY:
        return <Lobby />;

      case GAME_PHASE.ANSWERING:
        return <PromptScreen />;

      case GAME_PHASE.READING:
        return <ReadPhase />;

      case GAME_PHASE.GUESSING:
        return <GuessPhase />;

      case GAME_PHASE.ROUND_SUMMARY:
        return <RoundSummary />;

      case GAME_PHASE.OUT_OF_PROMPTS:
        return <OutOfPrompts />;

      default:
        console.error(`Unknown game phase: ${this.state.gamePhase}`);
        return <Whoops />;
    }
  }

  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <ThemeContext.Provider value={this.state.colorTheme}>
          <PusherContext.Provider value={this.state}>
            <SegmentManager />
            <ModalManager
              setGuessesEvaluated={this.setGuessesEvaluated}
              setGuesserId={this.setGuesserId}
            />
            <ConnectionStatus
              pusherConnectionStatus={this.state.pusherConnectionStatus}
            />
            <BrowserRouter>
              <AppContainer
                handleLeave={this.leaveRoom}
                toggleOptions={this.toggleOptions}
                hideOptions={this.hideOptions}
              >
                <Switch>
                  <Route path={APP_ROUTES.ADMIN_MODE}>
                    <AdminMode />
                  </Route>
                  <Route path={APP_ROUTES.MAGIC_LOGIN}>
                    <MagicLogin />
                  </Route>
                  <Route path={`${APP_ROUTES.CREATE_ROOM}/:configPreset?`}>
                    <LoginGate>
                      <CreateRoom
                        setGamePhase={this.setGamePhase}
                        handleJoin={this.handleJoin}
                        setPin={this.setPusherPin}
                        setColorTheme={this.setColorTheme}
                        updateWhoAmI={this.updateWhoAmI}
                      />
                    </LoginGate>
                  </Route>
                  <Route path={`${APP_ROUTES.JOIN_ROOM}/:roomPin?`}>
                    <LoginGate>
                      <JoinRoom
                        setGamePhase={this.setGamePhase}
                        handleJoin={this.handleJoin}
                        userName={this.state.userName}
                        setUserName={this.setUserName}
                        setPin={this.setPusherPin}
                      />
                    </LoginGate>
                  </Route>
                  <Route path={`${APP_ROUTES.SHOP}/:sku?`}>
                    <ShopHome />
                  </Route>

                  <Route path={APP_ROUTES.LOGIN}>
                    <Login />
                  </Route>
                  <Route path={APP_ROUTES.SIGNUP}>
                    <Signup />
                  </Route>
                  <Route path={APP_ROUTES.AUTH}>
                    <AuthEntry />
                  </Route>

                  <Route path={APP_ROUTES.USER_STATS}>
                    <UserStats />
                  </Route>
                  <Route path="/">{this.getGameStateComponent()}</Route>
                </Switch>
              </AppContainer>
            </BrowserRouter>
          </PusherContext.Provider>
        </ThemeContext.Provider>
      </ApolloProvider>
    );
  }
}

export default App;
