import styled from "styled-components/macro";
import { lightDarkVariant } from "../../lib/lightDarkVariant";
import { ThemedComponentProps } from "../../types/ThemedComponentProps";

export const AuxButton = styled.button.attrs(() => ({
  className: "auxButton",
}))<ThemedComponentProps>`
  && {
    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.detail,
        dark: props.tcTheme.dark.detail,
      })}

    display: inline-block;
    margin: 0 auto;
    border: none;
    border-radius: 3px;
    padding: 0.2em 0.8em;
    font-size: 0.6em;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
    text-transform: uppercase;
    font-weight: bold;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
`;
