import { useContext, useCallback, useState } from "react";
import styled from "styled-components/macro";
import axios from "axios";
import { useHistory } from "react-router";
import { PusherContext } from "../../pusherContext";
import { PlayerBanner } from "../../design-components/PlayerBanner/PlayerBanner";
import { LoginScreen } from "../../LoginScreen/LoginScreen";
import {
  MenuContainer,
  MenuRow,
} from "../../design-components/MenuLayout/MenuLayout";
import { SubmitButton } from "../../design-components/SubmitButton/SubmitButton";
import { CardProps } from "../../design-components/Card/Card";
import ManagePlayersModal from "../../ManagePlayersModal/ManagePlayersModal";
import { APP_ROUTES } from "../../constants";
import { teardownSegment, trackEvent } from "../../SegmentManager";

const PlayerPreviewContainer = styled.div`
  && {
    .menuContainer {
      margin-top: 0;
    }

    .cardContainer {
      h2 {
        margin-bottom: 0.5em;
      }
    }

    .fullRow {
      display: flex;
      justify-content: center;

      button {
        width: 100%;
      }
    }

    .splitRow {
      display: flex;
      justify-content: space-between;

      button {
        width: 48%;
      }
    }
  }
`;

type PlayerPreviewProps = {
  setEditProfileMode: CallableFunction;
  submitButtonProps?: CardProps["submitButtonProps"];
};

export const PlayerPreview = ({
  setEditProfileMode,
  submitButtonProps,
}: PlayerPreviewProps) => {
  const history = useHistory();
  const { me, pusherPin } = useContext(PusherContext);
  const [managingPlayers, setManagingPlayers] = useState(false);
  const [logoutCount, setLogoutCount] = useState(0);

  const enableEditProfile = useCallback(() => {
    setEditProfileMode(true);
  }, [setEditProfileMode]);

  const openManagePlayers = useCallback(() => {
    setManagingPlayers(true);
  }, [setManagingPlayers]);

  const closeManagePlayers = useCallback(() => {
    setManagingPlayers(false);
  }, [setManagingPlayers]);

  const reloadPage = useCallback(() => {
    window?.location.reload();
  }, []);

  const viewStats = useCallback(() => {
    history.push(APP_ROUTES.USER_STATS);
  }, [history]);

  const handleLogout = useCallback(async () => {
    if (window?.confirm("Do you really want to logout?") === true) {
      trackEvent("Logged Out");
      await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/user/logout`);
      teardownSegment();
      window.location.href = "/";
    } else {
      trackEvent("Logout Aborted");
    }

    setLogoutCount(logoutCount + 1);
  }, [logoutCount, setLogoutCount]);

  if (!me || me.isGuest) {
    return (
      <LoginScreen
        cardProps={{
          title:
            "With a free account, you can host games, upload a profile picture, and unlock more prompts.",
        }}
        onSuccess={reloadPage}
      />
    );
  }

  return (
    <PlayerPreviewContainer>
      <MenuContainer>
        <MenuRow>
          <PlayerBanner title={me.name} submitButtonProps={submitButtonProps} />
        </MenuRow>
      </MenuContainer>
      <MenuContainer>
        {pusherPin && (
          <MenuRow className="fullRow">
            <SubmitButton
              textLabels={{
                disabled: "Manage Players",
                enabled: "Manage Players",
                submitting: "Manage Players",
              }}
              disabled={false}
              onClick={openManagePlayers}
              resetOnChange={managingPlayers}
            >
              <span role="img" aria-label="Imp">
                😈{" "}
              </span>
            </SubmitButton>
          </MenuRow>
        )}
        {!pusherPin && (
          <MenuRow className="fullRow">
            <SubmitButton
              textLabels={{
                disabled: "View Stats",
                enabled: "View Stats",
                submitting: "View Stats",
              }}
              disabled={false}
              onClick={viewStats}
            >
              <span role="img" aria-label="Chart trending upwards">
                📈{" "}
              </span>
            </SubmitButton>
          </MenuRow>
        )}
        <MenuRow className={!!pusherPin ? "fullRow" : "splitRow"}>
          <SubmitButton
            textLabels={{
              disabled: "Edit Profile",
              enabled: "Edit Profile",
              submitting: "One sec...",
            }}
            disabled={false}
            onClick={enableEditProfile}
          >
            <span role="img" aria-label="Protractor">
              📐{" "}
            </span>
          </SubmitButton>

          {/* only show logout button if not in a round because it's super frustrating to logout mid-round */}
          {!pusherPin && (
            <SubmitButton
              textLabels={{
                disabled: "Logout",
                enabled: "Logout",
                submitting: "See ya!",
              }}
              resetOnChange={logoutCount}
              disabled={false}
              onClick={handleLogout}
            >
              <span role="img" aria-label="Hand making peace sign">
                ✌️{" "}
              </span>
            </SubmitButton>
          )}
        </MenuRow>
      </MenuContainer>
      {managingPlayers && (
        <ManagePlayersModal handleClose={closeManagePlayers} />
      )}
    </PlayerPreviewContainer>
  );
};
