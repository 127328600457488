import { GetUserPacks } from "../__generated__/GetUserPacks";

/**
 * Default selection: All packs enabled except seasonal ones
 */
function selectDefault(
  ownedPacks: GetUserPacks["packs"],
): Map<string, boolean> {
  const defaultSelection = new Map<string, boolean>();
  for (const pack of ownedPacks) {
    if (!pack.isSeasonal) {
      defaultSelection.set(pack.packId, true);
    }
  }
  return defaultSelection;
}

/**
 * Select all packs
 */
function selectAll(ownedPacks: GetUserPacks["packs"]): Map<string, boolean> {
  const allSelection = new Map<string, boolean>();
  for (const pack of ownedPacks) {
    allSelection.set(pack.packId, true);
  }
  return allSelection;
}

/**
 * Select no packs
 */
function selectNone(ownedPacks: GetUserPacks["packs"]): Map<string, boolean> {
  const noneSelection = new Map<string, boolean>();
  for (const pack of ownedPacks) {
    noneSelection.set(pack.packId, false);
  }
  return noneSelection;
}

export { selectDefault, selectAll, selectNone };
