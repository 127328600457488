import { useContext } from "react";
import styled from "styled-components/macro";
import { PusherContext } from "../pusherContext";
import { LastGuess } from "./LastGuess";
import "./SelectionModal.scss";

const Modal = styled.div`
  && {
    h1 {
      font-family: "Righteous", sans-serif;
      font-size: 2.2rem;
      font-weight: normal;
      text-align: center;
    }

    .last-guess {
      margin: 10rem auto 0;
      max-width: 550px;
    }
  }
`;

interface Props {
  handleClose: (e: any) => void;
}

export default function LastGuessModal({ handleClose }: Props) {
  const { lastGuess } = useContext(PusherContext);

  return (
    <Modal>
      <div className="modalOverlay" onClick={handleClose} />
      <div className="selectionModal">
        <div className="modalSpacer" />
        <div className="modalContent">
          <button className="closeModal" onClick={handleClose}>
            &times;
          </button>
          <h1>Last Guess</h1>
          {lastGuess && (
            <LastGuess {...lastGuess} parentBackgroundIsBody={true} />
          )}
        </div>
      </div>
    </Modal>
  );
}
