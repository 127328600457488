import React, { useContext } from "react";
import styled from "styled-components/macro";
import "./TopToast.scss";
import { ThemedComponentProps } from "../types/ThemedComponentProps";
import { lightDarkVariant } from "../lib/lightDarkVariant";
import { ThemeContext } from "../themeContext";

type TopToastProps = {
  children: React.ReactNode;
};

const TopToastWrapper = styled.p.attrs(() => ({
  className: "topToast",
}))<ThemedComponentProps>`
  ${(props) =>
    lightDarkVariant("background-color", {
      light: props.tcTheme.light.detail,
      dark: props.tcTheme.dark.detail,
    })}
`;

export const TopToast = ({ children }: TopToastProps) => {
  const tcTheme = useContext(ThemeContext);
  return <TopToastWrapper tcTheme={tcTheme}>{children}</TopToastWrapper>;
};
