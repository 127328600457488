import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { DateTime } from "luxon";
import { useQuery, gql } from "@apollo/client";

import { TCLogo } from "../TCLogo/TCLogo";
import { Card } from "../design-components/Card/Card";
import {
  MenuContainer,
  MenuRow,
} from "../design-components/MenuLayout/MenuLayout";
import { APP_ROUTES } from "../constants";
import { RoomCreateConfig } from "../types/eventPayloads";
import { PusherContext } from "../pusherContext";
import { ThemeContext } from "../themeContext";
import { CardBanner } from "../design-components/CardBanner/CardBanner";
import TickingCountdown from "../design-components/TickingCountdown/TickingCountdown";
import OxfordString from "../design-components/OxfordString/OxfordString";
import { trackEvent, trackPage } from "../SegmentManager";
import type { AnnouncementQuery } from "./__generated__/AnnouncementQuery";
import AnnouncementCard from "../AnnouncementCard";

const ANNOUNCEMENTS_QUERY = gql`
  query AnnouncementQuery {
    activeAnnouncements(take: 1) {
      title
      body
    }
  }
`;

export const HomeScreen = () => {
  const history = useHistory();
  const [featuredConfigs, setFeaturedConfigs] = useState<
    Array<RoomCreateConfig>
  >([]);

  const { isGuestAccount } = useContext(PusherContext);

  const { data: announcementData } = useQuery<AnnouncementQuery>(
    ANNOUNCEMENTS_QUERY,
    {
      pollInterval: 60000,
    },
  );

  useEffect(() => {
    trackPage("Game", "Title Screen");
  }, []);

  const selectCreate = useCallback(() => {
    history.push(APP_ROUTES.CREATE_ROOM);
  }, [history]);

  const createFeatured = useCallback(
    (configSlug: string) => {
      trackEvent("Create Featured Room", {
        slug: configSlug,
      });
      history.push(`${APP_ROUTES.CREATE_ROOM}/${configSlug}`);
    },
    [history],
  );

  const selectJoin = useCallback(() => {
    history.push(APP_ROUTES.JOIN_ROOM);
  }, [history]);

  const goShopping = useCallback(() => {
    history.push(APP_ROUTES.SHOP);
  }, [history]);

  const goLogin = useCallback(() => {
    history.push(APP_ROUTES.AUTH);
  }, [history]);

  useEffect(() => {
    const getFeaturedConfigs = async () => {
      const featuredConfigs = await axios.get<Array<RoomCreateConfig>>(
        `${process.env.REACT_APP_BACKEND_HOST}/featuredConfigs`,
      );
      setFeaturedConfigs(featuredConfigs.data);
    };
    getFeaturedConfigs();
  }, []);

  return (
    <>
      <TCLogo subtitle="Hate Your Friends™️" splashScreen={true} />
      <MenuContainer>
        {announcementData?.activeAnnouncements &&
          announcementData.activeAnnouncements.length > 0 && (
            <MenuRow className="above-main">
              {announcementData.activeAnnouncements.map((announcement) => (
                <AnnouncementCard announcement={announcement} />
              ))}
            </MenuRow>
          )}

        {featuredConfigs?.map((feature) => {
          if (DateTime.fromISO(feature.endDate) < DateTime.now()) {
            return null;
          }

          const featureBanner = (
            <CardBanner emoji={feature.secondaryEmoji}>
              <p>{feature.description}</p>
              <span className="small-caps">
                Available for{" "}
                <TickingCountdown
                  until={DateTime.fromISO(feature.endDate).toJSDate()}
                />
              </span>
            </CardBanner>
          );

          return (
            <ThemeContext.Provider
              value={feature.colorTheme}
              key={feature.slug}
            >
              <MenuRow className="above-main">
                <Card
                  topContent={featureBanner}
                  submitButtonProps={{
                    disabled: false,
                    onClick: isGuestAccount
                      ? goLogin
                      : () => createFeatured(feature.slug),
                    textLabels: {
                      disabled: "Create Featured Room",
                      enabled: "Create Featured Room",
                      submitting: "Create Featured Room",
                    },
                    useThemedButton: true,
                  }}
                >
                  {feature.primaryEmoji}
                  &nbsp; Play a special game mode featuring prompts from the{" "}
                  <OxfordString elements={feature.packNames} />.
                </Card>
              </MenuRow>
            </ThemeContext.Provider>
          );
        })}

        <MenuRow>
          <Card
            fullCardButton={true}
            submitButtonProps={{
              disabled: false,
              onClick: selectCreate,
              textLabels: {
                disabled: "Create Room",
                enabled: "Create Room",
                submitting: "Create Room",
              },
            }}
          >
            <span role="img" aria-label="Sparkles">
              ✨
            </span>
          </Card>
          <Card
            fullCardButton={true}
            submitButtonProps={{
              disabled: false,
              onClick: selectJoin,
              textLabels: {
                disabled: "Join Game",
                enabled: "Join Game",
                submitting: "Join Game",
              },
            }}
          >
            <span role="img" aria-label="Group of people">
              👥
            </span>
          </Card>
        </MenuRow>

        {isGuestAccount && (
          <MenuRow className="below-main">
            <Card
              submitButtonProps={{
                disabled: false,
                onClick: goLogin,
                textLabels: {
                  disabled: "Log In or Sign Up",
                  enabled: "Log In or Sign Up",
                  submitting: "Log In or Sign Up",
                },
              }}
            >
              With a free account, you can host games, upload a profile picture,
              and unlock more prompts.
            </Card>
          </MenuRow>
        )}

        {!isGuestAccount && (
          <MenuRow className="below-main">
            <Card
              submitButtonProps={{
                disabled: false,
                onClick: goShopping,
                textLabels: {
                  disabled: "Browse Prompt Packs",
                  enabled: "Browse Prompt Packs",
                  submitting: "Browse Prompt Packs",
                },
              }}
            >
              Add some variety with new prompts!
            </Card>
          </MenuRow>
        )}
      </MenuContainer>
    </>
  );
};
