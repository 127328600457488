import axios from "axios";
import {
  GrantPackResult,
  MissingPackResult,
  UserSearchResult,
} from "../types/eventPayloads";

const baseApiUri = `${process.env.REACT_APP_BACKEND_HOST}/admin`;

/**
 * Search for users
 */
export const searchUsers = async (
  keyword: string,
): Promise<UserSearchResult[]> => {
  const response = await axios.get(`${baseApiUri}/searchUsers`, {
    params: {
      keyword,
    },
  });
  return response.data;
};

/**
 * Get packs a user doesn't own
 */
export const getMissingPacks = async (
  userId: string,
): Promise<MissingPackResult[]> => {
  const response = await axios.get(`${baseApiUri}/missingPacks`, {
    params: {
      userId,
    },
  });
  return response.data;
};

/**
 * Grant a pack to a user
 */
export const grantPack = async (
  packId: string,
  userId: string,
): Promise<GrantPackResult> => {
  const response = await axios.post(`${baseApiUri}/grantPack`, {
    data: {
      packId,
      userId,
    },
  });
  return response.data;
};
