import { useContext } from "react";
import { PusherContext } from "../pusherContext";
import { CurrentPin } from "../CurrentPin/CurrentPin";
import { TCLogo } from "../TCLogo/TCLogo";
import { Card } from "../design-components/Card/Card";

export const WaitingRoom = () => {
  const pusherContext = useContext(PusherContext);
  const { onlineUsers, pusherPin } = pusherContext;

  const userList = Object.entries(onlineUsers).map(([id, user]) => (
    <li key={id}>{user.name}</li>
  ));

  return (
    <div className="lobby">
      <TCLogo subtitle="Waiting Room" />

      <Card>{pusherPin && <CurrentPin pin={pusherPin} />}</Card>

      <Card title="Waiting Room" subtitle="You'll join the next round!" />

      <Card title="Who's Here">
        <ul>{userList}</ul>
      </Card>
    </div>
  );
};
