import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { DateTime } from "luxon";
import { Card } from "../design-components/Card/Card";
import RadialProgress from "../design-components/RadialProgress/RadialProgress";
import { lightDarkVariant } from "../lib/lightDarkVariant";
import { smallPhone } from "../lib/smallPhone";
import { PusherContext } from "../pusherContext";
import { ThemeContext } from "../themeContext";
import { ThemedComponentProps } from "../types/ThemedComponentProps";
import { vetoKick } from "../api/room";

const ModalBackdrop = styled.div`
  && {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.75;
    background-color: #000;
    z-index: 5;
  }
`;

const ModalContent = styled.div<ThemedComponentProps>`
  && {
    position: relative;
    top: 20vh;
    z-index: 10;

    width: 100%;
    border-radius: 5px;
    text-align: center;

    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.detail,
        dark: props.tcTheme.dark.detail,
      })}

    h1 {
      font-family: "Righteous", sans-serif;
      font-size: 3rem;
      font-weight: normal;
      margin: 0;

      ${smallPhone(css`
        font-size: 2.5rem;
      `)}
    }

    p {
      max-width: 90%;
      margin: 1em auto;
    }

    .cardContainer {
      margin-bottom: 0;
    }
  }
`;

const Countdown = styled.div`
  && {
    font-size: 5rem;
    font-family: "Righteous", sans-serif;
    margin: 1em auto -1em;

    span {
      position: relative;
      top: -2em;
    }
  }
`;

export default function BeingKickedModal() {
  const tcTheme = useContext(ThemeContext);
  const {
    activeKickOrder,
    clearKickOrder,
    humanPlayers,
    me,
    pusherPin,
    roomConfig,
  } = useContext(PusherContext);
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  const countdownRef = useRef(secondsRemaining);
  countdownRef.current = secondsRemaining;

  // countdown!
  useEffect(() => {
    if (secondsRemaining === 0) {
      return;
    }

    const countdown = setTimeout(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(countdownRef.current - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(countdown);
    };
  }, [secondsRemaining]);

  useEffect(() => {
    if (!activeKickOrder) {
      return;
    }

    const kickTime = DateTime.fromISO(activeKickOrder.executeAt);
    const diff = Math.round(kickTime.diffNow("seconds").seconds);
    setSecondsRemaining(diff);
  }, [activeKickOrder]);

  const handleVetoKick = useCallback(async () => {
    if (!pusherPin) {
      return;
    }
    await vetoKick(pusherPin);
    clearKickOrder();
  }, [clearKickOrder, pusherPin]);

  if (activeKickOrder === null) {
    return null;
  }
  if (activeKickOrder.playerBeingKickedId !== me?.userId) {
    return null;
  }

  const kickerName = humanPlayers[activeKickOrder?.kickerId]?.name ?? "Someone";

  return (
    <>
      <ModalBackdrop />
      <ModalContent tcTheme={tcTheme}>
        <Card
          submitButtonProps={{
            textLabels: {
              enabled: "I'm Not Dead Yet",
              submitting: "One sec...",
              disabled: "It's too late...",
            },
            onClick: handleVetoKick,
            disabled: secondsRemaining <= 2,
          }}
        >
          <h1>You're Being Kicked!</h1>

          {secondsRemaining > 0 && (
            <>
              <Countdown>
                <RadialProgress
                  currentValue={secondsRemaining}
                  totalValue={roomConfig?.inactiveTimeoutSeconds ?? 30}
                />
                <span>{secondsRemaining}</span>
              </Countdown>
            </>
          )}

          <p>
            {kickerName} is trying to kick you out. If you want to stay in the
            game, just hit the button below!
          </p>
        </Card>
      </ModalContent>
    </>
  );
}
