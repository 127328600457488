import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";

// components
import { PrimaryButton } from "../design-components/BlockButton";
import { BlockInput } from "../design-components/BlockInput";
import { TCLogo } from "../TCLogo/TCLogo";
import InfoBlurb from "../design-components/InfoBlurb";
import { FormContainer, ErrorWrapper } from "./helpers/designComponents";

// helpers
import getAuthenticationFailureDescription from "./helpers/authenticationFailures";

// types
import { PusherContext } from "../pusherContext";
import { APP_ROUTES } from "../constants";
import { AuthLinkState } from "./helpers/AuthLinkState";
import { IsLoggedIn } from "./__generated__/IsLoggedIn";
import { UserLogin, UserLoginVariables } from "./__generated__/UserLogin";

// graphql
import { IS_LOGGED_IN } from "./IsLoggedIn";
import LOGIN_MUTATION from "./LoginMutation";
import { AuthenticationFailure } from "../__generated__/globalTypes";

export default function Login() {
  const history = useHistory();
  const location = useLocation<AuthLinkState>();
  const { updateWhoAmI } = useContext(PusherContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<AuthenticationFailure | null>(null);

  // load email from link state if the signup page sent it
  useEffect(() => {
    if (location.state) {
      const { email: stateEmail } = location.state;
      setEmail(stateEmail);
    }
  }, [location.state]);

  // redirect to / if already logged in
  const { data: loginData } = useQuery<IsLoggedIn>(IS_LOGGED_IN, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (loginData?.me?.userId) {
      history.push("/");
    }
  }, [history, loginData]);

  const [login, { loading: loginLoading }] = useMutation<
    UserLogin,
    UserLoginVariables
  >(LOGIN_MUTATION, {
    variables: {
      data: {
        email,
        password,
      },
    },
  });

  const handleHome = () => {
    history.push("/");
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      const result = await login();
      if (result.data?.login?.success) {
        updateWhoAmI();

        const redirectURI = new URLSearchParams(location.search).get("to");
        console.log({ redirectURI });
        if (redirectURI.length > 0) {
          console.log("pushing to", decodeURIComponent(redirectURI));
          history.push(decodeURIComponent(redirectURI));
        } else {
          history.push("/");
        }
      } else {
        setError(
          result.data?.login?.failureType ?? AuthenticationFailure.UnknownError,
        );
      }
    } catch (e) {
      console.error(e);
      setError(e.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Got 'Em: Log In</title>
      </Helmet>

      <TCLogo onClick={handleHome} />

      <FormContainer onSubmit={handleLogin}>
        <BlockInput
          type="text"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <BlockInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <PrimaryButton type="submit" disabled={loginLoading}>
          Log In
        </PrimaryButton>

        {error && (
          <ErrorWrapper>
            <InfoBlurb hideThatFinger={true}>
              <>
                {getAuthenticationFailureDescription(error)}
                {error === AuthenticationFailure.AccountNotFound && (
                  <Link<AuthLinkState>
                    to={{
                      pathname: APP_ROUTES.SIGNUP,
                      state: {
                        email,
                      },
                    }}
                  >
                    Sign up instead
                  </Link>
                )}
              </>
            </InfoBlurb>
          </ErrorWrapper>
        )}

        <Link
          to={{
            pathname: APP_ROUTES.MAGIC_LOGIN,
          }}
        >
          Send me a magic link
        </Link>
      </FormContainer>
    </>
  );
}
