import { useState, useCallback } from "react";

/**
 * Manage a list of strings and expose a function to check whether that thing has been seen before, as well as a function
 * to mark a thing as having been seen.
 */
export const useSeenTracker = (): [
  (test: string) => boolean,
  (newItem: string) => void,
] => {
  const [seenMap, setSeenMap] = useState(new Map<string, boolean>());

  const checkSeen = useCallback(
    (key: string) => {
      return seenMap.has(key);
    },
    [seenMap],
  );

  const addItem = useCallback(
    (item: string) => {
      const draft = seenMap;
      draft.set(item, true);
      setSeenMap(draft);
    },
    [seenMap],
  );

  return [checkSeen, addItem];
};
