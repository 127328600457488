import { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Card } from "../design-components/Card/Card";
import { InlinePlayerName } from "../design-components/InlinePlayerName/InlinePlayerName";
import { lightDarkVariant } from "../lib/lightDarkVariant";
import { PusherContext } from "../pusherContext";
import { ThemeContext } from "../themeContext";
import { GuessResultPayload } from "../types/eventPayloads";
import { ThemedComponentProps } from "../types/ThemedComponentProps";
import TopIcon from "./TopIcon";

const GuessedLabel = styled.div<ThemedComponentProps>`
  && {
    margin: 4rem 0;

    p {
      z-index: 2;
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.2rem;
      font-size: 1.2rem;
      margin: 0;
      padding: 0 1rem;

      ${(props) =>
        lightDarkVariant("background-color", {
          light: props.tcTheme.light.detail,
          dark: props.tcTheme.dark.detail,
        })}
    }

    .border-line {
      z-index: 1;
      width: 70%;
      margin: 0 auto 0;
      position: relative;
      top: -0.85rem;

      ${(props) =>
        lightDarkVariant("border-bottom", {
          light: `1px solid ${props.tcTheme.light.primaryTextColor}`,
          dark: `1px solid ${props.tcTheme.dark.primaryTextColor}`,
        })};
    }
  }
`;

const Wrapper = styled.div.attrs(() => ({
  className: "last-guess",
}))<ThemedComponentProps>`
  && {
    position: relative;

    h2 {
      font-family: "Righteous";
    }

    section.streak {
      font-size: 1.2rem;
      margin-top: 1rem;
      text-align: center;
      font-weight: bold;

      span {
        padding: 2px 5px;
        border-radius: 5px;

        ${(props) =>
          lightDarkVariant("background-color", {
            light: props.tcTheme.light.primary,
            dark: props.tcTheme.dark.primary,
          })};
      }
    }

    section.guesser {
      text-align: center;
      margin: 3rem 0;
    }

    section.guessee {
      margin: 0 auto;
      width: 70%;

      .inlinePlayerName {
        text-align: center;
      }
    }

    blockquote {
      margin: 0;
      padding: 0.4em 0;
      font-size: 2.5rem;
      border: 0;
    }

    .cardContents {
      position: relative;
      padding-top: 5rem;
    }

    .inlinePlayerName {
      display: block;
      margin: 0;
    }
  }
`;

interface LastGuessProps {
  parentBackgroundIsBody?: boolean;
}

export const LastGuess = ({
  answerGuessedId,
  playerGuessedId,
  guessCorrect,
  playerWhoGuessed,
  parentBackgroundIsBody = false,
  correctGuessStreak,
}: GuessResultPayload & LastGuessProps) => {
  const { currentAnswers } = useContext(PusherContext);
  const tcTheme = useContext(ThemeContext);
  const [answerGuessed, setAnswerGuessed] = useState("");

  useEffect(() => {
    if (answerGuessedId) {
      const fullAnswer = currentAnswers.find(
        (answer) => answer.answerId === +answerGuessedId,
      );
      if (fullAnswer) {
        setAnswerGuessed(fullAnswer.answer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerGuessedId]);

  return (
    <Wrapper tcTheme={tcTheme}>
      <TopIcon
        correct={guessCorrect}
        parentBackgroundIsBody={parentBackgroundIsBody}
      />
      <Card>
        {correctGuessStreak > 0 && (
          <section className="streak">
            <span>🔥 {correctGuessStreak}</span>
          </section>
        )}
        <section className="guesser">
          <InlinePlayerName playerId={playerWhoGuessed} scalingFactor={2} />
          <GuessedLabel tcTheme={tcTheme}>
            <p>guessed</p>
            <div className="border-line" />
          </GuessedLabel>
        </section>
        <section className="guessee">
          <InlinePlayerName playerId={playerGuessedId} />
          <blockquote>{answerGuessed}</blockquote>
        </section>
      </Card>
    </Wrapper>
  );
};
