import axios from "axios";

const baseApiUri = `${process.env.REACT_APP_BACKEND_HOST}/room`;

/**
 * Update the current user's profile
 * @param updatePayload Key/value pairs of properties to be updated
 */
export const kickUser = async (roomPin: string, userId: string) => {
  return axios.post(`${baseApiUri}/${roomPin}/kickUser`, null, {
    params: {
      userId,
    },
  });
};

export const vetoKick = async (roomPin: string) => {
  return axios.post(`${baseApiUri}/${roomPin}/vetoKick`);
};
