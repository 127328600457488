import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { useQuery } from "@apollo/client";
import axios from "axios";
import styled from "styled-components/macro";

import { APP_ROUTES, GAME_PHASE } from "../constants";
import { RoomCreateConfig, NewRoomOptions } from "../types/eventPayloads";
import { PusherContext } from "../pusherContext";
import { ThemeContext, defaultTheme } from "../themeContext";

import { AuxButton } from "../design-components/AuxButton/AuxButton";
import { Card } from "../design-components/Card/Card";
import { CardFold } from "../design-components/CardFold/CardFold";
import { CardForm } from "../design-components/CardForm/CardForm";
import { CardBanner } from "../design-components/CardBanner/CardBanner";
import OxfordString from "../design-components/OxfordString/OxfordString";

import { TCLogo } from "../TCLogo/TCLogo";
import { trackEvent, trackPage } from "../SegmentManager";

import {
  selectAll,
  selectNone,
  selectDefault,
} from "./helpers/packSelectionPresets";

import { GetUserPacks } from "./__generated__/GetUserPacks";
import GET_USER_PACKS from "./getUserPacks";

const CreateRoomWrapper = styled.div`
  padding-bottom: 30vh;

  .card-form {
    p {
      font-size: 0.8em;
    }
  }

  button.back-home {
    margin: 0 auto;
    display: block;
  }
`;

const PackMultiSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5rem 0 3rem 0;

  && {
    button {
      background-color: #fff;
      color: #000;
      font-size: 1.2rem;
      margin: 0 1.5rem;

      &:focus {
        opacity: 0.8;
      }
    }
  }
`;

const PackOption = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;

  && {
    input {
      margin-top: 0.15rem;
    }

    label {
      p {
        margin: 0;
        font-size: 1.5rem;
        font-weight: normal;

        &:first-child {
          padding-bottom: 0.3rem;
        }

        span {
          font-size: 1rem;
          font-weight: bold;
          text-transform: uppercase;
          background-color: #fff;
          color: #000;
          margin-left: 1rem;
          padding: 0.2rem 0.5rem;
          border-radius: 5px;
          position: relative;
          top: -0.2rem;
        }
      }
    }
  }
`;

type CreateRoomProps = {
  setGamePhase: (newPhase: GAME_PHASE) => void;
  handleJoin: CallableFunction;
  setPin: CallableFunction;
  setColorTheme: CallableFunction;
  updateWhoAmI: () => void;
};

export const CreateRoom = (props: CreateRoomProps) => {
  const tcTheme = useContext(ThemeContext);

  const history = useHistory();
  const { configPreset: roomCreateConfigSlug } = useParams<{
    configPreset?: string;
  }>();

  const { gamePhase } = useContext(PusherContext);

  const [createRoomEnabled, setCreateRoomEnabled] = useState(false);

  // state for custom game modes
  const [
    configPresetData,
    setConfigPresetData,
  ] = useState<RoomCreateConfig | null>(null);

  // standard state for any room
  const [nsfwEnabled, setNsfwEnabled] = useState(true);
  const [createError, setCreateError] = useState<string | null>(null);

  // state for pack selection
  const [selectedPacks, setSelectedPacks] = useState<Map<string, boolean>>(
    new Map(),
  );
  const [packsTainted, setPacksTainted] = useState(false);

  // get user's packs for configuration form
  const { error: userPacksError, data: userPacks } = useQuery<GetUserPacks>(
    GET_USER_PACKS,
    {
      onCompleted: (data) => {
        // initialize pack selection state to default selection
        setSelectedPacks(selectDefault(data.packs));
      },
    },
  );

  useEffect(() => {
    trackPage("Setup", "Create Room");
  }, []);

  // if a preset config was found, load it from the server if possible
  useEffect(() => {
    const getFeaturedConfig = async () => {
      if (!roomCreateConfigSlug) {
        return;
      }

      try {
        const featuredConfig = await axios.get<RoomCreateConfig>(
          `${process.env.REACT_APP_BACKEND_HOST}/featuredConfigs/${roomCreateConfigSlug}`,
        );

        if (!featuredConfig.data.userOwnsAllPacks) {
          history.push(APP_ROUTES.SHOP);
        } else {
          setConfigPresetData(featuredConfig.data);
          props.setColorTheme(featuredConfig.data.colorTheme);
        }
      } catch (error) {
        Sentry.captureException(error);
        history.push(APP_ROUTES.CREATE_ROOM);
      }
    };
    getFeaturedConfig();
  }, [roomCreateConfigSlug, history, props]);

  // when game enters lobby state, navigate back to / so we can join the action
  useEffect(() => {
    if (gamePhase === GAME_PHASE.LOBBY) {
      history.push("/");
    }
  }, [gamePhase, history]);

  useEffect(() => {
    const createRoomEnabled =
      !packsTainted ||
      (selectedPacks &&
        Array.from(selectedPacks.entries()).filter((entry) => entry[1]).length >
          0);
    setCreateRoomEnabled(createRoomEnabled);
  }, [selectedPacks, packsTainted]);

  const backHome = useCallback(() => {
    props.setColorTheme(defaultTheme);
    props.setGamePhase(GAME_PHASE.HOME_SCREEN);
    history.push("/");
  }, [props, history]);

  const goShopping = useCallback(() => {
    history.push(APP_ROUTES.SHOP);
  }, [history]);

  const handleCreate = useCallback(async () => {
    try {
      setCreateError(null);

      let roomProps: NewRoomOptions = {
        nsfwEnabled,
        rotationMode: "linear",
      };

      if (configPresetData?.requestedPackIds) {
        roomProps = {
          ...roomProps,
          requestedPackIds: configPresetData.requestedPackIds,
        };
      }

      if (configPresetData?.colorThemeName) {
        roomProps = {
          ...roomProps,
          colorThemeName: configPresetData.colorThemeName,
        };
      }

      if (configPresetData?.slug) {
        roomProps = {
          ...roomProps,
          configPresetName: configPresetData.slug,
        };
      }

      // if user has overridden packs, use those for the room
      if (packsTainted) {
        console.debug("Sending user's pack selection in create room props");
        const selectedPackIds = Array.from(selectedPacks.entries())
          .filter((entry) => entry[1])
          .map((entry) => entry[0]);

        roomProps = {
          ...roomProps,
          requestedPackIds: selectedPackIds,
        };
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_HOST}/room`,
        roomProps,
      );

      const { pin, channel_id } = response.data;
      props.setPin(pin);
      props.handleJoin(channel_id);

      trackEvent("Room Created", {
        pin,
        nsfwEnabled,
        rotationMode: "linear",
        packsTainted,
      });
    } catch (error) {
      console.log({ error });
      if (error?.response?.data?.message) {
        setCreateError(error.response.data.message);
      } else {
        setCreateError(error.message);
      }
    }
  }, [props, nsfwEnabled, configPresetData, packsTainted, selectedPacks]);

  const toggleNsfwEnabled = useCallback(() => {
    setNsfwEnabled(!nsfwEnabled);
  }, [nsfwEnabled, setNsfwEnabled]);

  const togglePack = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const packId = e.currentTarget.name;
    const isChecked = e.currentTarget.checked;

    setSelectedPacks((prev) => {
      const next = new Map(prev);
      next.set(packId, isChecked);
      return next;
    });
    setPacksTainted(true);
  }, []);

  const selectAllPacks = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (userPacks) {
      setSelectedPacks(selectAll(userPacks.packs));
      setPacksTainted(true);
      trackEvent("All Packs Selected");
    }
  };

  const selectNoPacks = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (userPacks) {
      setSelectedPacks(selectNone(userPacks.packs));
      setPacksTainted(true);
      trackEvent("No Packs Selected");
    }
  };

  const selectDefaultPacks = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (userPacks) {
      setSelectedPacks(selectDefault(userPacks.packs));
      setPacksTainted(true);
      trackEvent("Default Packs Selected");
    }
  };

  // display summary of custom game mode if one is selected
  let gameModeSummary: ReactNode = null;
  if (configPresetData) {
    const { secondaryEmoji, packNames } = configPresetData;

    gameModeSummary = (
      <CardBanner emoji={secondaryEmoji}>
        <p>
          Play a special game mode featuring prompts from the{" "}
          <OxfordString elements={packNames} />.
        </p>
      </CardBanner>
    );
  }

  return (
    <CreateRoomWrapper>
      <TCLogo
        subtitle="Create Room"
        onClick={backHome}
        primaryEmoji={configPresetData?.primaryEmoji}
      />

      <Card
        topContent={gameModeSummary}
        title="Room Settings"
        subtitle={createError}
        submitButtonProps={{
          onClick: handleCreate,
          textLabels: {
            disabled: "No Packs Selected",
            enabled: "Create Room",
            submitting: "Creating new room...",
          },
          disabled: !createRoomEnabled,
          resetOnChange: createError,
        }}
      >
        <CardForm>
          <input
            type="checkbox"
            name="nsfwEnabled"
            id="nsfwEnabled"
            checked={nsfwEnabled}
            onChange={toggleNsfwEnabled}
          />
          <label htmlFor="nsfwEnabled">Include adult prompts</label>

          {/* TODO: allow user to enable other user's Prompt Packs in the mix? */}
          {/* TODO: toggle for "PIN-only" vs "friends can join" */}
        </CardForm>

        <br />

        {!userPacksError && userPacks && (
          <CardFold buttonText="Configure room..." permanentOpen={true}>
            <CardForm>
              <fieldset>
                <h4>Packs to include in the game</h4>
                <p>
                  Prompts will be chosen randomly and will not repeated in the
                  same game session. Players can vote to skip any prompt.
                </p>

                <PackMultiSelectContainer>
                  <AuxButton onClick={selectAllPacks} tcTheme={tcTheme}>
                    All
                  </AuxButton>
                  <AuxButton onClick={selectNoPacks} tcTheme={tcTheme}>
                    None
                  </AuxButton>
                  <AuxButton onClick={selectDefaultPacks} tcTheme={tcTheme}>
                    Default
                  </AuxButton>
                </PackMultiSelectContainer>

                {userPacks.packs.map((pack) => (
                  <PackOption key={pack.packId}>
                    <input
                      type="checkbox"
                      name={`${pack.packId}`}
                      id={`${pack.packId}-checkbox`}
                      checked={selectedPacks.get(pack.packId) === true || false}
                      onChange={togglePack}
                    />
                    <label htmlFor={`${pack.packId}-checkbox`}>
                      <p>
                        <b>{pack.packName}</b>
                        {pack.isSeasonal && <span>Seasonal</span>}
                      </p>
                      <p>{pack.marketingDescription}</p>
                    </label>
                  </PackOption>
                ))}

                <AuxButton onClick={goShopping} tcTheme={tcTheme}>
                  Get More Prompts
                </AuxButton>
              </fieldset>
            </CardForm>
          </CardFold>
        )}
      </Card>

      <AuxButton tcTheme={tcTheme} className="back-home" onClick={backHome}>
        Back Home
      </AuxButton>
    </CreateRoomWrapper>
  );
};
