import React, { useState, useCallback, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import styled from "styled-components/macro";

import "./LoginScreen.scss";

import { AuthForm } from "./AuthForm";
import { Card, CardProps } from "../design-components/Card/Card";
import { APP_ROUTES } from "../constants";
import {
  MenuContainer,
  MenuRow,
} from "../design-components/MenuLayout/MenuLayout";

const LoginScreenWrapper = styled.div`
  .menuContainer {
    margin: 2rem 0 5rem;
  }

  .cardContents {
    padding-top: 0 !important;
  }
`;

type LoginScreenProps = {
  onSubmit?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  cardProps?: Omit<CardProps, "children">;
  children?: ReactNode;
  initialMode?: "login" | "signup" | "none";
};

export const LoginScreen = (props: LoginScreenProps) => {
  const history = useHistory();
  const [authError, setAuthError] = useState<string | null>(null);
  const [authMode, setAuthMode] = useState<LoginScreenProps["initialMode"]>(
    props.initialMode || "none",
  );

  const handleMagic = useCallback(() => {
    history.push(APP_ROUTES.MAGIC_LOGIN);
  }, [history]);

  const setLoginMode = useCallback(() => {
    setAuthMode("login");
  }, [setAuthMode]);

  const setSignupMode = useCallback(() => {
    setAuthMode("signup");
  }, [setAuthMode]);

  const handleSubmitLogin = useCallback(
    async (email: string, password: string) => {
      props.onSubmit?.apply(null, []);
      setAuthError(null);

      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/user/login`, {
          email: email.trim(),
          password,
        });

        props.onSuccess?.apply(null, []);
      } catch (error) {
        props.onError?.apply(null, []);

        if (error.response.status === 401) {
          setAuthError("Incorrect credentials");
        } else {
          setAuthError(
            "Something's up on our end... please try again shortly.",
          );
          Sentry.captureException(error);
        }
      }
    },
    [props],
  );

  const handleSubmitSignup = useCallback(
    async (email: string, password: string, name?: string) => {
      props.onSubmit?.apply(null, []);
      setAuthError(null);

      try {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_HOST}/user/register`,
          {
            email: email.trim(),
            password,
            name,
          },
        );

        props.onSuccess?.apply(null, []);
      } catch (error) {
        props.onError?.apply(null, []);

        if (error.response.status === 400 || error.response.status === 403) {
          setAuthError(error.response.data.message);
        } else {
          setAuthError(
            "Something's up on our end... please try again shortly.",
          );
          Sentry.captureException(error);
        }
      }
    },
    [props],
  );

  if (!authMode) {
    return null;
  }

  if (authMode === "none") {
    return (
      <MenuContainer>
        {props.cardProps && (
          <MenuRow className="above-main">
            <Card {...props.cardProps} />
          </MenuRow>
        )}
        <MenuRow>
          <Card
            fullCardButton={true}
            submitButtonProps={{
              disabled: false,
              onClick: setLoginMode,
              textLabels: {
                disabled: "Log In",
                enabled: "Log In",
                submitting: "Log In",
              },
            }}
          >
            <span role="img" aria-label="Two Beer Glasses">
              🍻
            </span>
          </Card>
          <Card
            fullCardButton={true}
            submitButtonProps={{
              disabled: false,
              onClick: setSignupMode,
              textLabels: {
                disabled: "Create Account",
                enabled: "Create Account",
                submitting: "Create Account",
              },
            }}
          >
            <span role="img" aria-label="Man Dancing">
              🕺
            </span>
          </Card>
        </MenuRow>
      </MenuContainer>
    );
  }

  const switchModeButton =
    authMode === "login" ? (
      <Card
        submitButtonProps={{
          onClick: setSignupMode,
          disabled: false,
          disableOnClick: false,
          textLabels: {
            disabled: "Create Account",
            enabled: "Create Account",
            submitting: "Create Account",
          },
        }}
      />
    ) : (
      <Card
        submitButtonProps={{
          onClick: setLoginMode,
          disabled: false,
          disableOnClick: false,
          textLabels: {
            disabled: "Log In",
            enabled: "Log In",
            submitting: "Log In",
          },
        }}
      />
    );

  return (
    <LoginScreenWrapper>
      <AuthForm
        authError={authError}
        mode={authMode}
        handleSubmit={
          authMode === "login" ? handleSubmitLogin : handleSubmitSignup
        }
        cardProps={props.cardProps}
      >
        <MenuContainer>
          <MenuRow>
            {switchModeButton}
            <Card
              submitButtonProps={{
                onClick: handleMagic,
                disabled: false,
                textLabels: {
                  disabled: "Request Magic Link",
                  enabled: "Request Magic Link",
                  submitting: "Request Magic Link",
                },
              }}
            />
          </MenuRow>
        </MenuContainer>
      </AuthForm>
    </LoginScreenWrapper>
  );
};
