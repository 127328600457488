import React from "react";
import styled from "styled-components/macro";
import { ProfilePicContainer } from "../ProfilePicContainer/ProfilePicContainer";
import { Card } from "../Card/Card";
import { ResponseMeta } from "./ResponseMeta";

const ResponseWrapper = styled.div.attrs(() => ({
  className: "gameResponse",
}))`
  margin: 0;
  overflow-y: visible;
  font-size: 2.8rem;

  .withImage {
    display: flex;
    flex-direction: row;

    .profilePicContainer {
      width: 4rem;
      height: 4rem;
      border-width: 2px;
    }

    p {
      margin: 0 0 0 1.5rem;
      padding-top: 0.2rem;
    }
  }
`;

export interface GameResponseProps {
  onClick?: (e: any) => void;
  responseImage?: string;
  responseTitle: string;
  responseIndex?: number;
  totalResponses?: number;
  children?: React.ReactChild;
}

export const GameResponse = ({
  onClick,
  responseImage,
  responseTitle,
  responseIndex,
  totalResponses,
  children,
}: GameResponseProps) => {
  // render newlines
  const responseText = responseTitle.split("\n").map((line, index) => (
    <span key={`res-${index}`}>
      {line}
      <br />
    </span>
  ));

  return (
    <ResponseWrapper onClick={onClick}>
      <Card>
        {children}

        <ResponseMeta
          responseIndex={responseIndex}
          totalResponses={totalResponses}
        />

        {responseImage && (
          <div className="withImage">
            <ProfilePicContainer profilePicture={responseImage} />
            <p>{responseText}</p>
          </div>
        )}
        {!responseImage && responseText}
      </Card>
    </ResponseWrapper>
  );
};
