import styled from "styled-components/macro";
import "./SelectionModal.scss";
import { GameResponse } from "../design-components/GameResponse/GameResponse";

const Modal = styled.div`
  h1 {
    font-family: "Righteous", sans-serif;
    font-size: 2.2rem;
    font-weight: normal;
    text-align: center;
  }
`;

export type SelectionModalProps = {
  handleClose: (e: any) => void;
  handleSelection: (optionId: string) => void;
  headerText: string;
  options: {
    [key: string]: {
      image?: string;
      title: string;
    };
  };
};

export const SelectionModal = ({
  handleClose,
  handleSelection,
  headerText,
  options,
}: SelectionModalProps) => {
  const generateHandleSelection = (optionId: string) => () => {
    handleSelection(optionId);
    handleClose(null);
  };

  const optionList = Object.entries(options).map(([optionId, displayValue]) => (
    <GameResponse
      key={optionId}
      responseImage={displayValue.image}
      responseTitle={displayValue.title}
      onClick={generateHandleSelection(optionId)}
    />
  ));

  return (
    <Modal>
      <div className="modalOverlay" onClick={handleClose} />
      <div className="selectionModal">
        <div className="modalSpacer" />
        <div className="modalContent">
          <button className="closeModal" onClick={handleClose}>
            &times;
          </button>
          <h1>{headerText}</h1>
          {optionList}
        </div>
      </div>
    </Modal>
  );
};
