import styled, { css } from "styled-components/macro";
import { EventModalProps } from "./EventModal";
import { lightDarkVariant } from "../../lib/lightDarkVariant";
import { smallPhone } from "../../lib/smallPhone";
import { largePhone } from "../../lib/largePhone";

// @ts-expect-error
import SpaceBackground from "../../TCLogo/jeremy-thomas-space.jpg";

const StarText = styled.h1`
  @keyframes bg-pan {
    from {
      background-position: bottom left;
    }
    to {
      background-position: top right;
    }
  }

  z-index: 10002;
  margin-bottom: 5vh;
  font-family: "Righteous", sans-serif;
  text-align: center;
  background: url(${SpaceBackground}) repeat;
  background-size: 2503px 1708px;
  /* background-position: top right; */
  background-clip: text;
  /* required for Safari to work: */
  -webkit-background-clip: text;
  color: transparent;
  font-size: 85px;
  /* transition: background-position 240s; */
  animation: bg-pan 15s linear infinite;

  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;

  ${lightDarkVariant("background-position", {
    light: "top left",
    dark: "center",
  })}

  ${largePhone(css`
    && {
      font-size: 64px;
    }
  `)}

  ${smallPhone(css`
    && {
      font-size: 50px;
    }
  `)}
`;

const ImageBanner = styled.img`
  max-width: 250px;
  display: block;
  margin: 0 auto 2rem;
`;

export default function PrimaryText(props: EventModalProps) {
  switch (props.imageBanner) {
    default:
      return <StarText>{props.starText}</StarText>;
    case "gotem":
      return <ImageBanner src="/assets/logo.png" alt={props.starText} />;
    case "nicetry":
      return <ImageBanner src="/assets/nice_try.png" alt={props.starText} />;
  }
}
