import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { TCLogo } from "../TCLogo/TCLogo";
import { GAME_PHASE } from "../constants";
import { Card } from "../design-components/Card/Card";
import getPusherChannel from "../lib/getPusherChannel";
import { PusherContext } from "../pusherContext";
import { trackEvent, trackPage } from "../SegmentManager";

type JoinRoomProps = {
  setGamePhase: (newPhase: GAME_PHASE) => void;
  handleJoin: CallableFunction;
  userName: string | null;
  setUserName: (newUsername: string) => void;
  setPin: (newPin: string) => void;
};

export const JoinRoom = (props: JoinRoomProps) => {
  const history = useHistory();
  const { roomPin: prefilledPin } = useParams<{
    roomPin?: string;
  }>();

  const { gamePhase } = useContext(PusherContext);

  const [enteredPin, setEnteredPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [pinWasPreset, setPinWasPreset] = useState(false);

  useEffect(() => {
    trackPage("Setup", "Join Room");
  }, []);

  // when game enters lobby state, navigate back to / so we can join the action
  useEffect(() => {
    if (gamePhase === GAME_PHASE.LOBBY) {
      history.push("/");
    }
  }, [gamePhase, history]);

  // parse PIN from URL if present (like if somebody shares the room link)
  useEffect(() => {
    if (prefilledPin && prefilledPin.length) {
      setPinWasPreset(true);
      setEnteredPin(prefilledPin.trim());
    }
  }, [prefilledPin]);

  // action handlers
  const backHome = useCallback(() => {
    props.setGamePhase(GAME_PHASE.HOME_SCREEN);
    history.push("/");
  }, [props, history]);

  const handleJoin = useCallback(async () => {
    setPinError("");

    try {
      // attempt to look up Pusher channel by PIN, otherwise show error
      const pusherChannelName = await getPusherChannel(enteredPin);
      props.setPin(enteredPin);
      props.handleJoin(pusherChannelName);

      trackEvent("Room Joined", {
        pin: enteredPin,
      });
    } catch (e) {
      console.error(e);

      let errorMessage = "Something went wrong. Please try again later!";
      if (e.response.status === 404 || e.response.status === 410) {
        errorMessage = "That PIN doesn't look right.";
      } else {
        Sentry.captureException(e);
      }

      setPinError(errorMessage);
    }
  }, [enteredPin, props]);

  // form change handlers
  const handleNameChange = useCallback(
    (e: any) => {
      props.setUserName(e.target.value);
    },
    [props],
  );

  const handlePinChange = useCallback(
    (e: any) => {
      setEnteredPin(e.target.value);
    },
    [setEnteredPin],
  );

  return (
    <>
      <TCLogo subtitle="Join Room" onClick={backHome} />

      <Card
        title={
          pinWasPreset
            ? "You've been invited to join a room!"
            : "Ask your friends for the room PIN!"
        }
        subtitle={pinError}
        submitButtonProps={{
          onClick: handleJoin,
          textLabels: {
            disabled: "Enter name and PIN",
            enabled: "Join Room",
            submitting: "Joining...",
          },
          disabled: enteredPin.length !== 4 || props.userName?.length === 0,
          resetOnChange: pinError,
        }}
      >
        <fieldset>
          <input
            type="text"
            id="name"
            placeholder="<Your name here>"
            value={props.userName || ""}
            onChange={handleNameChange}
          />
          <label htmlFor="name">Your Name</label>
        </fieldset>

        <fieldset>
          <input
            type="tel"
            id="pin"
            name="pin"
            placeholder="<Enter room PIN>"
            value={enteredPin}
            onChange={handlePinChange}
          />
          <label htmlFor="pin">Room PIN</label>
        </fieldset>
      </Card>

      <button onClick={backHome}>Back Home</button>
    </>
  );
};
