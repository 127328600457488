/// <reference types="@types/segment-analytics" />

import { useContext, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { PusherContext } from "../pusherContext";

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export function loadSegment() {
  if (!window.analytics || process.env.REACT_APP_DEPLOY_ENV !== "production") {
    return;
  }

  try {
    window.analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);
    window.analytics.page();
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function teardownSegment() {
  if (!window.analytics || process.env.REACT_APP_DEPLOY_ENV !== "production") {
    return;
  }

  try {
    window.analytics.reset();
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function trackPage(
  category?: string,
  name?: string,
  properties?: { [key: string]: string | number | boolean },
) {
  if (!window.analytics || process.env.REACT_APP_DEPLOY_ENV !== "production") {
    console.debug("Ignoring trackPage in this environment", ...arguments);
    return;
  }

  try {
    window.analytics.page(category, name, properties);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function trackEvent(
  name: string,
  properties?: { [key: string]: string | number | boolean },
) {
  if (!window.analytics || process.env.REACT_APP_DEPLOY_ENV !== "production") {
    console.debug("Ignoring trackEvent in this environment", ...arguments);
    return;
  }

  try {
    window.analytics.track(name, properties);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export default function SegmentManager() {
  const { userId, me } = useContext(PusherContext);

  useEffect(() => {
    if (
      !window.analytics ||
      process.env.REACT_APP_DEPLOY_ENV !== "production" ||
      !me ||
      !userId
    ) {
      return;
    }

    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      window.analytics.identify(userId, {
        name: me.name,
        email: me.email,
      });
    }
  }, [userId, me]);

  return null;
}
