import * as Sentry from "@sentry/browser";
import { PointEvent } from "../types/eventPayloads";

type FriendlyLabel = {
  emoji: string;
  label: string;
};

export function getFriendlyLabel(pointEvent: PointEvent): FriendlyLabel {
  const labelMap = new Map<PointEvent, FriendlyLabel>();

  labelMap.set(PointEvent.CorrectGuess, {
    emoji: "✅",
    label: "Correct Guess",
  });
  labelMap.set(PointEvent.StreakBonus, {
    emoji: "🔥",
    label: "Streak Bonus",
  });
  labelMap.set(PointEvent.DrySpellBroken, {
    emoji: "💦",
    label: "Dry Spell Broken",
  });
  labelMap.set(PointEvent.QuickAnswer, {
    emoji: "🏃",
    label: "Quick Answer",
  });
  labelMap.set(PointEvent.SlowAnswer, {
    emoji: "😴",
    label: "Slow Answer",
  });
  labelMap.set(PointEvent.GuessedSelf, {
    emoji: "🤦",
    label: "Guessed Self",
  });
  labelMap.set(PointEvent.RepeatedGuess, {
    emoji: "🍸",
    label: "Repeated Wrong Guess",
  });
  labelMap.set(PointEvent.LastOneOut, {
    emoji: "👑",
    label: "Last One Out",
  });
  labelMap.set(PointEvent.MostDeceivingAnswer, {
    emoji: "🕵️",
    label: "Elusive Answer",
  });
  labelMap.set(PointEvent.MostFrequentSuspect, {
    emoji: "👀",
    label: "Frequent Suspect",
  });
  labelMap.set(PointEvent.WorstGuesser, {
    emoji: "💩",
    label: "Worst Guesser",
  });

  if (!labelMap.has(pointEvent)) {
    Sentry.captureMessage(`Unknown point event: ${pointEvent.toString()}`);
    return {
      emoji: "",
      label: pointEvent.toString(),
    };
  }
  return labelMap.get(pointEvent)!;
}
