import React, { useContext, useEffect } from "react";
import { GuesserInterface } from "./GuesserInterface";
import { LastGuess } from "./LastGuess";
import { PusherContext } from "../pusherContext";
import { TCLogo } from "../TCLogo/TCLogo";
import InfoBlurb from "../design-components/InfoBlurb";
import NonGuesserWrapper from "./NonGuesserWrapper";
import NotGuessing from "./NotGuessing";
import { trackPage } from "../SegmentManager";

export const GuessPhase = () => {
  const pusherContext = useContext(PusherContext);
  const { guesserId, lastGuess, remainingUsers, userId } = pusherContext;

  useEffect(() => {
    trackPage("Game", "Guessing Phase");
  }, []);

  if (userId && !Object.keys(remainingUsers).includes(userId)) {
    return (
      <NonGuesserWrapper>
        <TCLogo subtitle="You're Out!" />
        <InfoBlurb>Better luck next round!</InfoBlurb>
        {lastGuess && <LastGuess {...lastGuess} />}
      </NonGuesserWrapper>
    );
  }

  if (userId !== guesserId) {
    return <NotGuessing />;
  }

  return <GuesserInterface />;
};
