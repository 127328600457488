import { TCLogo } from "../TCLogo/TCLogo";
import { Card } from "../design-components/Card/Card";

interface Props {
  handleLeave: (e: any) => void;
}

export default function TimedOut({ handleLeave }: Props) {
  return (
    <div className="lobby">
      <TCLogo />

      <Card
        title="Timed Out"
        subtitle="You've been kicked! But not to worry - you can rejoin whenever you're ready."
        submitButtonProps={{
          textLabels: {
            enabled: "Leave Room",
            disabled: "Leave Room",
            submitting: "Leave Room",
          },
          onClick: handleLeave,
          disabled: false,
        }}
      />
    </div>
  );
}
