import { useContext } from "react";
import styled from "styled-components/macro";
import { Card } from "../design-components/Card/Card";
import { InlinePlayerName } from "../design-components/InlinePlayerName/InlinePlayerName";
import { StatusIconProps } from "../design-components/InlinePlayerName/StatusIcon";
import { PusherContext } from "../pusherContext";

const Container = styled.div.attrs(() => ({
  className: "groupSubmissionStatus",
}))`
  && {
    text-align: left;

    .profilePicContainer {
      margin-right: 0.8em;
    }

    .inlinePlayerName {
      margin: 0.8em;
    }
  }
`;

export default function GroupSubmissionStatus() {
  const { humanPlayers, respondedUsers } = useContext(PusherContext);

  const statusList = Object.entries(humanPlayers).map(([id, player]) => {
    let statusIcon: StatusIconProps["icon"] = "danger";
    if (Object.keys(respondedUsers).includes(id)) {
      statusIcon = "success";
    }

    return (
      <div key={id}>
        <InlinePlayerName
          playerId={id}
          scalingFactor={1.4}
          statusIcon={statusIcon}
        />
      </div>
    );
  });

  return (
    <Container>
      <Card>{statusList}</Card>
    </Container>
  );
}
