import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { ProfilePicContainer } from "../design-components/ProfilePicContainer/ProfilePicContainer";
import { PointLineItem } from "../types/eventPayloads";
import { PusherContext } from "../pusherContext";
import { smallPhone } from "../lib/smallPhone";
import { getFriendlyLabel } from "../lib/pointLineItemHelper";
import { ThemedComponentProps } from "../types/ThemedComponentProps";
import { ThemeContext } from "../themeContext";
import { lightDarkVariant } from "../lib/lightDarkVariant";

const Wrapper = styled.div<ThemedComponentProps>`
  display: flex;
  justify-content: center;
  width: 150px;

  .avatar-container {
    @keyframes textReveal {
      from {
        opacity: 0;
        top: -20px;
      }

      to {
        opacity: 1;
        top: 0;
      }
    }

    @keyframes textRevealMuted {
      from {
        opacity: 0;
        top: -20px;
      }

      to {
        opacity: 0.7;
        top: 0;
      }
    }

    position: relative;
    display: inline;
    text-align: center;

    .award-label {
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: bold;
      padding: 0.5rem 0;
    }

    .player-name {
      position: relative;
      font-size: 2rem;
      padding: 0.5rem 0;
      font-family: "Righteous", serif;

      /* initial animation state */
      opacity: 0;
      top: -20px;

      animation: textReveal 0.8s ease-in-out forwards;
      animation-delay: 2s;
    }

    .point-value {
      position: relative;
      font-family: monospace;
      font-size: 1.6rem;
      letter-spacing: 0.1rem;
      padding: 0.3rem 0 0.5rem;

      /* initial animation state */
      opacity: 0;
      top: -20px;

      animation: textRevealMuted 0.8s ease-in-out forwards;
      animation-delay: 2.5s;
    }
  }

  .label-container {
    margin: 15px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
      margin: 0;
    }
  }

  .profilePicContainer {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    z-index: 10;

    ${smallPhone(css`
      width: 3em;
      height: 3em;
    `)}
  }

  .profile-pic-reveal-overlay {
    @keyframes reveal {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }

    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 80px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: "reveal" 0.8s ease-in-out forwards;
    animation-delay: 2s;

    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.secondary,
        dark: props.tcTheme.dark.secondary,
      })}

    ${smallPhone(css`
      width: 3em;
      height: 3em;
    `)}
  }

  .emoji-container {
    @keyframes moveDown {
      from {
        font-size: 40px;
        top: -20px;
        left: 50%;
      }

      to {
        font-size: 25px;
        top: 14px;
        left: 75%;
      }
    }

    /* initial animation state */
    font-size: 40px;
    top: -20px;
    left: 50%;

    position: absolute;
    display: block;
    width: 40px;
    margin-left: -20px;
    padding-top: 32px;
    animation: moveDown 0.8s ease-in-out forwards;
    animation-delay: 2s;

    p {
      margin: 0;
      text-align: center;
      text-shadow: 0 0 4px #000;
    }
  }
`;

type Props = {
  point: PointLineItem;
};

export default function BonusAward({ point }: Props) {
  const { humanPlayers } = useContext(PusherContext);
  const themeContext = useContext(ThemeContext);
  const [playerPic, setPlayerPic] = useState("");
  const [playerName, setPlayerName] = useState("");

  useEffect(() => {
    const { playerId } = point;
    if (playerId && humanPlayers[playerId]) {
      setPlayerPic(humanPlayers[playerId].profilePicture);
      setPlayerName(humanPlayers[playerId].name);
    }
  }, [point, humanPlayers]);

  const { emoji, label } = getFriendlyLabel(point.event);
  const mathSymbol = point.pointValue < 0 ? "-" : "+";

  return (
    <Wrapper tcTheme={themeContext}>
      <div className="avatar-container">
        <ProfilePicContainer profilePicture={playerPic} />
        <div className="profile-pic-reveal-overlay" />
        <div className="emoji-container">
          <p>{emoji}</p>
        </div>
        <div className="label-container">
          <p className="award-label">{label}</p>
          <p className="player-name">{playerName}</p>
          <p className="point-value">
            {mathSymbol}
            {Math.abs(point.pointValue).toLocaleString("en-US")}
          </p>
        </div>
      </div>
    </Wrapper>
  );
}
