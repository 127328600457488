import Decimal from "decimal.js";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Card } from "../design-components/Card/Card";
import { PusherContext } from "../pusherContext";
import { getRecommendedPack_recommendedPack } from "../queries/__generated__/getRecommendedPack";
import { trackEvent } from "../SegmentManager";

const Wrapper = styled.div`
  .cardContents {
    padding: 2rem;

    h3 {
      font-family: "Righteous", sans-serif;
      font-size: 2rem;
      font-weight: normal;
      text-align: center;
      margin: 0;
    }

    p {
      text-align: center;
      font-size: 1.8rem;
      width: 80%;
      margin: 1rem auto;
    }
  }
`;

type Props = {
  pack: getRecommendedPack_recommendedPack;
  topContent?: React.ReactNode;
  sourceForAnalytics?: string;
};

/**
 * A self-contained component allowing the user to purchase a Prompt Pack
 */
export default function PackBuyer({
  pack,
  topContent,
  sourceForAnalytics,
}: Props) {
  const { setCart } = useContext(PusherContext);
  const [lastRefresh, setLastRefresh] = useState(new Date());

  useEffect(() => {
    trackEvent("Round Summary Upsell Seen");
  }, []);

  const refreshPacks = () => {
    setLastRefresh(new Date());
  };

  const priceDollars = new Decimal(pack.priceCents).dividedBy(100);
  const priceText = `$${priceDollars.toFixed(2)}`;

  const buyPack = (pack: getRecommendedPack_recommendedPack) => {
    console.debug("buyPack", pack);

    /**
     * Callback when Checkout is closed
     */
    const hideCheckout = () => {
      refreshPacks();
      trackEvent("Checkout Canceled", {
        source: sourceForAnalytics ?? "Pack Buyer Component",
      });
    };

    setCart([pack], hideCheckout);

    trackEvent("Checkout Started", {
      packName: pack.packName,
      source: sourceForAnalytics ?? "Pack Buyer Component",
    });
  };

  return (
    <Wrapper>
      <Card
        topContent={topContent}
        submitButtonProps={{
          disabled: false,
          onClick: () => buyPack(pack),
          textLabels: {
            enabled: `Purchase (${priceText})`,
            disabled: `Not Available`,
            submitting: `Completing Purchase...`,
          },
          resetOnChange: lastRefresh,
          useThemedButton: true,
        }}
      >
        <h3>{pack.packName}</h3>
        <p>{pack.marketingDescription}</p>
      </Card>
    </Wrapper>
  );
}
