import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { lightDarkVariant } from "../lib/lightDarkVariant";

import "./OptionsScreen.scss";

import { CurrentPin } from "../CurrentPin/CurrentPin";
import { OptionsOverlay } from "../OptionsOverlay/OptionsOverlay";
import { PusherContext } from "../pusherContext";
import { ScoreSummary } from "../ScoreSummary/ScoreSummary";
import { ThemedComponentProps } from "../types/ThemedComponentProps";
import { ThemeContext } from "../themeContext";
import { Card } from "../design-components/Card/Card";
import { PlayerPreview } from "./PlayerSettings/PlayerPreview";
import { PlayerSettings } from "./PlayerSettings/PlayerSettings";
import { trackEvent, trackPage } from "../SegmentManager";

type OptionsScreenProps = {
  handleLeave: CallableFunction;
  toggleOptions: (e: any) => void;
};

const OptionsWrapper = styled.div.attrs(() => ({
  className: "optionsScreen",
}))<ThemedComponentProps>`
  ${(props) =>
    lightDarkVariant("background-color", {
      light: props.tcTheme.light.primary,
      dark: props.tcTheme.dark.primary,
    })}
`;

export const OptionsScreen = ({
  handleLeave,
  toggleOptions,
}: OptionsScreenProps) => {
  const { pusherPin, gamePoints, humanPlayers } = useContext(PusherContext);
  const tcTheme = useContext(ThemeContext);

  const [editProfileMode, setEditProfileMode] = useState(false);

  // arbitrary counter used to control disabled state on Leave Room button
  const [isLeaving, setIsLeaving] = useState(0);

  useEffect(() => {
    trackPage("Account", "Options Screen");
  }, []);

  const handleLeaveRoom = useCallback(
    (e: any) => {
      e?.preventDefault();

      if (window.confirm("Are you sure you want to leave?") === true) {
        trackEvent("Leave Room");
        handleLeave();
      } else {
        trackEvent("Leave Room Aborted");
        setIsLeaving(isLeaving + 1);
      }
    },
    [handleLeave, isLeaving],
  );

  if (editProfileMode) {
    return (
      <OptionsWrapper tcTheme={tcTheme}>
        <section>
          <OptionsOverlay toggleOptions={toggleOptions} />
          <PlayerSettings />
        </section>
      </OptionsWrapper>
    );
  }

  // only show "leave room" button if user is in a room
  let leaveRoomButtonProps = undefined;
  if (pusherPin?.length > 0) {
    leaveRoomButtonProps = {
      disabled: false,
      onClick: handleLeaveRoom,
      textLabels: {
        disabled: "Leave Room",
        enabled: "Leave Room",
        submitting: "Leaving...",
      },
      resetOnChange: isLeaving,
    };
  }

  return (
    <OptionsWrapper tcTheme={tcTheme}>
      <section>
        <OptionsOverlay toggleOptions={toggleOptions} />
        <PlayerPreview
          setEditProfileMode={setEditProfileMode}
          submitButtonProps={leaveRoomButtonProps}
        />

        {pusherPin && (
          <>
            <Card>
              <CurrentPin pin={pusherPin} />
            </Card>
          </>
        )}

        {!!gamePoints && !!humanPlayers && (
          <Card title="Scoreboard" subtitle="Updated at the end of each round.">
            <ScoreSummary showNewPoints={false} />
          </Card>
        )}
      </section>
    </OptionsWrapper>
  );
};
