import React, { useCallback, useContext } from "react";
import styled from "styled-components/macro";
import * as Sentry from "@sentry/browser";

import { ThemedComponentProps } from "../types/ThemedComponentProps";
import { lightDarkVariant } from "../lib/lightDarkVariant";
import { ThemeContext } from "../themeContext";
import { PusherContext } from "../pusherContext";
import { AuxButton } from "../design-components/AuxButton/AuxButton";
import { FancyNumbers } from "./FancyNumbers";

import "./CurrentPin.scss";

type CurrentPinProps = {
  pin: string;
};

const CurrentPinWrapper = styled.div.attrs(() => ({
  className: "currentPin",
}))<ThemedComponentProps>`
  h2 {
    ${(props) =>
      lightDarkVariant("text-shadow", {
        light: `2px 2px 1px ${props.tcTheme.light.detailShadow}`,
        dark: `2px 2px 1px ${props.tcTheme.dark.detailShadow}`,
      })}
  }

  .auxButton {
    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.secondary,
        dark: props.tcTheme.dark.secondary,
      })}

    margin-bottom: 2rem !important;
    display: block;
  }

  img.qr {
    margin-top: 3rem;
    border-radius: 5px;
  }
`;

export const CurrentPin = ({ pin }: CurrentPinProps) => {
  const tcTheme = useContext(ThemeContext);
  const { pusherPin } = useContext(PusherContext);

  const shareLink = useCallback(async () => {
    try {
      if (!navigator.share) {
        throw new Error("This browser doesn't support native sharing");
      }

      await navigator.share({
        title: `Got 'Em! Invitation Link`,
        text: "Let's play Got 'Em!",
        url: `/join/${pusherPin}`,
      });

      Sentry.captureMessage("Shared room URL successfully");
    } catch (error) {
      // if the user just cancelled it, don't rly care
      if (error.name !== "AbortError") {
        console.error(error);
        Sentry.captureException(error);
      }
    }
  }, [pusherPin]);

  return (
    <CurrentPinWrapper tcTheme={tcTheme}>
      {!!navigator.share && (
        <AuxButton tcTheme={tcTheme} onClick={shareLink}>
          Share Room Link
        </AuxButton>
      )}

      <FancyNumbers pin={pin} />
      <p>
        Tell your friends
        <br className="show-xs" /> so they can join
      </p>

      <img
        src={`${process.env.REACT_APP_BACKEND_HOST}/room/${pusherPin}/qr`}
        alt="QR code to let users join this room"
        className="qr"
      />
      <p>Or have them scan this bad boy</p>
    </CurrentPinWrapper>
  );
};
