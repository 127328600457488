import { GetMyAnswerPayload } from "./eventPayloads";

export function isMyAnswerPayload(obj: unknown): obj is GetMyAnswerPayload {
  if (typeof obj === "object") {
    if (obj?.hasOwnProperty("answer")) {
      return true;
    }
  }
  return false;
}
