import { useState, useCallback, useEffect, useRef } from "react";

/**
 * Manage a queue of items (like notifications), processing them one at a time with a configurable delay between them.
 * Exposes the current item and a function to push more items onto the queue, and handles the rest internally.
 *
 * @param intervalMs The number of milliseconds to wait between items in the queue
 */
export const useTimedQueue = <T>(
  intervalMs: number,
): [T | null, (newItem: T) => void] => {
  const [internalQueue, setInternalQueue] = useState<Array<T>>([]);
  const [currentItem, setCurrentItem] = useState<T | null>(null);

  // needed so the latest queue can be read in setTimeout's scope
  const queueRef = useRef(internalQueue);
  queueRef.current = internalQueue;

  // add a new item to the queue
  const enqueueItem = useCallback(
    (newItem: T) => {
      const [...draft] = internalQueue;
      draft.push(newItem);
      setInternalQueue(draft);
    },
    [internalQueue],
  );

  // shift the first item off an array; used for functional state updates
  const arrayShift = useCallback((oldState: Array<T>): Array<T> => {
    oldState.shift();
    return oldState;
  }, []);

  // move an item off the queue and into currentItem
  const processItem = useCallback(() => {
    const [...draft] = queueRef.current;

    if (draft.length) {
      setCurrentItem(draft.shift()!);
      setInternalQueue((draft) => arrayShift(draft));
      setTimeout(processItem, intervalMs);
    } else {
      setCurrentItem(null);
    }
  }, [intervalMs, arrayShift]);

  // handle an item being added to an empty queue
  useEffect(() => {
    if (currentItem === null) {
      if (internalQueue.length) {
        processItem();
      }
    }
  }, [internalQueue.length, currentItem, processItem]);

  return [currentItem, enqueueItem];
};
