import React, {
  useState,
  useCallback,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import styled from "styled-components/macro";
import { lightDarkVariant } from "../../lib/lightDarkVariant";
import { ThemeContext } from "../../themeContext";
import { ThemedComponentProps } from "../../types/ThemedComponentProps";

export const Button = styled.button`
  && {
    border-radius: 5px;
    font-weight: bold;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      &:hover {
        opacity: 0.6;
      }
    }
  }
`;

const ThemedButton = styled(Button)<ThemedComponentProps>`
  && {
    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.secondary,
        dark: props.tcTheme.dark.secondary,
      })}

    ${(props) =>
      lightDarkVariant("border-color", {
        light: props.tcTheme.light.secondary,
        dark: props.tcTheme.dark.secondary,
      })}

    ${(props) =>
      lightDarkVariant("color", {
        light: props.tcTheme.light.primaryTextColor,
        dark: props.tcTheme.dark.primaryTextColor,
      })}
  }
`;

export type SubmitButtonProps = {
  children?: ReactNode;
  disabled: boolean;
  buttonPrimary?: boolean;
  buttonAux?: boolean;
  onClick: (e: any) => void;
  textLabels: {
    disabled: string | React.ReactNode;
    enabled: string | React.ReactNode;
    submitting: string | React.ReactNode;
  };
  resetOnChange?: any;
  disableOnClick?: boolean;
  useThemedButton?: boolean;
};

export const SubmitButton = ({
  children,
  disabled,
  onClick,
  textLabels,
  resetOnChange,
  disableOnClick = true,
  buttonPrimary = true,
  buttonAux = false,
  useThemedButton,
}: SubmitButtonProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [buttonClasses, setButtonClasses] = useState<Set<string>>(new Set());
  const tcTheme = useContext(ThemeContext);

  useEffect(() => {
    setSubmitting(false);
  }, [resetOnChange]);

  useEffect(() => {
    if (buttonPrimary) {
      setButtonClasses((prev) => {
        const newClasses = new Set(prev);
        newClasses.add("filled");
        return newClasses;
      });
    }
  }, [buttonPrimary]);

  useEffect(() => {
    if (buttonAux) {
      setButtonClasses((prev) => {
        const newClasses = new Set(prev);
        newClasses.add("auxSubmitButton");
        return newClasses;
      });
    }
  }, [buttonAux]);

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      onClick.call(null, undefined);

      if (disableOnClick === true) {
        setSubmitting(true);
      }
    },
    [onClick, disableOnClick],
  );

  if (disabled) {
    return (
      <Button
        className={[...Array.from(buttonClasses), "submitButton"].join(" ")}
        disabled={true}
      >
        {!!children && children}
        {textLabels.disabled}
      </Button>
    );
  }

  if (submitting) {
    return (
      <Button
        className={[...Array.from(buttonClasses), "submitButton"].join(" ")}
        disabled={true}
      >
        {!!children && children}
        {textLabels.submitting}
      </Button>
    );
  }

  if (useThemedButton === true) {
    return (
      <ThemedButton
        className={[...Array.from(buttonClasses), "submitButton"].join(" ")}
        onClick={handleSubmit}
        disabled={false}
        tcTheme={tcTheme}
      >
        {!!children && children}
        {textLabels.enabled}
      </ThemedButton>
    );
  }

  return (
    <Button
      className={[...Array.from(buttonClasses), "submitButton"].join(" ")}
      onClick={handleSubmit}
      disabled={false}
    >
      {!!children && children}
      {textLabels.enabled}
    </Button>
  );
};
