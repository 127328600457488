import React, { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Decimal from "decimal.js";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { useQuery } from "@apollo/client";

import { Card } from "../design-components/Card/Card";
import TickingCountdown from "../design-components/TickingCountdown/TickingCountdown";
import { TCLogo } from "../TCLogo/TCLogo";
import {
  FeaturedConfigPack,
  ShopPack,
  ShopPackList,
} from "../types/eventPayloads";
import { PackCategory, CategoryChooser } from "./CategoryChooser";
import { CardBanner } from "../design-components/CardBanner/CardBanner";
import { ThemeContext } from "../themeContext";
import { DateTime } from "luxon";
import PackDescription from "./PackDescription";
import { trackEvent, trackPage } from "../SegmentManager";
import { PusherContext } from "../pusherContext";
import GET_SHOP_PACKS from "../queries/getShopPacks";
import { getShopPacks } from "../queries/__generated__/getShopPacks";

const ShopWrapper = styled.div`
  && {
    .cardContainer {
      margin-bottom: 3vh;
    }

    .cardContents {
      padding: 2rem;

      h3 {
        font-family: "Righteous", sans-serif;
        font-size: 2rem;
        font-weight: normal;
        text-align: center;
        margin: 0;
      }

      p {
        text-align: center;
        font-size: 1.8rem;
        width: 80%;
        margin: 1rem auto;
      }
    }

    h2.shop-section {
      font-style: italic;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 2vh;
    }
  }
`;

const Strike = styled.span`
  text-decoration: line-through;
  opacity: 0.6;
`;

export const ShopHome = () => {
  const history = useHistory();
  const { setCart } = useContext(PusherContext);

  // UI state
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [selectedCategory, setSelectedCategory] = useState(
    PackCategory.featured,
  );

  // store content
  const [featuredConfigPacks, setFeaturedConfigPacks] = useState<
    Array<FeaturedConfigPack>
  >([]);
  const [promotedPacks, setPromotedPacks] = useState<Array<ShopPack>>([]);
  const { data: shopPacks } = useQuery<getShopPacks>(GET_SHOP_PACKS);

  // list of recently played packs so we can highlight them
  const [recentlyPlayed, setRecentlyPlayed] = useState<string[]>([]);

  const refreshPacks = useCallback(async () => {
    const result = (
      await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/packs/shop`)
    ).data as ShopPackList;

    setFeaturedConfigPacks(result.featuredConfigPacks);
    setPromotedPacks(result.promotedPacks);
    setLastRefresh(new Date());
  }, []);

  useEffect(() => {
    trackPage("Shop", "Home");
  }, []);

  const getRecentlyPlayedPacks = useCallback(async () => {
    try {
      const result = (
        await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/packs/recentlyPlayed`,
        )
      ).data as string[];
      setRecentlyPlayed(result);
    } catch (e) {
      Sentry.captureException(e);
    }
  }, []);

  const goHome = useCallback(() => {
    history.push("/");
  }, [history]);

  const buyPack = useCallback(
    (pack: ShopPack) => {
      /**
       * Callback when Checkout is closed
       */
      const hideCheckout = () => {
        refreshPacks();
        trackEvent("Checkout Canceled", {
          source: "shop",
        });
      };

      setCart([pack], hideCheckout);

      trackEvent("Checkout Started", {
        packName: pack.packName,
        source: "shop",
      });
    },
    [refreshPacks, setCart],
  );

  // fetch shop listing
  useEffect(() => {
    refreshPacks();
    getRecentlyPlayedPacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chooseCategory = (category: PackCategory) => {
    setSelectedCategory(category);

    try {
      trackEvent("Shop Category Changed", {
        category,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <Helmet>
        <title>Got 'Em: Shop</title>
      </Helmet>
      <ShopWrapper>
        <TCLogo subtitle="Let's Shop" onClick={goHome} />

        <CategoryChooser
          selectedCategory={selectedCategory}
          setSelectedCategory={chooseCategory}
        />

        {selectedCategory === PackCategory.featured && (
          <>
            <h2 className="shop-section">Featured Packs</h2>

            {featuredConfigPacks?.map((featuredConfig) => {
              const priceDollars = new Decimal(
                featuredConfig.pack.priceCents,
              ).dividedBy(100);
              const priceText = `$${priceDollars.toFixed(2)}`;

              const endDate = DateTime.fromISO(
                featuredConfig.featuredConfig.endDate,
              ).toJSDate();

              const packBanner = (
                <CardBanner
                  emoji={featuredConfig.featuredConfig.secondaryEmoji}
                >
                  <p>
                    {featuredConfig.featuredConfig.description}
                    <br />
                    <span className="small-caps">
                      Available for <TickingCountdown until={endDate} />
                    </span>
                  </p>
                </CardBanner>
              );

              return (
                <ThemeContext.Provider
                  value={featuredConfig.featuredConfig.colorTheme}
                  key={featuredConfig.featuredConfig.slug}
                >
                  <Card
                    topContent={packBanner}
                    key={featuredConfig.pack.packId}
                    submitButtonProps={{
                      disabled: false,
                      onClick: () => buyPack(featuredConfig.pack),
                      textLabels: {
                        enabled: `Purchase (${priceText})`,
                        disabled: `Not Available`,
                        submitting: `Completing Purchase...`,
                      },
                      resetOnChange: lastRefresh,
                      useThemedButton: true,
                    }}
                  >
                    <h3>{featuredConfig.pack.packName}</h3>
                    <p>{featuredConfig.pack.marketingDescription}</p>
                  </Card>
                </ThemeContext.Provider>
              );
            })}

            {promotedPacks.length === 0 && featuredConfigPacks.length === 0 && (
              <Card>
                You've got them all &mdash; thank you for your support!
              </Card>
            )}

            {promotedPacks?.map((pack) => {
              const priceDollars = new Decimal(pack.priceCents).dividedBy(100);
              const priceText = `$${priceDollars.toFixed(2)}`;

              return (
                <Card
                  key={pack.packId}
                  submitButtonProps={{
                    disabled: false,
                    onClick: () => buyPack(pack),
                    textLabels: {
                      enabled: `Purchase (${priceText})`,
                      disabled: `Not Available`,
                      submitting: `Completing Purchase...`,
                    },
                    resetOnChange: lastRefresh,
                  }}
                >
                  <PackDescription
                    pack={pack}
                    recentlyPlayedPacks={recentlyPlayed}
                  />
                </Card>
              );
            })}
          </>
        )}

        {selectedCategory === PackCategory.notOwned && (
          <>
            <h2 className="shop-section">Browse All</h2>

            {shopPacks?.allPacks?.notOwnedPacks?.length === 0 && (
              <Card>You've got them all &mdash; check back soon!</Card>
            )}

            {shopPacks?.allPacks?.notOwnedPacks?.map((pack) => {
              const priceDollars = new Decimal(pack.priceCents).dividedBy(100);
              const priceText = `$${priceDollars.toFixed(2)}`;

              return (
                <Card
                  key={pack.packId}
                  submitButtonProps={{
                    disabled: false,
                    onClick: () => buyPack(pack),
                    textLabels: {
                      enabled:
                        pack.priceCents < pack.fullPriceCents ? (
                          <>
                            <Strike>
                              $
                              {new Decimal(pack.fullPriceCents)
                                .dividedBy(100)
                                .toFixed(2)}
                            </Strike>{" "}
                            $
                            {new Decimal(pack.priceCents)
                              .dividedBy(100)
                              .toFixed(2)}
                          </>
                        ) : (
                          `${priceText}`
                        ),
                      disabled: `Not Available`,
                      submitting: `Completing Purchase...`,
                    },
                    resetOnChange: lastRefresh,
                  }}
                >
                  <PackDescription
                    pack={pack}
                    recentlyPlayedPacks={recentlyPlayed}
                  />
                </Card>
              );
            })}
          </>
        )}

        {selectedCategory === PackCategory.owned && (
          <>
            <h2 className="shop-section">Your Prompt Packs</h2>
            {shopPacks?.allPacks?.ownedPacks?.map((pack) => {
              return (
                <Card
                  key={pack.packId}
                  submitButtonProps={{
                    disabled: true,
                    onClick: () => alert("buying!"),
                    textLabels: {
                      enabled: `In Your Collection`,
                      disabled: `In Your Collection`,
                      submitting: `In Your Collection`,
                    },
                  }}
                >
                  <PackDescription
                    pack={pack}
                    recentlyPlayedPacks={recentlyPlayed}
                  />
                </Card>
              );
            })}
          </>
        )}

        <Card
          submitButtonProps={{
            disabled: false,
            onClick: goHome,
            textLabels: {
              disabled: "Back Home",
              enabled: "Back Home",
              submitting: "Back Home",
            },
          }}
        />
      </ShopWrapper>
    </>
  );
};
