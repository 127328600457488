import { css, FlattenSimpleInterpolation } from "styled-components/macro";

/**
 * Generate a CSS snippet targeting small phones
 */
export const smallPhone = (styles: FlattenSimpleInterpolation) => {
  return css`
    @media screen and (max-height: 600px) {
      ${styles};
    }
  `;
};
