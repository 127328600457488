import React from "react";
import ReactDOM from "react-dom";
import Pusher from "pusher-js";
import Axios from "axios";
import * as Sentry from "@sentry/browser";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// redirect to Heroku URL if on old URL
// if (window?.location?.href.match(/tc\.jeffpi\.com/)) {
//   window.location.href = "https://triviacast-staging.herokuapp.com/app";
// }

// redirect to main url if on heroku URL since we now use a Netlify proxy
if (window?.location?.href.match(/triviacast-staging\.herokuapp\.com/)) {
  window.location.href = "https://tc.jeffpi.com";
}

Axios.defaults.withCredentials = true;

// 🙄 https://github.com/pusher/pusher-js/issues/62
// https://github.com/pusher/pusher-js/issues/471
Pusher.Runtime.createXHR = function () {
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

Sentry.init({
  environment: process.env.REACT_APP_DEPLOY_ENV,
  dsn:
    "https://970b05df21824238bf5da02d76f8b572@o391791.ingest.sentry.io/5238409",
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
