import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import * as Sentry from "@sentry/browser";
import { UserStats as UserStatsType } from "../types/eventPayloads";
import { Card } from "../design-components/Card/Card";
import { TCLogo } from "../TCLogo/TCLogo";
import axios from "axios";
import { trackPage } from "../SegmentManager";

const RecordTable = styled.table`
  && {
    width: 90%;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    th,
    td {
      border-bottom: 0;
    }

    td {
      font-weight: bold;
      font-family: monospace;
      width: 30%;
      min-width: 100px;
      text-align: right;
    }

    th {
      font-weight: normal;
    }
  }
`;

export default function UserStats() {
  const history = useHistory();
  const [kickEmoji, setKickEmoji] = useState("");
  const [userStats, setUserStats] = useState<UserStatsType | null>(null);

  useEffect(() => {
    trackPage("Account", "User Stats");
  }, []);

  useEffect(() => {
    const getStats = async () => {
      try {
        const result = (
          await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/user/stats`)
        ).data as UserStatsType;
        setUserStats(result);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getStats();
  }, []);

  useEffect(() => {
    if (!userStats) {
      return;
    }

    if (userStats.timesKicked > 0) {
      setKickEmoji("😈");
    } else {
      setKickEmoji("😇");
    }
  }, [userStats]);

  const backHome = useCallback(() => {
    history.push("/");
  }, [history]);

  if (!userStats) {
    return (
      <>
        <TCLogo subtitle="Your Stats" onClick={backHome} />
        <Card title="Records" subtitle="Loading stats..." />
        <button onClick={backHome}>Back Home</button>
      </>
    );
  }

  return (
    <>
      <TCLogo subtitle="Your Stats" onClick={backHome} />

      <Card title="Records">
        <RecordTable>
          <tbody>
            <tr>
              <th>Points scored</th>
              <td>{userStats.pointsScored.toLocaleString("en-US")} 🏅</td>
            </tr>
            <tr>
              <th>Rounds swept</th>
              <td>{userStats.roundsSwept.toLocaleString("en-US")} 🧹</td>
            </tr>
            <tr>
              <th>Best guess streak</th>
              <td>{userStats.bestGuessStreak.toLocaleString("en-US")} 🔥</td>
            </tr>
            <tr>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <th>Rounds played</th>
              <td>{userStats.roundsPlayed.toLocaleString("en-US")} 🎉</td>
            </tr>
            <tr>
              <th>Words written</th>
              <td>{userStats.wordsWritten.toLocaleString("en-US")} ✍️</td>
            </tr>
            <tr>
              <th>Times kicked</th>
              <td>
                {userStats.timesKicked.toLocaleString("en-US")} {kickEmoji}
              </td>
            </tr>
          </tbody>
        </RecordTable>
      </Card>

      <button onClick={backHome}>Back Home</button>
    </>
  );
}
