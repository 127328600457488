import styled from "styled-components/macro";
import { Card } from "../design-components/Card/Card";
import { AnnouncementQuery_activeAnnouncements } from "../HomeScreen/__generated__/AnnouncementQuery";

const Wrapper = styled.div`
  h3 {
    font-family: "Righteous", sans-serif;
    font-size: 2rem;
    font-weight: normal;
    text-align: center;
    margin: 0 0 2rem;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 0;
    text-align: left;
  }
`;

export type AnnouncementCardProps = {
  announcement: AnnouncementQuery_activeAnnouncements;
};

export default function AnnouncementCard({
  announcement,
}: AnnouncementCardProps) {
  return (
    <Wrapper>
      <Card>
        <h3>{announcement.title}</h3>
        <p>{announcement.body}</p>
      </Card>
    </Wrapper>
  );
}
