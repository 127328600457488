import styled from "styled-components/macro";

export const BlockButton = styled.button`
  && {
    border-radius: 5px;
    display: block;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      &:hover {
        opacity: 0.6;
      }
    }
  }
`;

export const SecondaryButton = styled(BlockButton).attrs(() => ({
  className: "filled",
}))`
  font-weight: normal;
`;

export const PrimaryButton = styled(BlockButton).attrs(() => ({
  className: "filled",
}))`
  font-weight: bold;
`;
