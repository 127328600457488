import React, { useContext, useEffect } from "react";
import "./ReadPhase.scss";
import { PusherContext } from "../pusherContext";
import { ResponseViewer } from "./ResponseViewer";
import { PlayerBanner } from "../design-components/PlayerBanner/PlayerBanner";
import { trackPage } from "../SegmentManager";

export const ReadPhase = () => {
  const pusherContext = useContext(PusherContext);
  const {
    currentAnswers: gameAnswers,
    guesserId,
    humanPlayers,
    userId,
  } = pusherContext;

  useEffect(() => {
    trackPage("Game", "Reading Phase");
  }, []);

  if (userId !== guesserId) {
    if (guesserId && humanPlayers[guesserId]) {
      return (
        <PlayerBanner
          userId={guesserId}
          title={humanPlayers[guesserId].name}
          subtitle="Currently Reading"
        />
      );
    }

    return (
      <h2>
        Listen to the Reader{" "}
        <span role="img" aria-label="rainbow">
          🌈
        </span>
      </h2>
    );
  }

  if (gameAnswers && gameAnswers.length > 0) {
    return <ResponseViewer />;
  }

  return (
    <p>
      Hmmm... but there are no answers to read.{" "}
      <span role="img" aria-label="Hmmm">
        🤔
      </span>
    </p>
  );
};
