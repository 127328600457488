import { gql } from "@apollo/client";

const SIGNUP_MUTATION = gql`
  mutation UserSignup($data: SignupInput!) {
    signup(data: $data) {
      failureType
      success
    }
  }
`;

export default SIGNUP_MUTATION;
