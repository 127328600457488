import React, { ReactNode, useContext } from "react";
import styled, { css } from "styled-components/macro";
import { smallPhone } from "../../lib/smallPhone";
import { ThemeContext } from "../../themeContext";
import { SubmitButton, SubmitButtonProps } from "../SubmitButton/SubmitButton";
import { CardContainer } from "../CardContainer/CardContainer";

const CardTitle = styled.h2`
  && {
    font-family: "Righteous", sans-serif;
    text-align: center;
    font-size: 32px;
    margin: 3rem 5%;

    ${smallPhone(css`
      font-size: 24px;
    `)}
  }
`;

const CardSubtitle = styled.h3.attrs(() => ({
  className: "muted",
}))`
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  padding-bottom: 3rem;
`;

export type CardProps = {
  topContent?: ReactNode;
  children?: ReactNode;
  title?: string;
  subtitle?: string | null;
  submitButtonProps?: SubmitButtonProps;
  fullCardButton?: boolean;
};

export const Card = (props: CardProps) => {
  const tcTheme = useContext(ThemeContext);

  const mainCardContent = (
    <>
      {props.title && <CardTitle>{props.title}</CardTitle>}
      {props.subtitle && <CardSubtitle>{props.subtitle}</CardSubtitle>}
      {props.children && <div className="cardContents">{props.children}</div>}
    </>
  );

  return (
    <CardContainer tcTheme={tcTheme}>
      {props.topContent && props.topContent}

      {/* normal mode */}
      {!props.fullCardButton && mainCardContent}

      {/* "full card button" mode */}
      {props.submitButtonProps && (
        <SubmitButton {...props.submitButtonProps}>
          {!!props.fullCardButton && (
            <div className="mainCardContent">{mainCardContent}</div>
          )}
        </SubmitButton>
      )}
    </CardContainer>
  );
};
