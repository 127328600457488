import { useEffect, useState } from "react";
import styled from "styled-components/macro";

const FancyNumbersWrapper = styled.div`
  && {
    img {
      width: auto;
      height: 7rem;
      padding: 0 0.5px;
    }
  }
`;

interface FancyNumbersProps {
  pin: string;
}

/**
 * Use fancy images to render a numeric PIN
 */
export const FancyNumbers = ({ pin }: FancyNumbersProps) => {
  const [digitArray, setDigitArray] = useState<string[]>([]);

  useEffect(() => {
    setDigitArray(pin.split("").filter((char) => char.match(/^\d$/)));
  }, [pin]);

  const renderedPin = digitArray.map((digit, i) => (
    <img key={`digit-${i}`} src={`/assets/digit_${digit}.png`} alt={digit} />
  ));

  return <FancyNumbersWrapper>{renderedPin}</FancyNumbersWrapper>;
};
