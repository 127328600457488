import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Card } from "../design-components/Card/Card";
import GameSessionSummary from "./GameSessionSummary";
import recentGameSessionsQuery from "./queries/recentGameSessions";
import { recentGameSessions } from "./queries/__generated__/recentGameSessions";

export default function BrowseGameSessions() {
  const [page] = useState(0);
  const { data, loading, error } = useQuery<recentGameSessions>(
    recentGameSessionsQuery,
    {
      variables: {
        skip: page,
        take: 10,
      },
    },
  );

  if (error) {
    console.error(error);
  }

  return (
    <Card>
      <h3>Recent Game Sessions</h3>

      {loading && <p>Loading...</p>}
      {error && <p>Whoops... {error.message}</p>}

      {data &&
        data.gameRooms.map((gameRoom) => (
          <GameSessionSummary gameRoom={gameRoom} key={gameRoom.gameRoomId} />
        ))}
    </Card>
  );
}
