import { ReactNode } from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  && {
    display: inline;

    b {
      white-space: nowrap;
    }
  }
`;

interface Props {
  elements: string[];
}

export default function OxfordString({ elements }: Props) {
  let featuredPacks: Array<ReactNode> = [];
  if (elements.length === 1) {
    featuredPacks.push(<b key={elements[0]}>{elements[0]}</b>);
    featuredPacks.push(" pack");
  } else {
    for (let i = 0; i < elements.length; ++i) {
      if (elements.length > 2 && i === elements.length - 1) {
        featuredPacks.push(", and ");
      } else if (i > 0) {
        featuredPacks.push(", ");
      }

      featuredPacks.push(<b>{elements[i]}</b>);
    }
    featuredPacks.push(" packs");
  }

  return <Container>{featuredPacks}</Container>;
}
