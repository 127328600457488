/* eslint-disable no-fallthrough */

import styled from "styled-components/macro";
import { PusherConnectionStatus } from "../types/ConnectionStatus";

const StatusMessage = styled.div`
  && {
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 100;

    p {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 700;
      font-size: 1.6rem;
      padding: 0.7rem 1rem;
      border-radius: 5px;
      opacity: 0.9;
      border: 1px solid #fff;
    }

    &.warning {
      p {
        background-color: #fca311;
      }
    }

    &.error {
      p {
        background-color: #ba1200;
      }
    }
  }
`;

interface ConnectionStatusProps {
  pusherConnectionStatus: PusherConnectionStatus;
}

export const ConnectionStatus = ({
  pusherConnectionStatus,
}: ConnectionStatusProps) => {
  switch (pusherConnectionStatus) {
    default:
    // The connection to Channels is open and authenticated with your app.
    case PusherConnectionStatus.Connected:
    // Initial state. No event is emitted in this state.
    case PusherConnectionStatus.Initialized:
    // The Channels connection was previously connected and has now intentionally been closed
    case PusherConnectionStatus.Disconnected:
      return null;

    // All dependencies have been loaded and Channels is trying to connect.
    // The connection will also enter this state when it is trying to reconnect after a connection failure.
    case PusherConnectionStatus.Connecting:
      return (
        <StatusMessage className="warning">
          <p>Connecting to game...</p>
        </StatusMessage>
      );

    // The connection is temporarily unavailable.
    // In most cases this means that there is no internet connection.
    // It could also mean that Channels is down, or some intermediary is blocking the connection.
    // In this state, pusher-js will automatically retry the connection every 15 seconds.
    case PusherConnectionStatus.Unavailable:
      return (
        <StatusMessage className="error">
          <p>
            Lost connection to game!
            <br />
            Try refreshing the page if this persists.
          </p>
        </StatusMessage>
      );

    // Channels is not supported by the browser.
    // This implies that WebSockets are not natively available and an HTTP-based transport could not be found.
    case PusherConnectionStatus.Failed:
      return (
        <StatusMessage className="error">
          <p>Sorry, this browser doesn't support Got 'Em.</p>
        </StatusMessage>
      );
  }
};
