import React from "react";
import styled, { css } from "styled-components/macro";

import PrimaryText from "./PrimaryText";
import { PointLineItem } from "../../types/eventPayloads";
import { lightDarkVariant } from "../../lib/lightDarkVariant";
import { smallPhone } from "../../lib/smallPhone";
import { largePhone } from "../../lib/largePhone";
import { getFriendlyLabel } from "../../lib/pointLineItemHelper";

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9000;
  background-color: #000;
  opacity: 0.8;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
`;

const ModalContent = styled.div`
  position: relative;
  top: 20vh;
  display: block;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  z-index: 10001;
  /* background-color: #fff;
  border-radius: 5px; */

  ${largePhone(css`
    && {
      top: 5vh;
    }
  `)}

  ${smallPhone(css`
    && {
      top: 10vh;
    }
  `)}
`;

const BubbleText = styled.div`
  display: inline-block;
  padding: 5px 8px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  z-index: 10002;

  ${lightDarkVariant("background-color", {
    light: "#333",
    dark: "#fff",
  })}

  ${lightDarkVariant("color", {
    light: "#fff",
    dark: "#333",
  })}
`;

const PointItem = styled(BubbleText)`
  max-width: 500px;
  min-width: 200px;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right {
    font-family: monospace;
  }
`;

export interface EventModalProps {
  imageBanner?: "gotem" | "nicetry";
  starText: string;
  bubbleText: string;
  pointLineItems?: PointLineItem[];
  onClick?: (e: any) => void;
}

export const EventModal = (props: EventModalProps) => {
  return (
    <div onClick={props.onClick}>
      <ModalBackground />
      <ModalWrapper>
        <ModalContent>
          <PrimaryText {...props} />
          <BubbleText>{props.bubbleText}</BubbleText>
          <br />
          <br />
          {props.pointLineItems &&
            props.pointLineItems.map((item) => {
              if (item.pointValue === 0) {
                return null;
              }

              const { emoji, label } = getFriendlyLabel(item.event);

              return (
                <PointItem key={item.event}>
                  <div className="left">
                    {emoji} {label}
                  </div>
                  <div className="right">
                    {item.pointValue.toLocaleString("en-US")}
                  </div>
                </PointItem>
              );
            })}
        </ModalContent>
      </ModalWrapper>
    </div>
  );
};
