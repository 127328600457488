import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { LobbyControls } from "../Lobby/LobbyControls/LobbyControls";
import "./roundSummary.scss";
import { TCLogo } from "../TCLogo/TCLogo";
import { ScoreSummary } from "../ScoreSummary/ScoreSummary";
import { Card } from "../design-components/Card/Card";
import { PusherContext } from "../pusherContext";
import { trackPage } from "../SegmentManager";
import EndOfRoundBonus from "./EndOfRoundBonus";

import GET_ROUND_BONUSES from "../queries/getRoundBonuses";
import {
  getRoundBonuses,
  getRoundBonusesVariables,
} from "../queries/__generated__/getRoundBonuses";
import EndOfRoundUpsell from "./EndOfRoundUpsell";

export const RoundSummary = () => {
  const { gameRoomId } = useContext(PusherContext);

  const { data: roundBonuses } = useQuery<
    getRoundBonuses,
    getRoundBonusesVariables
  >(GET_ROUND_BONUSES, {
    variables: {
      gameRoomId,
    },
  });

  useEffect(() => {
    trackPage("Game", "Round Summary");
  }, []);

  return (
    <>
      <TCLogo subtitle="Round Over" />

      <EndOfRoundUpsell />

      {roundBonuses && (
        <EndOfRoundBonus
          points={roundBonuses.gameRoom.currentRound.roundBonuses}
        />
      )}

      <Card>
        <ScoreSummary showNewPoints={true} />
      </Card>
      <LobbyControls />
    </>
  );
};
