import React, { useCallback } from "react";
import { Card } from "../design-components/Card/Card";
import { TCLogo } from "../TCLogo/TCLogo";

export const ServerUnreachableScreen = () => {
  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      <TCLogo subtitle="Whoops..." />
      <Card
        title="Looks like we're down"
        subtitle="This is embarassing. We can't reach the server right now. Try reloading a few times or come back later. Sorry!"
        submitButtonProps={{
          disabled: false,
          onClick: reloadPage,
          textLabels: {
            disabled: "Reload Page",
            enabled: "Reload Page",
            submitting: "Reload Page",
          },
        }}
      />
    </>
  );
};
