import { useCallback } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components/macro";
import * as Sentry from "@sentry/browser";
import { getMyAnswer, retractAnswer } from "../api/round";
import { SubmitButton } from "../design-components/SubmitButton/SubmitButton";
import { TCLogo } from "../TCLogo/TCLogo";
import { isMyAnswerPayload } from "../types/typeGuards";
import InfoBlurb from "../design-components/InfoBlurb";
import GroupSubmissionStatus from "./GroupSubmissionStatus";

const Wrapper = styled.div`
  && {
    text-align: center;

    .infoBlurb {
      margin-bottom: 3rem;
    }

    .groupSubmissionStatus {
      margin-top: 5rem;
    }
  }
`;

export const SubmittedAnswer = () => {
  const handleChangeResponse = useCallback(async () => {
    try {
      if (!window?.localStorage) {
        throw new Error("This feature requires a modern browser");
      }

      // fetch answer from server and set to localStorage
      const myAnswer = await getMyAnswer();
      if (!isMyAnswerPayload(myAnswer)) {
        throw new TypeError("Invalid response from getMyAnswer");
      }
      window.localStorage.setItem("ANSWER_DRAFT", myAnswer.answer);

      try {
        // this endpoint also triggers a sync message which will send the user back to edit their response
        const retraction = await retractAnswer();
        if (retraction.status !== 200) {
          throw new Error(
            `Got HTTP ${retraction.status} when attempting to retract answer`,
          );
        }
      } catch (error) {
        window.localStorage.removeItem("ANSWER_DRAFT");
        throw error;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>Got 'Em: Response Submitted</title>
      </Helmet>
      <TCLogo subtitle="Response Submitted" />
      <InfoBlurb>
        (Feel free to harass your slower friends while you wait)
      </InfoBlurb>
      <SubmitButton
        disabled={false}
        buttonPrimary={false}
        onClick={handleChangeResponse}
        textLabels={{
          enabled: "Change Your Response",
          disabled: "Change Your Response",
          submitting: "Retracting response...",
        }}
      />

      <GroupSubmissionStatus />
    </Wrapper>
  );
};
