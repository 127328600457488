import styled from "styled-components/macro";
import { useCallback, useState } from "react";

const FoldButton = styled.button`
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  line-height: 1.5em;
  font-size: 1.2rem;
`;

interface CardFoldProps {
  buttonText: string;
  children: React.ReactChild;
  permanentOpen: boolean;
}

export const CardFold = (props: CardFoldProps) => {
  const [hideButton, setHideButton] = useState(false);
  const [foldVisible, setFoldVisible] = useState(false);

  const toggleFold = useCallback(() => {
    if (props.permanentOpen) {
      setHideButton(true);
    }
    setFoldVisible(!foldVisible);
  }, [props.permanentOpen, foldVisible]);

  return (
    <>
      {!hideButton && (
        <FoldButton onClick={toggleFold}>{props.buttonText}</FoldButton>
      )}
      {foldVisible && <> {props.children}</>}
    </>
  );
};
