import React, { useContext, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { NextGuesser, NextGuesserVariables } from "./__generated__/NextGuesser";
import { LastGuess } from "./LastGuess";
import { PusherContext } from "../pusherContext";
import InfoBlurb from "../design-components/InfoBlurb";
import { PlayerBanner } from "../design-components/PlayerBanner/PlayerBanner";
import NonGuesserWrapper from "./NonGuesserWrapper";
import { InlinePlayerName } from "../design-components/InlinePlayerName/InlinePlayerName";

const NEXT_GUESSER_QUERY = gql`
  query NextGuesser($roomId: String!) {
    gameRoom(gameRoomId: $roomId) {
      nextGuesser {
        name
        userId
      }
    }
  }
`;

export default function NotGuessing() {
  const pusherContext = useContext(PusherContext);
  const { guesserId, humanPlayers, lastGuess, gameRoomId } = pusherContext;

  const { data: nextGuesserData, refetch } = useQuery<
    NextGuesser,
    NextGuesserVariables
  >(NEXT_GUESSER_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      roomId: gameRoomId,
    },
  });

  useEffect(() => {
    refetch();
  }, [guesserId, refetch]);

  return (
    <NonGuesserWrapper>
      <PlayerBanner
        userId={guesserId}
        title={humanPlayers[guesserId]?.name ?? "The Guesser"}
        subtitle="Currently Guessing"
      />
      {nextGuesserData && (
        <InfoBlurb hideThatFinger={true}>
          <>
            <p>Next guesser:</p>
            <InlinePlayerName
              playerId={nextGuesserData.gameRoom.nextGuesser.userId}
              scalingFactor={1.4}
            />
          </>
        </InfoBlurb>
      )}
      {!nextGuesserData && (
        <InfoBlurb>
          Put on your best poker face and try not to get guessed!
        </InfoBlurb>
      )}
      {lastGuess && <LastGuess {...lastGuess} />}
    </NonGuesserWrapper>
  );
}
