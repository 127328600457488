import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/browser";

import { useQuery } from "../hooks/useQuery";
import { TCLogo } from "../TCLogo/TCLogo";
import { Card } from "../design-components/Card/Card";
import { RequestForm } from "./RequestForm";
import { trackPage } from "../SegmentManager";

export const MagicLogin = () => {
  const query = useQuery();
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const magicToken = query.get("token");

  useEffect(() => {
    trackPage("Auth", "Magic Login");
  }, []);

  const handleBackToGame = useCallback(() => {
    history.push("/");
  }, [history]);

  const tryToken = useCallback((token: string) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_HOST}/user/magicLogin`, {
      token,
    });
  }, []);

  useEffect(() => {
    try {
      setSuccess(false);
      setFailure(false);

      if (magicToken) {
        tryToken(magicToken)
          .then(() => {
            setSuccess(true);
          })
          .catch((err) => {
            setFailure(true);
            Sentry.captureException(err);
          });
      } else {
        setFailure(true);
      }
    } catch (error) {}
  }, [magicToken, tryToken]);

  const getFailureState = useCallback(() => {
    if (!failure) {
      return null;
    }

    if (magicToken && magicToken.length > 0) {
      return (
        <RequestForm
          title="Hmmm..."
          subtitle="That magic login link doesn't seem right. FYI, they're only good for two hours."
        />
      );
    }

    return <RequestForm />;
  }, [failure, magicToken]);

  const backHome = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <>
      <TCLogo subtitle="Magic Login" />
      {success && (
        <Card
          title="Welcome Back!"
          subtitle="You're logged in. Magic!"
          submitButtonProps={{
            disabled: false,
            textLabels: {
              disabled: "Start Playing",
              enabled: "Start Playing",
              submitting: "Start Playing",
            },
            onClick: handleBackToGame,
          }}
        />
      )}
      {getFailureState()}

      <button onClick={backHome}>Back Home</button>
    </>
  );
};
