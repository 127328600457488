import styled from "styled-components/macro";

export const MenuContainer = styled.div.attrs(() => ({
  className: "menuContainer",
}))`
  && {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
`;

export const MenuRow = styled.div`
  && {
    flex: 1;
    display: flex;
    flex-direction: row;

    .cardContainer {
      flex: 1;
      margin: 0 3%;

      .mainCardContent {
        text-align: center;
        font-size: 3em;
      }
    }

    &.above-main {
      margin-bottom: 3vh;

      .cardContents {
        font-size: 1.8rem;
        text-align: center;
      }
    }

    &.below-main {
      margin-top: 3vh;

      .cardContents {
        font-size: 1.8rem;
        text-align: center;
      }
    }
  }
`;
