import { useContext } from "react";
import styled, { css } from "styled-components/macro";
import { CardProps, Card } from "../Card/Card";
import { PusherContext } from "../../pusherContext";
import { ProfilePicContainer } from "../ProfilePicContainer/ProfilePicContainer";
import { smallPhone } from "../../lib/smallPhone";

const Banner = styled.div`
  && {
    position: relative;
    flex: 1;
    padding-top: 2em;

    .cardContainer {
      padding-top: 2em;
    }

    .picWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .profilePicContainer {
      margin: 0 auto;
      width: 4.5em;
      height: 4.5em;
      z-index: 10;

      ${smallPhone(css`
        width: 3em;
        height: 3em;
      `)}
    }
  }
`;

export interface PlayerBannerProps extends CardProps {
  submitButtonProps?: CardProps["submitButtonProps"];
  userId?: string;
}

/**
 * Render a player banner by wrapping a <Card /> component and overlaying the user's profile image. If no user ID prop
 * is passed, the logged-in user will be used.
 */
export const PlayerBanner = (props: PlayerBannerProps) => {
  const { me, humanPlayers } = useContext(PusherContext);
  const { userId } = props;

  // TODO: allow this to be configured remotely from the server config endpoint
  const FALLBACK_IMAGE = "/assets/feels_guy.png";

  let profilePicture = FALLBACK_IMAGE;
  if (userId && humanPlayers[userId]) {
    profilePicture = humanPlayers[userId].profilePicture;
  } else if (me) {
    profilePicture = me.profilePicture;
  }

  return (
    <Banner>
      <div className="picWrapper">
        <ProfilePicContainer profilePicture={profilePicture} />
      </div>
      <Card subtitle={me?.email} {...props}></Card>
    </Banner>
  );
};
