import { gql } from "@apollo/client";

const ADD_BOT_MUTATION = gql`
  mutation addBotToRoom {
    addBot {
      userId
      name
    }
  }
`;

export default ADD_BOT_MUTATION;
