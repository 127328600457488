import React, { ReactNode, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle, css } from "styled-components/macro";
import { useLocation } from "react-router-dom";

import { PusherContext } from "../pusherContext";
import { ThemeContext } from "../themeContext";
import { ThemedComponentProps } from "../types/ThemedComponentProps";

import { OptionsOverlay } from "../OptionsOverlay/OptionsOverlay";
import { OptionsScreen } from "../OptionsScreen/OptionsScreen";
import BeingKickedModal from "../BeingKickedModal/BeingKickedModal";
import { TextNotificationBar } from "../TextNotificationBar/TextNotificationBar";
import { lightDarkVariant } from "../lib/lightDarkVariant";
import Checkout from "../Checkout";
import { GAME_PHASE } from "../constants";
import { TCLogoSmall } from "../TCLogo/TCLogoSmall";
import HowToPlay from "../HowToPlay";

type AppContainerProps = {
  children: ReactNode;
  handleLeave: CallableFunction;
  toggleOptions: (e: any) => void;
  hideOptions: (e: any) => void;
};

const GlobalStyle = createGlobalStyle<ThemedComponentProps>`
  body {
    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.primary,
        dark: props.tcTheme.dark.primary,
      })}
    }
`;

type AppContainerDivProps = {
  optionsVisible: boolean;
};

const AppContainerDiv = styled.div<ThemedComponentProps & AppContainerDivProps>`
  max-width: 550px;
  margin: auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${(props) =>
    lightDarkVariant("background-color", {
      light: props.tcTheme.light.primary,
      dark: props.tcTheme.dark.primary,
    })}

  input,
  textarea {
    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.secondaryTextColor,
        dark: props.tcTheme.dark.secondaryTextColor,
      })}
  }

  .logo {
    padding: 1rem;
  }

  button:not(.filled) {
    ${(props) =>
      lightDarkVariant("background-color", {
        light: props.tcTheme.light.secondary,
        dark: props.tcTheme.dark.secondary,
      })}
    border: 0;
    border-radius: 4px;
  }

  ${(props) =>
    props.optionsVisible &&
    css`
      .app-content {
        display: none;
      }
    `}
`;

/**
 * Render whichever phase of the game we're in, or the options overlay on top of it
 */
export const AppContainer = (props: AppContainerProps) => {
  const location = useLocation();
  const {
    activeKickOrder,
    gamePhase,
    optionsVisible,
    setShowHowToPlayModal,
    showCheckoutModal,
    showHowToPlayModal,
  } = useContext(PusherContext);
  const tcTheme = useContext(ThemeContext);

  // close options overlay when route changes
  useEffect(() => {
    props.hideOptions.call(null, []);
  }, [location, props.hideOptions]);

  const onCloseHowToPlayModal = () => {
    setShowHowToPlayModal(false);
  };

  const showOptions = optionsVisible;
  const showCheckout = !optionsVisible && showCheckoutModal;
  const showHowToPlay = !optionsVisible && showHowToPlayModal;

  return (
    <>
      <Helmet>
        <title>Got 'Em</title>
      </Helmet>
      <GlobalStyle tcTheme={tcTheme} />
      <AppContainerDiv tcTheme={tcTheme} optionsVisible={showOptions}>
        <div className="app-topbar">
          {gamePhase === GAME_PHASE.LOBBY && <TCLogoSmall />}
          <TextNotificationBar />
          <OptionsOverlay toggleOptions={props.toggleOptions} />
        </div>

        {showOptions && (
          <OptionsScreen
            handleLeave={props.handleLeave}
            toggleOptions={props.toggleOptions}
          />
        )}

        {/* display global modals */}
        {showCheckout && <Checkout />}
        {showHowToPlay && <HowToPlay handleClose={onCloseHowToPlayModal} />}

        {activeKickOrder !== null && <BeingKickedModal />}

        <div className="app-content">{props.children}</div>
      </AppContainerDiv>
    </>
  );
};
