import { useMutation, useQuery } from "@apollo/client";
import React from "react";

import PLAYER_CAN_ADD_BOT from "./PlayerCanAddBotQuery";
import { getPlayerCanAddBot } from "./__generated__/getPlayerCanAddBot";

import ADD_BOT_MUTATION from "./AddBotMutation";
import { addBotToRoom } from "./__generated__/addBotToRoom";
import { SubmitButton } from "../design-components/SubmitButton/SubmitButton";
import { trackEvent } from "../SegmentManager";

type Props = {
  clickEventName?: string;
};

/**
 * Checks if the user is allowed to add bots. If so, presents a styled button to do so.
 * Also handles the mutation to add the bot.
 */
export const AddBotButton = ({ clickEventName = "Add Bot Clicked" }: Props) => {
  const [botsAdded, setBotsAdded] = React.useState<number>(0);
  const { data: canAddBots } = useQuery<getPlayerCanAddBot>(PLAYER_CAN_ADD_BOT);
  const [addBotToRoom] = useMutation<addBotToRoom>(ADD_BOT_MUTATION);

  const onButtonClick = async () => {
    trackEvent(clickEventName);
    await addBotToRoom();
    setBotsAdded(botsAdded + 1);
  };

  if (!canAddBots?.canAddBots) {
    return null;
  }

  return (
    <SubmitButton
      textLabels={{
        disabled: "Bots unavailable",
        enabled: "🤖 Add Bot",
        submitting: "Adding Bot...",
      }}
      disabled={false}
      onClick={onButtonClick}
      resetOnChange={botsAdded}
      useThemedButton={true}
    />
  );
};
