import axios from "axios";

const baseApiUri = `${process.env.REACT_APP_BACKEND_HOST}/user`;

type UpdateUserPayload = {
  name?: string;
  imageId?: string;
};

/**
 * Update the current user's profile
 * @param updatePayload Key/value pairs of properties to be updated
 */
export const updateUser = async (updatePayload: UpdateUserPayload) => {
  return axios.put(baseApiUri, updatePayload);
};

type CreatePresignedUploadResponse = {
  fields: {
    AWSAccessKeyId: string;
    acl: string;
    key: string;
    policy: string;
    signature: string;
  };
  imageId: string;
  url: string;
};

export const createPresignedUpload = async (
  fileName: string,
): Promise<CreatePresignedUploadResponse> => {
  const response = await axios.post<CreatePresignedUploadResponse>(
    `${baseApiUri}/profilePicture/createPresignedUpload`,
    {
      fileName,
    },
  );

  return response.data;
};

type CreatePresignedUploadResponseNew = {
  imageId: string;
  presignedUrl: string;
};

export const createPresignedUploadNew = async (
  fileName: string,
): Promise<CreatePresignedUploadResponseNew> => {
  const response = await axios.post<CreatePresignedUploadResponseNew>(
    `${baseApiUri}/profilePicture/createPresignedUploadNew`,
    {
      fileName,
    },
  );

  return response.data;
};
