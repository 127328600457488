import { useContext, useCallback } from "react";
import styled from "styled-components/macro";
import "./OptionsOverlay.scss";
import { ThemedComponentProps } from "../types/ThemedComponentProps";
import { lightDarkVariant } from "../lib/lightDarkVariant";
import { ThemeContext } from "../themeContext";
import { PusherContext } from "../pusherContext";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../constants";
import { ProfilePicContainer } from "../design-components/ProfilePicContainer/ProfilePicContainer";

type OptionsOverlayProps = {
  toggleOptions: CallableFunction;
};

const OptionsOverlayWrapper = styled.div.attrs(() => ({
  className: "optionsOverlay",
}))<ThemedComponentProps>`
  && {
    display: flex;
    justify-content: space-between;

    .profilePicContainer {
      width: 4rem;
      height: 4rem;
      border-width: 2px;
      margin: 1rem;
      box-shadow: 0 0 5px #000;
      cursor: pointer;
    }

    button {
      margin-right: 0;
      margin-top: 1em;

      ${(props) =>
        lightDarkVariant("background-color", {
          light: props.tcTheme.light.detail,
          dark: props.tcTheme.dark.detail,
        })}
    }
  }
`;

export const OptionsOverlay = ({ toggleOptions }: OptionsOverlayProps) => {
  const history = useHistory();
  const tcTheme = useContext(ThemeContext);
  const { me, optionsVisible } = useContext(PusherContext);

  const handleToggle = useCallback(() => {
    toggleOptions();
  }, [toggleOptions]);

  const handleAdmin = useCallback(() => {
    history.push(APP_ROUTES.ADMIN_MODE);
  }, [history]);

  if (!me) {
    return null;
  }

  if (optionsVisible) {
    return (
      <OptionsOverlayWrapper tcTheme={tcTheme}>
        {me?.isAdmin && <button onClick={handleAdmin}>Admin Mode</button>}
        {!me?.isAdmin && <div></div>}
        <button onClick={handleToggle}>Back to Game</button>
      </OptionsOverlayWrapper>
    );
  }

  return (
    <OptionsOverlayWrapper tcTheme={tcTheme}>
      <ProfilePicContainer
        onClick={handleToggle}
        profilePicture={me?.profilePicture}
      />
    </OptionsOverlayWrapper>
  );
};
