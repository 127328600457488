import Decimal from "decimal.js";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ThemeContext } from "../themeContext";
import { ShopPack } from "../types/eventPayloads";
import { ThemedComponentProps } from "../types/ThemedComponentProps";

const Wrapper = styled.div`
  && {
    text-align: center;
  }
`;

const RecentlyPlayedBadge = styled.div<ThemedComponentProps>`
  && {
    display: inline-block;
    margin-bottom: 1.5rem;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 0;
    opacity: 0.8;
    font-size: 0.6em;
    font-weight: bold;
  }
`;

interface Props {
  pack: ShopPack;
  recentlyPlayedPacks?: string[];
}

export default function PackDescription({ pack, recentlyPlayedPacks }: Props) {
  const tcTheme = useContext(ThemeContext);
  const [recentlyPlayed, setRecentlyPlayed] = useState(false);

  useEffect(() => {
    if (recentlyPlayedPacks && recentlyPlayedPacks.includes(pack.packId)) {
      setRecentlyPlayed(true);
    }
  }, [recentlyPlayedPacks, pack]);

  return (
    <Wrapper>
      {recentlyPlayed && (
        <RecentlyPlayedBadge tcTheme={tcTheme}>
          🔥 Recently Played!
        </RecentlyPlayedBadge>
      )}
      {!pack.ownedByUser &&
        !recentlyPlayed &&
        pack.priceCents < pack.fullPriceCents && (
          <RecentlyPlayedBadge tcTheme={tcTheme}>
            On sale <>&mdash;</> $
            {new Decimal(pack.fullPriceCents)
              .minus(new Decimal(pack.priceCents))
              .dividedBy(100)
              .toFixed(2)}{" "}
            off!
          </RecentlyPlayedBadge>
        )}
      <h3>{pack.packName}</h3>
      <p>{pack.marketingDescription}</p>
    </Wrapper>
  );
}
