import styled from "styled-components/macro";
import Decimal from "decimal.js";
import { useContext, useEffect, useState } from "react";
import { PusherContext } from "../../pusherContext";

interface DynamicProps {
  circumference: number;
  offset: number;
}

const Wrapper = styled.div<DynamicProps>`
  && {
    .progress-ring__circle {
      transition: stroke-dashoffset 0.35s;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;

      stroke-dasharray: ${(props) =>
        `${props.circumference} ${props.circumference}`};
      stroke-dashoffset: ${(props) => props.offset};
    }
  }
`;

interface Props {
  currentValue: number;
  totalValue: number;
}

export default function RadialProgress({ currentValue, totalValue }: Props) {
  const { isDarkMode } = useContext(PusherContext);
  const [circ, setCirc] = useState(0);
  const [offset, setOffset] = useState(0);
  const [strokeColor, setStrokeColor] = useState("white");

  useEffect(() => {
    const circumference = 52 * 2 * Math.PI;
    setCirc(circumference);
  }, []);

  useEffect(() => {
    const percent = new Decimal(currentValue).dividedBy(
      new Decimal(totalValue),
    );

    const circumference = new Decimal(circ);
    const newOffset = circumference.minus(percent.times(circumference));

    setOffset(newOffset.toNumber());
  }, [circ, currentValue, totalValue]);

  useEffect(() => {
    if (isDarkMode) {
      return setStrokeColor("white");
    }
    return setStrokeColor("black");
  }, [isDarkMode]);

  return (
    <Wrapper circumference={circ} offset={offset}>
      <svg height="120" width="120">
        <circle
          className="progress-ring__circle"
          strokeWidth="4"
          stroke={strokeColor}
          fill="transparent"
          r="52"
          cx="60"
          cy="60"
        />
      </svg>
    </Wrapper>
  );
}
