import React, { useEffect } from "react";
import { trackPage } from "../SegmentManager";

function handleReload() {
  window.location.reload();
}

export default function Whoops() {
  useEffect(() => {
    trackPage("Error", "Whoops");
  }, []);

  return (
    <>
      <h1>Whoops...</h1>
      <p>Something went wrong. Maybe try this...</p>
      <p>
        <button onClick={handleReload}>Reload</button>
      </p>
    </>
  );
}
