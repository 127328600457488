import React from "react";
import styled from "styled-components/macro";
import { DateTime } from "luxon";
import { recentGameSessions_gameRooms } from "../queries/__generated__/recentGameSessions";
import { CLIENT_TYPES } from "../../constants";

const Wrapper = styled.div`
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 1rem 0.8rem;
  margin: 1.5rem 0;

  .title {
    margin: 0;
    font-size: 1.8rem;
    display: flex;

    .left {
      flex: 1;

      .date {
        font-family: monospace;
        font-weight: bold;
      }

      .roundsAndPlayers {
        display: inline-block;
        margin-left: 1.5rem;
        opacity: 0.8;
      }
    }

    .right {
      flex: 0;

      span {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        background-color: #fff;
        color: #000;
        margin-left: 0.5rem;
        padding: 0.2rem 0.5rem;
        border-radius: 5px;
        position: relative;
        top: -0.5rem;
      }
    }
  }

  .details {
    margin-top: 0.5rem;
    font-size: 1.7rem;

    .label {
      font-weight: bold;
    }
  }
`;

type Props = {
  gameRoom: recentGameSessions_gameRooms;
};

export default function GameSessionSummary({ gameRoom }: Props) {
  const numPlayers = gameRoom.playerHistory.length;

  // tag some users who we've given content to as 'internal' so we don't get over-excited about their games
  const internalUserIds = [
    "d7ee2f02-00a1-4a7c-8f8d-ad3643c52e3b", // maureen
  ];

  // if the user is in the `internalUserIds` array or has the admin role, it's an internal game
  const internalGame =
    gameRoom.playerHistory.some((player) => player.isAdmin) ||
    gameRoom.playerHistory.some((player) =>
      internalUserIds.includes(player.userId),
    );

  // check if bots were in the mix
  const botsPresent = gameRoom.playerHistory.some(
    (player) => player.clientType === CLIENT_TYPES.BOT,
  );

  return (
    <Wrapper>
      <div className="title">
        <div className="left">
          <span className="date">
            {DateTime.fromISO(gameRoom.createdAt).toLocaleString()}
          </span>
          <span className="roundsAndPlayers">
            {numPlayers} players, {gameRoom.gameRounds.length} rounds
          </span>
        </div>
        <div className="right">
          {internalGame && <span>Internal</span>}
          {gameRoom.roomConfig.nsfwEnabled ? (
            <span className="nsfw">NSFW</span>
          ) : (
            <span className="sfw">SFW</span>
          )}
          {botsPresent && <span className="bots">Bots</span>}
        </div>
      </div>
      <div className="details">
        <span className="label">Packs: </span>
        {gameRoom.roomConfig.packsEnabled
          .map((pack) => pack.packName)
          .join(", ")}
      </div>
    </Wrapper>
  );
}
