import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

const link = createHttpLink({
  credentials: "include",
  uri: `${process.env.REACT_APP_BACKEND_HOST}/graphql`,
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default apolloClient;
