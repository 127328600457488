import { DateTime } from "luxon";
import { useEffect, useState } from "react";

interface Props {
  until: Date;
}

export default function TickingCountdown({ until }: Props) {
  const [countdownString, setCountdownString] = useState("");

  useEffect(() => {
    const buildString = () => {
      const diff = DateTime.fromJSDate(until).diffNow();
      setCountdownString(diff.toFormat("d'd' hh:mm:ss"));
    };

    buildString();
    const updateInterval = window.setInterval(buildString, 1000);

    return () => {
      window.clearInterval(updateInterval);
    };
  }, [until]);

  return <>{countdownString}</>;
}
