import React from "react";
import { EventModal } from "../design-components/EventModal/EventModal";

export const GameLoadingScreen = () => {
  return (
    <>
      <EventModal
        starText="Loading..."
        bubbleText="This can seriously take up to 30 seconds! Stay with us..."
      />
    </>
  );
};
