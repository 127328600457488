import styled from "styled-components/macro";
import { PointLineItem } from "../types/eventPayloads";
import BonusAward from "./BonusAward";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 4rem;
  margin-bottom: 5rem;
`;

export type EndOfRoundBonusProps = {
  points: PointLineItem[];
};

export default function EndOfRoundBonus({ points }: EndOfRoundBonusProps) {
  return (
    <Wrapper>
      {points.map((point) => (
        <BonusAward point={point} key={point.event} />
      ))}
    </Wrapper>
  );
}
