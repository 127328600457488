import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import { PusherContext } from "../pusherContext";
import { PaymentModal } from "./PaymentModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY ?? "");

export default function Checkout() {
  const { cart, onCheckoutClosed, setShowCheckoutModal } = useContext(
    PusherContext,
  );

  const doHideCheckout = () => {
    if (onCheckoutClosed) {
      onCheckoutClosed();
    }
    setShowCheckoutModal(false);
  };

  return (
    <Elements stripe={stripePromise}>
      <PaymentModal cart={cart} handleClose={doHideCheckout} />
    </Elements>
  );
}
