import styled from "styled-components/macro";

const EEText = styled.p`
  && {
    margin: 0;
    display: inline-block;
    transform: rotate(-25deg);
    font-size: 1.2rem;
    position: relative;
    top: -0.5rem;
    left: 1rem;
  }
`;

interface Props {
  roundPoints: number;
  totalPoints: number;
}

export const ScoreEasterEgg = ({ roundPoints, totalPoints }: Props) => {
  if (roundPoints === 6 && totalPoints === 9) {
    return <EEText>Nice</EEText>;
  }

  if (roundPoints === 4 && totalPoints === 20) {
    return <EEText>Blaze It</EEText>;
  }

  return null;
};
