export enum GAME_PHASE {
  ANSWERING = "answering",
  ENTER_PIN = "enter-pin",
  GUESSING = "guessing",
  HOME_SCREEN = "home-screen",
  LOBBY = "lobby",
  OUT_OF_PROMPTS = "out-of-prompts",
  READING = "reading",
  ROUND_SUMMARY = "round-summary",
}

export enum APP_ROUTES {
  ADMIN_MODE = "/admin",
  AUTH = "/auth",
  CREATE_ROOM = "/create",
  JOIN_ROOM = "/join",
  LOGIN = "/login",
  MAGIC_LOGIN = "/magicLogin",
  SHOP = "/shop",
  SIGNUP = "/signup",
  USER_STATS = "/user-stats",
}

export enum PLAYER_ROLE {
  APPLETV = "APPLETV",
  CHAD = "CHAD",
  MELVIN = "MELVIN",
}

export enum CLIENT_TYPES {
  APPLE_TV = "apple_tv",
  BOT = "bot",
  WEB = "web",
}

export enum ROUND_STATUS {
  OUT = "out", // out for the round after their answer was guessed
  READY = "ready", // ready and connected to the Pusher channel
}

export enum NETWORK_STATUS {
  GONE = "gone", // inactive on the Pusher channel, but still in the game if they come back in time
  ONLINE = "online", // nominal; player is connected
  PENDING = "pending", // haven't figured out what to do yet, but don't want to initialize as online in case they're going to the waiting room
  TIMED_OUT = "timed_out", // failed to rejoin after a timeout; out of the game rotation
  WAITING_ROOM = "waiting_room", // (re)joined while a round was in progress; will move to READY at the end of the round
  WAITING_ROOM_GONE = "waiting_room_gone", // inactive on the Pusher channel while in the waiting room
}

export type PublicRoomConfig = {
  minPlayers: number;
  inactiveTimeoutSeconds: number;
  nsfwEnabled: boolean;
  promptPacks: Array<{
    packId: string;
    packName: string;
  }>;
  configPresetName: string | null;
};

export enum ThemeName {
  ChristmasSpirit = "christmasSpirit",
  Default = "default",
  FarOutMan = "farOutMan",
  NewYearNewMe = "newYearNewMe",
  SpookMonth = "spookMonth",
  ThanksGotEm = "thanksGotEm",
  VDayVibe = "vDayVibe",
  WineryWines = "wineryWines",
  IndependenceDay = "independenceDay",
}

export const TC_CONSTANTS = {
  APP_VERSION: "tc-prerelease",
  STORAGE_KEYS: {
    HOST_UUID: "host-uuid",
    ROOM_PIN: "room-pin",
    USER_ID: "user-id",
    USER_NAME: "user-name",
  },
  EVENTS: {
    ANSWER_READ: "client-tc.answer_read",
    BROADCAST_ANSWERS: "tc.broadcast_answers",
    CHANNEL_VACATED: "channel_vacated",
    FINISH_ROUND: "tc.finish_round",
    GUESS_RESULT: "tc.guess_result",
    KICK_NOTIFICATION: "tc.kick_notification",
    MEMBER_ADDED: "member_added",
    MEMBER_REMOVED: "member_removed",
    NEW_GUESSER: "tc.new_guesser",
    NEW_PROMPT: "tc.new_prompt",
    OUT_OF_PROMPTS: "tc.out_of_prompts",
    PLAYERLIST_SYNC: "tc.player_list_sync",
    REQUEST_SYNC: "client-tc.request_sync",
    ROUND_SYNC: "tc.round_sync",
    START_GUESSING: "tc.start_guessing",
    START_READING: "tc.start_reading",
    SUBMIT_GUESS: "client-tc.submit_guess",
    TEXT_NOTIFICATION: "tc.text_notification",
    THEME_UPDATE: "tc.theme_update",
    VETO_COUNT_UPDATE: "tc.veto_count_update",
  },
};
