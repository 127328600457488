import React, { useContext } from "react";
import styled from "styled-components/macro";

import { ThemeContext } from "../../themeContext";
import { ThemedComponentProps } from "../../types/ThemedComponentProps";
import { lightDarkVariant } from "../../lib/lightDarkVariant";

const PromptWrapper = styled.h2.attrs(() => ({
  className: "bigPrompt",
}))<ThemedComponentProps>`
  font-family: "Righteous";
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: bold;

  .fillInTheBlank {
    position: relative;
    top: -0.5rem;

    ${(props) =>
      lightDarkVariant("border-bottom", {
        light: `1px solid ${props.tcTheme.light.primaryTextColor}`,
        dark: `1px solid ${props.tcTheme.dark.primaryTextColor}`,
      })}
  }
`;

const FillInTheBlank = () => {
  return (
    <span className="fillInTheBlank">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
  );
};

export const BigPrompt = ({ promptText }: { promptText: string | null }) => {
  const tcTheme = useContext(ThemeContext);

  if (!promptText) {
    return null;
  }

  // "fill-in-the-blank" is represented by underscores which renders poorly with the Righteous font; replace it with something better
  const promptSegments = promptText.split(/(_{3,})/);
  return (
    <PromptWrapper tcTheme={tcTheme}>
      {promptSegments.map((segment, index) => {
        if (segment.match(/_{3,}/)) {
          return <FillInTheBlank key={`segment-${index}`} />;
        }
        return (
          <React.Fragment key={`segment-${index}`}>{segment}</React.Fragment>
        );
      })}
    </PromptWrapper>
  );
};
